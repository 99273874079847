import { Box } from "@mui/material";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  icon: ReactNode;
};

const FavoriteSliderContainer = ({ children, icon }: Props) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        gap: "2rem",
      }}
    >
      <Box
        sx={{
          display: {
            mobile: "none",
            sm: "block",
          },
          width: {
            mobile: "45px",
            sm: "55px",
            md: "65px",
            lg: "75px",
            xl: "95px",
          },
          height: "95px",

          "& svg": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        }}
      >
        {icon}
      </Box>
      {children}
    </Box>
  );
};

export default FavoriteSliderContainer;
