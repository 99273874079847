import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../../Assets/Images/main-logo.svg";
import { StyledAuthButton } from "../../StyledComponents/StyledButton/StyledButton";
import "./index.scss";
import useGetData from "../../../Hooks/useGetData";

const AuthInfo = ({
  title,
  subTitle,
  subText,
  buttonText,
  buttonAction,
  register,
  children,
}) => {
  const { i18n } = useTranslation();
  const { data } = useGetData("settings/all", []);
  const updatedLogo = data && data?.settings?.logo;
  return (
    <div
      className="info"
      style={{
        order: register ? -1 : 1,
      }}
    >
      <div className="info__logo">
        <Link to="/">
          <img src={data && updatedLogo} alt="logo" />
        </Link>
      </div>

      <div className="info__text">
        <div className="info__title">
          <h1>{title}</h1>
        </div>
        <div className={`info__subtitle ${i18n.dir()}`}>
          <h2>{subTitle}</h2>
          <p>{subText}</p>
        </div>
      </div>
      <div className="small-form">{children}</div>
      <div className="info__actions">
        <StyledAuthButton
          variant="contained"
          onClick={buttonAction}
          sx={{
            backgroundColor: "#CEAFF5",
            color: "#755798",
            "&:hover": {
              backgroundColor: "#d9bdfd",
            },
          }}
        >
          {buttonText}
        </StyledAuthButton>
      </div>
    </div>
  );
};

export default AuthInfo;
