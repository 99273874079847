import React from "react";
import { useNavigate } from "react-router-dom";
import fallbackImage from "../../../Assets/Images/default-actor.jpg";
import useLongPress from "../../../Hooks/useLongPress";
import {
  StyledBox,
  StyledImage,
  StyledTypography,
} from "../../StyledComponents/SmallCard/SmallCard";

type Props = {
  name?: string;
  image?: string;
  id?: number;
};

const ActorCard: React.FC<Props> = ({ image, name = "", id }) => {
  const navigate = useNavigate();
  const onLongPress = () => {};

  const onClick = () => {
    navigate(`/actor/${id}`);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 100,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
  return (
    <StyledBox
      sx={{
        backgroundColor: "#3B215A",
      }}
      {...longPressEvent}
    >
      <StyledImage
        alt="series"
        onError={(e) => {
          e.currentTarget.src = fallbackImage;
        }}
        src={image || fallbackImage}
      />

      {name && (
        <StyledTypography
          sx={{
            color: "white",
            textAlign: "center",
          }}
        >
          {name}
        </StyledTypography>
      )}
    </StyledBox>
  );
};

export default ActorCard;
