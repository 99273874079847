import React from "react";
import { StyledIcon } from "../../../StyledComponents/ControlsIcon/StyledIcon";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

type Props = {};

const Favorite = (props: Props) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get("token");
  return (
    <StyledIcon
      onClick={() => {
        token ? navigate("/favorites") : navigate("/login");
      }}
      sx={{
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "white",
          "& svg": {
            "& g": {
              fill: "#614681",
              "& #Icon_material-favorite-2": {
                fill: "#5A4277",
              },
            },
          },
        },
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37.159"
        height="34.093"
        viewBox="0 0 37.159 34.093"
      >
        <g
          id="Icon_material-favorite"
          data-name="Icon material-favorite"
          transform="translate(-3 -4.5)"
          fill="#a47fd1"
        >
          <path
            d="M 21.58207511901855 37.24343872070312 L 19.55867004394531 35.40143966674805 L 19.53366851806641 35.37875747680664 C 10.1796293258667 26.89656829833984 3.999999284744263 21.29290962219238 3.999999284744263 14.71873950958252 C 3.999999284744263 9.549348831176758 8.049348831176758 5.499999046325684 13.21873950958252 5.499999046325684 C 16.09420967102051 5.499999046325684 18.93511962890625 6.820168972015381 20.81816864013672 9.031458854675293 L 21.57951927185059 9.925518989562988 L 22.34086990356445 9.031458854675293 C 24.22391891479492 6.820168972015381 27.06482887268066 5.499999046325684 29.94029998779297 5.499999046325684 C 35.10968780517578 5.499999046325684 39.15903854370117 9.549348831176758 39.15903854370117 14.71873950958252 C 39.15903854370117 21.28134918212891 32.99890899658203 26.87925910949707 23.67438888549805 35.35276031494141 L 23.60101890563965 35.41942977905273 L 21.58207511901855 37.24343872070312 Z"
            stroke="none"
          />
          <path
            id="Icon_material-favorite-2"
            d="M 21.58462715148926 35.89345550537109 L 23.00185966491699 34.61268997192383 C 27.61990928649902 30.41610908508301 31.60820007324219 26.79181861877441 34.27603912353516 23.52171897888184 C 36.96147918701172 20.23003959655762 38.15903854370117 17.51509857177734 38.15903854370117 14.71873950958252 C 38.15903854370117 10.11009883880615 34.5489387512207 6.499999046325684 29.94029998779297 6.499999046325684 C 27.35404968261719 6.499999046325684 24.79776000976562 7.688709259033203 23.10221862792969 9.67979907989502 L 21.57951927185059 11.46792888641357 L 20.05681991577148 9.67979907989502 C 18.36127853393555 7.688709259033203 15.80498886108398 6.499999046325684 13.21873950958252 6.499999046325684 C 8.610098838806152 6.499999046325684 4.999999046325684 10.11009883880615 4.999999046325684 14.71873950958252 C 4.999999046325684 17.51999855041504 6.201639175415039 20.23920822143555 8.896219253540039 23.53559875488281 C 11.57284927368164 26.81001853942871 15.5732889175415 30.43758964538574 20.20541000366211 34.63797760009766 L 20.23184967041016 34.66196060180664 L 21.58462715148926 35.89345550537109 M 21.57951927185059 38.59341812133789 L 18.88548851013184 36.14091873168945 C 9.317039489746094 27.46428871154785 2.999999284744263 21.74179840087891 2.999999284744263 14.71873950958252 C 2.999999284744263 8.996249198913574 7.496239185333252 4.499999046325684 13.21873950958252 4.499999046325684 C 16.45156860351562 4.499999046325684 19.55434989929199 6.004939079284668 21.57951927185059 8.383118629455566 C 23.60468864440918 6.004939079284668 26.70746994018555 4.499999046325684 29.94029998779297 4.499999046325684 C 35.66278839111328 4.499999046325684 40.15903854370117 8.996249198913574 40.15903854370117 14.71873950958252 C 40.15903854370117 21.74179840087891 33.84199905395508 27.46428871154785 24.27355003356934 36.15950012207031 L 21.57951927185059 38.59341812133789 Z"
            stroke="none"
            fill="#5a4277"
          />
        </g>
      </svg>
    </StyledIcon>
  );
};

export default Favorite;
