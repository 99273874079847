import { Box, Typography, styled } from "@mui/material";
import queryString from "query-string";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import FilterModal from "../../Modals/FilterModal";
import { StyledSecondaryButton } from "../../StyledComponents/StyledButton/StyledButton";
import ProductionYearSelect from "../ProductionYearSelect/ProductionYearSelect";
import SortButton from "../SortButton/SortButton";

type Props = {
  color: string;
  title?: any;
  subTitle?: string;
  actions?: boolean;
  sortButton?: boolean;
  moreButton?: {
    exist: boolean;
    url: string;
    id?: number;
  };
  categoryEnum?: number;
  yearFilter?: boolean;
};

const StyledListHead = styled("div")({
  position: "relative",
  padding: "0 20px",
  margin: "0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "@media (max-width: 768px)": {
    flexDirection: "column",
    margin: "20px 0",
  },
});
const StyledListTitle = styled("div")({
  color: "#81CEFC",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "relative",
  "&::before": {
    content: "''",
    position: "absolute",
    display: "block",
    width: "2px",
    height: "80%",
    backgroundColor: "#CEAFF5",
    left: "-20px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  "& h5": {
    fontSize: "3rem",
    fontWeight: "700",
  },
  "& p": {
    fontSize: "2rem",
    color: "#E2E2E2",
    fontWeight: "300",
  },
  "@media (max-width: 1440px)": {
    "& h5": {
      fontSize: "2.5rem",
    },
    "& p": {
      fontSize: "1.75rem",
    },
  },
  "@media (max-width: 1024px)": {
    "& h5": {
      fontSize: "2rem",
    },
    "& p": {
      fontSize: "1.5rem",
    },
  },
  "@media (max-width: 768px)": {
    width: "100%",
    justifySelf: "flex-start",
    "& h5": {
      fontSize: "1.75rem",
    },
    "& p": {
      fontSize: "1.25rem",
    },
  },
  "@media (max-width: 480px)": {},
});

const SectionHead = ({
  color,
  title,
  subTitle,
  actions,
  moreButton,
  sortButton,
  categoryEnum,
  yearFilter,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<HTMLDivElement | null>(null);
  const query = queryString.parse(location.search);
  let queryParams = queryString.stringify(query);
  
  useEffect(() => {
    if (query?.year && ref.current) {
      window.scrollTo({
        behavior: "smooth",
        top: ref.current.offsetTop - 240,
      });
    }
  }, [query?.year]);
  return (
    <StyledListHead ref={ref}>
      <StyledListTitle
        sx={{
          "&::before": {
            backgroundColor: color,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: color,
            }}
          >
            {title}
          </Typography>

          {yearFilter && <ProductionYearSelect />}
        </Box>

        <p>{subTitle}</p>
      </StyledListTitle>
      {actions && (
        <Box
          component="form"
          sx={{
            display: "flex",
            gap: "20px",
            minWidth: "320px",
            width: {
              mobile: "100%",
              sm: "auto",
            },
            justifyContent: {
              mobile: "flex-end",
            },
            marginRight: {
              mobile: "0",
              sm: "24px",
              md: "2rem",
            },
          }}
        >
          {sortButton && <SortButton />}
          {/* {sortButton && <FormMenu menuType="country" name="menu" label="" control={contr} />} */}

          {categoryEnum && (
            <FilterModal categoryEnum={categoryEnum} color={color} />
          )}
          {moreButton?.id && (
            <StyledSecondaryButton
              disableElevation
              sx={{
                backgroundColor: color,
                color: "#fff",
                "&:hover": {
                  backgroundColor: color,
                },
              }}
              variant="contained"
              color="secondary"
              onClick={() => {
                navigate(`/home/media/${moreButton?.id}?${queryParams}`);
              }}
            >
              {t("actions.more")}
            </StyledSecondaryButton>
          )}
        </Box>
      )}
    </StyledListHead>
  );
};

export default SectionHead;
