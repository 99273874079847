import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../Components/Auth/AuthLayout";
import RegisterForm from "../../../Components/Auth/RegisterForm";

const Register = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:768px)");
  const authInfoProps = {
    title: matches ? t("loginPage.secondTitle") : t("loginPage.title"),
    subTitle: t("loginPage.textAgain"),
    subText: t("loginPage.secondDesc"),
    buttonText: t("loginPage.login"),
    buttonAction: () => {
      navigate("/login");
    },
  };
  return (
    <AuthLayout
      register={true}
      authTitle={t("loginPage.secondNewAccount")}
      authInfo={authInfoProps}
    >
      <RegisterForm />
    </AuthLayout>
  );
};

export default Register;
