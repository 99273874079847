import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import ShoppingCartMain from "../../Components/Blocks/ShoppingCart/ShoppingCartMain";
import ShoppingCartSideBox from "../../Components/Blocks/ShoppingCart/ShoppingCartSideBox";
import useGetData from "../../Hooks/useGetData";
import { cartActions } from "../../store/cart-slice";

const ShoppingCart = () => {
  const dispatch = useDispatch();

  // State
  const refreshState = useSelector((state: any) => state.refreshApi);
  const cartState = useSelector((state: any) => state.cart);
  const [expanded, setExpanded] = useState(0);

  const [cartApiUrl, setCartApiUrl] = useState("cart/list");

  useEffect(() => {
    if (cartState.address_id) {
      setCartApiUrl(`cart/list?address_id=${cartState.address_id}`);
    }
  }, [cartState.address_id]);

  // API data
  const { data, loading, error } = useGetData(cartApiUrl, [
    refreshState,
    cartState.refreshCartToken,
  ]);

  useEffect(() => {
    let totalActualSize = 0;
    if (data) {
      const items: any = [];
      const onlineItems: any = [];
      const downloadItems: any = [];
      const storageItems: any = [];
      const dvdItems: any = [];

      data.cart.forEach((item: any) => {
        const itemData = {
          id: item.id,
          price_type: item.price_type,
          price: item.price,
          has_cover: item.has_cover,
          cover_price: item.cover_price,
          discount_price: item.discount_price,
        };

        if (item.price_type === 1) {
          onlineItems.push(item);
        } else if (item.price_type === 2) {
          downloadItems.push(item);
        } else if (item.price_type === 3) {
          storageItems.push(item);
          totalActualSize += item?.actual_size;
        } else if (item.price_type === 4) {
          dvdItems.push(item);
          dispatch(
            cartActions.addDvdQuantity(
              cartState.dvdQuantity + item?.dvd_quantity
            )
          );
        }

        if (itemData && itemData?.id) {
          items.push(itemData);
        }
      });

      dispatch(cartActions.addOnlineItems(onlineItems));
      dispatch(cartActions.addDownloadItems(downloadItems));
      dispatch(cartActions.addStorageItems(storageItems));
      dispatch(cartActions.addDvdItems(dvdItems));

      if (onlineItems.length > 0) {
        setExpanded(1);
      } else if (downloadItems.length > 0) {
        setExpanded(2);
      } else if (dvdItems.length > 0) {
        setExpanded(4);
      } else if (storageItems.length > 0) {
        setExpanded(3);
      }

      dispatch(
        cartActions.setStoragesPrice(data?.order_details?.total_storage_prices)
      );

      dispatch(cartActions.addClientNote(data?.client_note));
      dispatch(cartActions.addItems(items));
      dispatch(cartActions.setTotalPrice(data?.order_details?.sub_total));
      dispatch(
        cartActions.setShippingPrice(data?.order_details?.shipping_cost)
      );
      dispatch(
        cartActions.setDvdPrices(data?.order_details?.total_cover_prices)
      );
      dispatch(cartActions.setTotalCost(data?.order_details?.total));
      dispatch(cartActions.setOfferDiscount(data?.order_details?.offer_discount));
      dispatch(cartActions.setDiscount(data?.order_details?.discount));
      dispatch(cartActions.setAddress(data?.order_details?.address_id));
      if (data?.storage) {
        dispatch(cartActions.addStorageItem(data?.storage));
      }
    } else {
      dispatch(cartActions.setTotalActualSize(0));
      dispatch(cartActions.setTotalDiscountPrice(0));
      dispatch(cartActions.setTotalPrice(0));
      dispatch(cartActions.addOnlineItems([]));
      dispatch(cartActions.addDownloadItems([]));
      dispatch(cartActions.addStorageItems([]));
      dispatch(cartActions.addStorageItem({}));
      dispatch(cartActions.addDvdItems([]));
    }
    dispatch(cartActions.setTotalActualSize(totalActualSize));
  }, [data]);

  if (loading) return <MainLoader />;

  if (error) return <></>;
  
  return (
    <StyledContainer>
      <StyledCartContainer>
        <ShoppingCartSideBox />
        <ShoppingCartMain
          expanded={expanded}
          setExpanded={setExpanded}
          data={data}
        />
      </StyledCartContainer>
    </StyledContainer>
  );
};

export default ShoppingCart;

const StyledContainer = styled("section")({
  overflow: "hidden",
  padding: "200px 0",
  margin: "0 auto",
  maxWidth: "80vw",
  "@media (max-width: 576px)": {
    maxWidth: "92vw"
  }
});
const StyledCartContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2.25fr 3fr",
  borderRadius: "20px",
  overflow: "hidden",

  [theme.breakpoints.down("xl")]: {
    gridTemplateColumns: "1.75fr 3fr",
  },
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1.25fr 3fr",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));
