import { Box, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import playButton from "../../Assets/Images/play-button.svg";
import ActorCard from "../../Components/Blocks/ActorComponents/ActorCard";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import MediaHeader from "../../Components/Blocks/MediaHeader/MediaHeader";
import MediaListSlider from "../../Components/Blocks/MediaListSlider/MediaListSlider";
import MediaSliderSection from "../../Components/Blocks/MediaSliderSection/MediaSliderSection";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import StyledSliderWithIcon from "../../Components/StyledComponents/StyledSliderWithIcon/StyledSliderWithIcon";
import useGetData from "../../Hooks/useGetData";
import { trailerActions } from "../../store/trailer-slice";
import StyledMainBox from "./Styles/StyledMainBox";
import StyledSliderContainer from "./Styles/StyledSliderContainer";

type Props = {};

const MoviesPage = (props: Props) => {
  const { i18n, t } = useTranslation();
  const refreshApiState = useSelector((state: any) => state.refreshApi);

  const { id } = useParams<string>();
  const { data, loading, error } = useGetData(`movie/show/${id}/related/10`, [
    id,
    refreshApiState?.refreshApi,
    i18n.language,
  ]);

  const dispatch = useDispatch();

  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  let tags = [
    // {
    //   name: "Tags",
    //   name_ar: "التصنيف",
    //   label_value: data?.movie?.movie_data?.media_tags,
    //   label_value_ar: data?.movie?.movie_data?.media_tags,
    // },
    {
      name: "Product No",
      name_ar: "رقم المنتج",
      label_value: data?.movie?.movie_data?.movie_serial,
      label_value_ar: data?.movie?.movie_data?.movie_serial,
    },
    {
      name: "Quality",
      name_ar: "الجودة",
      label_value: data?.movie?.movie_data?.quality,
      label_value_ar: data?.movie?.movie_data?.quality_ar,
    },
    {
      name: "Size",
      name_ar: "الحجم",
      label_value: `${data?.movie?.movie_data?.actual_size} GB`,
      label_value_ar: `${data?.movie?.movie_data?.actual_size} جيجا`,
    },
    {
      name: "Dvd Quantity",
      name_ar: " عدد ال DVD",
      label_value: data?.movie?.movie_data?.dvd_quantity || "0",
      label_value_ar: data?.movie?.movie_data?.dvd_quantity || "0",
    },
    {
      name: "Production Year",
      name_ar: "سنة الانتاج",
      label_value: data?.movie?.movie_data?.year,
      label_value_ar: data?.movie?.movie_data?.year,
    },
    {
      name: "Duration in minutes",
      name_ar: "مدة العرض بالدقائق",
      label_value: `${data?.movie?.movie_data?.duration} min`,
      label_value_ar: `${data?.movie?.movie_data?.duration} دقيقة`,
    },
    {
      name: "Language",
      name_ar: "اللغة",
      label_value: data?.movie?.movie_data?.language,
      label_value_ar: data?.movie?.movie_data?.language_ar,
    },
    {
      name: "Subtitles",
      name_ar: "الترجمة",
      label_value: data?.movie?.movie_data?.subtitles?.join(", "),
      label_value_ar: data?.movie?.movie_data?.subtitles_ar?.join(", "),
    },
    {
      name: "Director",
      name_ar: "المخرج",
      label_value: data?.movie?.movie_data?.directors,
      label_value_ar: data?.movie?.movie_data?.directors_ar,
    },

    {
      name: "Notes",
      name_ar: "الملاحظات",
      label_value: data?.movie?.movie_data?.notes,
      label_value_ar: data?.movie?.movie_data?.notes,
    },
  ];

  const ActorsSlider = MediaListSlider(ActorCard);

  return (
    <Stack>
      <StyledMainBox>
        <Box
          component="img"
          src={data?.movie?.movie_data?.poster}
          alt=""
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            objectFit: "fill",
            // filter: "blur(5px)",
            zIndex: -1,
            // "&::after":{
            //   position: "absolute",
            //   content: '""',
            //   top: 0,
            //   left: 0,
            //   backgroundImage: "linear-gradient(180deg, #00000075 0%, #24113a1c 35%, #321c4a6e 66%, #321C4A 83%, #24113A 100%)"
            // },
            // "@media (min-width: 1024px)": {
            //   maxHeight: "38%"
            // },
            "@media (max-width: 1023.99px)": {
              maxHeight: "70vh"
            },
            "@media (max-width: 992px)": {
              maxHeight: "50vh"
            },
            
            "@media (max-width: 576px)": {
              maxHeight: "48vh"
            },
          }}
        />
        <Stack
          alignItems="center"
          justifyContent="space-between"
          sx={{
            marginTop: "90vh",
            height: "100%",
            // "@media (max-width: 1280px) and (min-height: 800px)": {
            //   marginTop: "60vh",
            // },
            "@media (max-width: 1023.99px)": {
              marginTop: "75vh",
            },
            "@media (max-width: 992px)": {
              marginTop: "60vh",
            },
            "@media (max-width: 768px)": {
              marginTop: "50vh",
            },
            gap: {
              mobile: "4rem",
              sm: "8rem",
              md: "12rem",
              lg: "15rem",
            },
          }}
        >
          <Box
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{
              scale: 0.9,
            }}
            component={motion.img}
            src={playButton}
            alt="play-button"
            sx={{
              cursor: "pointer",
              width: {
                mobile: "80px",
                md: "100px",
                xl: "130px",
              },
              position: "absolute",
              top: "35vh",
              "@media (max-width: 1023.99px)":{
                top: "30vh"
              },
              "@media (max-width: 992px)":{
                top: "20vh"
              }
            }}
            onClick={() => {
              dispatch(
                trailerActions.openModal(data?.movie?.movie_data?.youtube_code)
              );
            }}
          />
          <MainContainer>
            <MediaHeader
              type={data?.movie?.movie_data?.type}
              productId={data?.movie?.movie_data?.id}
              descAr={data?.movie?.movie_data?.description_ar}
              descEn={data?.movie?.movie_data?.description_en}
              image={`${data?.movie?.movie_data?.poster}`}
              thumb={`${data?.movie?.movie_data?.poster_thumb}`}
              rating={data?.movie?.movie_data?.avg_rate}
              {...data?.movie?.movie_data}
              noPeopleRated={data?.movie?.movie_data?.count_rate}
              tags={tags}
            />
          </MainContainer>
        </Stack>
      </StyledMainBox>
      <StyledSliderContainer>
        <MainContainer>
          <Stack spacing={20}>
            {data?.movie?.movie_data?.cast?.length > 0 && (
              <StyledSliderWithIcon title={t("actors")}>
                <ActorsSlider
                  items={data?.movie?.movie_data?.cast}
                  dots={true}
                  small={true}
                />
              </StyledSliderWithIcon>
            )}
            {data?.movie?.related_movies?.data?.length > 0 && (
              <MediaSliderSection
                movies={data?.movie?.related_movies?.data}
                title={t("singleMovie.related")}
                subTitle={t("singleMovie.more")}
                color="#3DB9EC"
                actions={true}
                dots={true}
              />
            )}
          </Stack>
        </MainContainer>
        <RequestBlock />
      </StyledSliderContainer>
    </Stack>
  );
};

export default MoviesPage;
