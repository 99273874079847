import { Box, TextField, styled } from "@mui/material";
import { ReactNode } from "react";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/cart-slice";

export const CustomTextField = styled(TextField)(() => ({
  borderRadius: "12px",
  color: "#E8D6FF",
  "& .MuiInput-input": {
    color: "white",
    opacity: "0.5",
  },
  "& .MuiInput-root:before": {
    borderBottomColor: "#E8D6FF",
  },
  "& .MuiInput-root:hover:before": {
    borderBottom: "2px solid #bb96ea",
  },
  "& .MuiInput-root:after": {
    borderBottomColor: "#E8D6FF",
  },
  "& .MuiInputLabel-root": {
    color: "#E8D6FF",
    opacity: "0.5",
    fontSize: "1.5rem",
  },
  "& .Mui-focused": {
    color: "#E8D6FF",
    // opacity: "1",
  },
  "& .MuiInputLabel-root.Mui-focused": { color: "#E8D6FF" },
  " & .MuiTextField-root": {
    flexBasis: { mobile: "50%" },
    // opacity: "1",
  },

  " & .MuiInput-input": {
    opacity: "1",
  },
  "& .Mui-disabled:before": {
    borderBottomStyle: "solid",
  },
  // "& .MuiFormHelperText-root": {
  //   color: "#d30000",
  // },
}));

interface Props {
  name: string;
  control?: any;
  label?: string;
  readOnly?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  multiline?: boolean;
}

const SearchTextField = ({
  control,
  name,
  label,
  readOnly,
  children,
  disabled,
  multiline,
}: Props) => {
  const dispatch = useDispatch();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={disabled && ""}
      render={({ field, fieldState: { error } }) => (
        <>
          <CustomTextField
            multiline={!!multiline}
            error={!!error}
            autoComplete="off"
            label={label}
            variant="standard"
            {...field}
            color={error ? "error" : "secondary"}
            helperText={error ? error.message : ""}
            disabled={disabled}
            InputProps={{
              readOnly: readOnly,
            }}
            style={{ flexBasis: readOnly ? "100%" : "" }}
            sx={{
              flexBasis: { mobile: "48%", sm: "20%" },
              "&.MuiInput-input": {
                cursor: readOnly ? "pointer" : "",
                fontSize: "1.5rem",
              },
              width: "100%",
            }}
            onChange={(e) => {
              if (name === "client_note") {
                dispatch(cartActions.addClientNote(e.target.value));
              } else {
                field.onChange(e.target.value);
              }
            }}
          />
          {children}
        </>
      )}
    />
  );
};

export default SearchTextField;
