import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { getSmallerText } from "../../../../Helpers/Functions/getSmallerText";
import { useNavigate } from "react-router-dom";

type Props = {
  item: any;
};

const DrawerSubItem = ({ item }: Props) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => {
        navigate(`/media/${item.slug_en}`);
      }}
      sx={{
        width: "100%",
        padding: "0.5rem 1rem",
        backgroundColor: "transparent",
        borderRadius: "8px",
        cursor: "pointer",
        color: "#ade7ff",
        fontWeight: "bold",
        // transition: "all .1s ease-in",
        "&:hover": {
          backgroundColor: "#e6c2eb",
          color: "#840095",
        },
      }}
    >
      {i18n.language === "en" ? item.name_en : item.name_ar}
    </Box>
  );
};

export default React.memo(DrawerSubItem);
