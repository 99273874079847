import { useEffect, useState } from "react";
import { getData } from "../Services/DataServices";
import { useDispatch } from "react-redux";
import { displayToastErrorApi } from "../Helpers/toastHelper";
import { mainCurrencyActions } from "../store/currency-slice";

// Define a custom hook with a generic type parameter
function useGetCurrency() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getData("currency/list").then((response) => {
          if (response !== undefined) {
            if (response.status === 200) {
              dispatch(
                mainCurrencyActions.setCurrencies(
                  response?.data?.currencies?.data
                )
              );
            }
          }
        });
      } catch (err) {
        // displayToastErrorApi(
        //   "There has been an error in getting your Currency"
        // );
        // console.log(err);
      } finally {
      }
    };

    fetchData();
  }, []);
}

export default useGetCurrency;
