import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  displayToastErrorApi,
  displayToastSuccess,
} from "../Helpers/toastHelper";
import { createData } from "../Services/DataServices";
import { cartActions } from "../store/cart-slice";

const useAddStorage = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const handleAddStorage = async (item: any) => {
    let formData = new FormData();
    formData.append("storage_id", item.id.toString());
    formData.append("quantity", "1");
    let apiUrl = `cart/add-storage`;

    try {
      setLoading(true);
      await createData(apiUrl, formData).then((response) => {
        if (response.status === 200) {
          setData(response.data);
          displayToastSuccess(response?.data?.message);
          dispatch(cartActions.refreshCart());
        }
      });
    } catch (error: any) {
      setError(error);
      displayToastErrorApi(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, handleAddStorage };
};

export default useAddStorage;
