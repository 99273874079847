import { useState } from "react";
import { displayToastErrorApi } from "../Helpers/toastHelper";
import { createData } from "../Services/DataServices";

const useWhishList = (
  productId: string,
  type: string,
  is_favorite?: boolean
) => {
  const [loading, setLoading] = useState(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(is_favorite || false);

  const handleAddOrRemoveFromFavorite = async () => {
    let formData = new FormData();
    formData.append("product_id", productId);
    formData.append("type", type?.toLocaleLowerCase());
    try {
      setLoading(true);
      await createData("wishlist/add-remove", formData).then((res) => {
        if (res.status === 200) {
          setIsFavorite(!isFavorite);
        }
      });
    } catch (error: any) {
      displayToastErrorApi(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  return {
    handleAddOrRemoveFromFavorite,
    loading,
    isFavorite,
  };
};

export default useWhishList;
