import CustomSearchField from "../Inputs/CustomSearchField";

interface SearchInput {
  keyword: string;
}

const SearchComponent = () => {
  return <CustomSearchField />;
};

export default SearchComponent;
