import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import StyledText from "../../Components/StyledComponents/StyledText/StyledText";
import useGetData from "../../Hooks/useGetData";
import NotFound from "../../Components/Blocks/NotFound/NotFound";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";

type Props = {};

const FAQ = (props: Props) => {
  const { data, loading, error } = useGetData("pages/faq/10", []);
  const { i18n, t } = useTranslation();
  if (error) return <ErrorComponent />;
  if (loading) return <MainLoader />;
  return (
    <>
      {data && (
        <MainContainer>
          {" "}
          <Box
            sx={{
              padding: "300px 0 0 0",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "40px",
              },
            }}
          >
            <SectionHead
              color="#CEAFF5"
              title={
                i18n.language === "en"
                  ? data?.data?.page_title?.txt
                  : data?.data?.page_title?.txt_ar
              }
              subTitle={
                i18n.language === "en"
                  ? data?.data?.page_subtitle?.txt
                  : data?.data?.page_subtitle?.txt_ar
              }
            />
            <Stack spacing={4}>
              {data?.data?.sections[0]?.Faqs?.length > 0 ? (
                data?.data?.sections[0]?.Faqs?.map(
                  (item: any, index: number) => (
                    <Stack key={item?.id}>
                      <StyledText
                        sx={{
                          color: "#FFAD0A",
                        }}
                      >
                        {t("faq.question")}
                        {i18n.language === "en"
                          ? item?.question_en
                          : item?.question_ar}
                      </StyledText>
                      <StyledText>
                        {t("faq.answer")}
                        {i18n.language === "en"
                          ? item?.answer_en
                          : item?.answer_ar}
                      </StyledText>
                    </Stack>
                  )
                )
              ) : (
                <NotFound text={t("faq.no-faq")} />
              )}
            </Stack>
          </Box>
        </MainContainer>
      )}
      <RequestBlock />
    </>
  );
};

export default FAQ;
