import { Box, Modal } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { addToCartModalActions } from "../../store/addToCart-slice";
import AddToCartForm from "../Blocks/Forms/AddToCartForm";

type Props = {};

const AddToCartModal = (props: Props) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(addToCartModalActions.closeAddToCartModal());
  };

  const modalState = useSelector((state: any) => state.addToCart);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
  };
  const location = useLocation();
  useEffect(() => {
    dispatch(addToCartModalActions.closeAddToCartModal());
  }, [location]);

  return (
    <Modal
      open={modalState.open}
      onClose={handleClose}
      aria-labelledby="modal-cart-modal"
      aria-describedby="modal-add-to-cart-modal"
    >
      <Box sx={style}>
        <AddToCartForm />
      </Box>
    </Modal>
  );
};

export default AddToCartModal;
