function changeImageQuality(url: string): string {
  const tmdbBaseUrl = "https://image.tmdb.org/t/p/";
  const quality = "original";

  if (url.includes(tmdbBaseUrl)) {
    const parts = url.split(tmdbBaseUrl);
    // ["https://image.tmdb.org/t/p/", "w500/abc.jpg"]
    const pathParts = parts[1].split("/");
    // ["w500", "abc.jpg"]
    pathParts[0] = quality; // replace quality
    // ["original", "abc.jpg"]
    return tmdbBaseUrl + pathParts.join("/");
    // "https://image.tmdb.org/t/p/original/abc.jpg"
  }

  // return original url if it's not from TMDB
  return url;
}

export default changeImageQuality;
