// @ts-nocheck
import { CircularProgress, Stack } from "@mui/material";
import StyledButton from "../../StyledComponents/StyledButton/StyledButton";
import MainTextField from "../MainTextField/MainTextField";
import { useEffect, useRef, useState } from "react";
import {
  displayToastErrorApi,
  displayToastSuccess,
} from "../../../Helpers/toastHelper";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { createData } from "../../../Services/DataServices";
import { useNavigate } from "react-router-dom";
import PhoneInput from "../PhoneInput/PhoneInput";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "libphonenumber-js";
import { TextField, styled, InputLabel } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

type Props = {};

const ContactUsForm = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const recaptchaRef = useRef<ReCAPTCHA | null>();
  const [capVal, setCapVal] = useState(false)
  const key = "6LcGBBsqAAAAAIWILGrhDr4QxoSaioDDu1SWRmDl"

  const onChange = async () => {
    try {
      const token = await recaptchaRef?.current?.executeAsync();
      setCapVal(token)
    } catch (error) {
      console.error('Error executing reCAPTCHA:', error);
    }
  }

  useEffect(() => {
    onChange()
  }, [])

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("validation.name")),
    email: yup
      .string()
      .required(t("validation.email"))
      .email(t("validation.email")),
    phone: yup
      .string()
      .required(t("validation.required"))
      .test("isValid", t("validation.mobile"), (val) => {
        return isValidPhoneNumber(val);
      })
      .test("firstZero", t("validation.mobile"), (val) => {
        // first number after  + or 00 should not be 0

        return val?.trim().length > 8 && val?.trim().charAt(1) !== "0";
      }),
    message: yup
      .string()
      .required(t("validation.message"))
      .min(10, t("validation.message")),
  });

  const handleContactUs = async (data: any) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    formData.append("recaptcha_response", capVal)
    try {
      setSubmitting(true);
      await createData("footer/contact_us", formData).then((res) => {
        if (res?.status === 200) {
          displayToastSuccess(res?.data?.message);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          displayToastErrorApi(res?.data?.message || t("apiMessages.error"));
        }
      });
    } catch (error: any) {
      displayToastErrorApi(error?.message || t("apiMessages.error"));
    } finally {
      setSubmitting(false);
    }
  };
  const {
    handleSubmit,

    control,
    formState: {},
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  return (
    <Stack
      component={"form"}
      spacing={2}
      sx={{
        "& > :last-child": {
          marginTop: "4rem",
        },
      }}
    >
      <InputLabel htmlFor="name"
        sx={{
          color: "#fff",
          padding: "0 14px"
        }}
      >{t("contactUs.name")}</InputLabel>
      <MainTextField
        background="#e8d6ff40"
        color="secondary"
        placeholder={t("contactUs.name")}
        name="name"
        control={control}
      />
      <InputLabel htmlFor="name"
        sx={{
          color: "#fff",
          padding: "0 14px"
        }}
      >{t("contactUs.email")}</InputLabel>
      <MainTextField
        background="#e8d6ff40"
        color="secondary"
        placeholder={t("contactUs.email")} // "البريد الالكتروني"
        name="email"
        control={control}
      />
      {/* <MainTextField
        background="#e8d6ff40"
        color="secondary"
        label="رقم الهاتف"
        name="phone"
        control={control}
      /> */}
      <InputLabel htmlFor="name"
        sx={{
          color: "#fff",
          padding: "0 14px"
        }}
      >{t("contactUs.phone")}</InputLabel>
      <PhoneInput
        background="#e8d6ff40"
        control={control}
        name="phone"
        // label={t("contactUs.phone")} // "رقم الهاتف"
      />
      <InputLabel htmlFor="name"
        sx={{
          color: "#fff",
          padding: "0 14px"
        }}
      >{t("contactUs.message")}</InputLabel>
      <MainTextField
        background="#e8d6ff40"
        color="secondary"
        placeholder={t("contactUs.message")} // "الرسالة"
        name="message"
        multiline
        control={control}
      />
      <ReCAPTCHA
        ref={recaptchaRef}
        value={capVal}
        sitekey={key}
        size="invisible"
        onChange={val => setCapVal(val as any)}
        name="recaptcha_response"
      />
      <StyledButton
        sx={{
          alignSelf: "flex-end",
          backgroundColor: "#CEAFF5",
        }}
        variant="contained"
        type="submit"
        onClick={handleSubmit(handleContactUs)}
        disabled={submitting}
        endIcon={submitting && <CircularProgress size={20} />}
      >
        {
          // "ارسال"
          t("contactUs.send")
        }{" "}
      </StyledButton>
    </Stack>
  );
};

export default ContactUsForm;
