// DeleteDialog.js
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

type Props = {
  isDialogOpen: boolean;
  handleClose: () => void;
  handleConfirm: any;
};

export default function NotAvailableAlert({
  isDialogOpen,
  handleClose,
  handleConfirm,
}: Props) {
  const cookies = new Cookies();
  const { t, i18n } = useTranslation();
  let availableMessage =
    cookies.get(i18n.language === "ar" ? "notAvailableAr" : "notAvailableEn") ||
    "Your item is not available but you can still order it.";
  return (
    <Dialog
      open={isDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          minWidth: {
            mobile: "240px",
            md: "500px",
          },
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          textAlign: "center",
          textTransform: "capitalize",
          textDecoration: "underline",
        }}
      >
        {t("notAvailable.title")}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "8px 1.5rem",
        }}
      >
        <DialogContentText id="alert-dialog-description">
          {availableMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleConfirm();
            handleClose();
          }}
          color="primary"
          autoFocus
        >
          {" "}
          {t("notAvailable.confirm")}
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          color="error"
        >
          {" "}
          {t("notAvailable.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
