import React, { useEffect } from 'react';
import { getLocation } from '../Services/DataServices';
import Cookies from "universal-cookie";
function useActivityLog() {
  const cookies = new Cookies();

  useEffect(() => {
    const currentPage = window.location.pathname;
    const allowedPages = ['/contact-us', '/advanced-search', '/request'];
    const validPage = () => {
      if(currentPage === "/contact-us"){
        return "contact_us"
      } else if (currentPage === "/advanced-search") {
        return "advance_search"
      } else if (currentPage === "/request") {
        return "special_requests"
      }
    }
    if (allowedPages.includes(currentPage) && cookies.get("token")) {
      getLocation("user/set_user_activity_log", validPage())
    }
  }, []);
}

export default useActivityLog;
