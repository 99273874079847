import Cookies from "universal-cookie";
import * as yup from "yup";
import { displayToastErrorApi, displayToastSuccess } from "../toastHelper";
import { createData } from "../../Services/DataServices";

const cookies = new Cookies();

// Function to handle the first step of the forget password process
export const handleForgetPasswordStepOne = async (
  data, // Form data containing email
  setError, // Function to set form errors
  setLoading, // Function to set loading state
  navigate // Function to navigate to another page
) => {
  // Convert the data object to FormData
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  try {
    setLoading(true);
    // Send the email to the server for password reset request
    await createData("password/email", formData).then((res) => {
      if (res.status === 200) {
        // Display success toast and navigate to the next step
        displayToastSuccess(res?.data?.message);
        navigate("/forget-password?step=2");
      } else {
        // Display error toast and set form error for email field
        displayToastErrorApi("الايميل غير موجود");
        setError("email", {
          message: "الايميل غير موجود",
        });
      }
    });
  } catch (error) {
    // console.log(error?.message);
    // console.log(error);
    // Set form error for email field
    setError("email", {
      message: "الايميل غير موجود",
    });
  } finally {
    setLoading(false);
  }
};

// Function to handle the second step of the forget password process
export const handleForgetPasswordStepTwo = async (
  data, // Form data containing verification code
  setError, // Function to set form errors
  setLoading, // Function to set loading state
  navigate // Function to navigate to another page
) => {
  // Convert the data object to FormData
  const formData = new FormData();
  let code = "";
  for (let key in data) {
    code = `${code}${data[key]}`;
  }
  formData.append("code", code);
  cookies.set("rest-code", code, { path: "/" });
  try {
    setLoading(true);
    // Send the verification code to the server for validation
    await createData("password/code/check", formData).then((res) => {
      if (res.status === 200) {
        // Display success toast and navigate to the next step
        displayToastSuccess(res?.data?.message);
        navigate("/forget-password?step=3");
      } else {
        // Display error toast for invalid verification code
        displayToastErrorApi("الكود غير صحيح");
      }
    });
  } catch (error) {
    displayToastErrorApi(error?.response?.data?.errors?.code[0]);
    // console.log(error);
  } finally {
    setLoading(false);
  }
};

// Function to handle the third step of the forget password process

export const handleForgetPasswordStepThree = async (
  data, // Form data containing new password
  setError, // Function to set form errors
  setLoading, // Function to set loading state
  navigate // Function to navigate to another page
) => {
  // Convert the data object to FormData
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  formData.append("code", cookies.get("rest-code"));
  try {
    setLoading(true);
    // Send the new password and verification code to reset the password
    await createData("password/reset", formData).then((res) => {
      if (res.status === 200) {
        // Display success toast and navigate to the login page
        displayToastSuccess(res?.data?.message);
        navigate("/login");
      } else {
        // Display error toast for any other error
        displayToastErrorApi("حدث خطأ ما !");
      }
    });
  } catch (error) {
    // console.log(error?.message);
    // console.log(error);
    // Display error toast for any other error
    displayToastErrorApi(
      error?.message || "اسم المستخدم او كلمة المرور غير صحيحة"
    );
  } finally {
    setLoading(false);
  }
};

// Yup validation schema for the first step of forget password form
export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("البريد الالكتروني غير صحيح")
    .required("البريد الالكتروني مطلوب"),
});

// Yup validation schema for the third step of forget password form
export const validationSchemaStepThree = yup.object().shape({
  password: yup.string().required("كلمة المرور مطلوبة"),
  //must match password
  password_confirmation: yup
    .string()
    .required("تأكيد كلمة المرور مطلوب")
    .oneOf([yup.ref("password")], "يجب أن تتطابق مع كلمة المرور"),
});
