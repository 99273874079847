import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../Components/Auth/AuthLayout";
import ForgetPasswordForm from "../../../Components/Auth/ForgetPasswordForm";

const ForgetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authInfoProps = {
    title: t("forgetPasswordPage.title"),
    subTitle: t("forgetPasswordPage.subTitle"),
    subText: t("forgetPasswordPage.text"),
    buttonText: t("forgetPasswordPage.newAccount"),
    buttonAction: () => {
      navigate("/register");
    },
  };
  return (
    <AuthLayout
      authTitle={t("forgetPasswordPage.loginTitle")}
      authInfo={authInfoProps}
    >
      <ForgetPasswordForm />
    </AuthLayout>
  );
};

export default ForgetPassword;
