import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyledIcon } from "../../../StyledComponents/ControlsIcon/StyledIcon";
import Cookies from "universal-cookie";

type Props = {};

const Cart = (props: Props) => {
  // const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const cookies = new Cookies();
  return (
    <StyledIcon
      onClick={() => {
        // console.log('log from cart button', cookies.get("token"), authState );
        
        if (authState.isAuthenticated) {
          // dispatch(cartActions.openCart());
          navigate("/cart");
        } else {
          navigate("/login");
        }
      }}
      sx={{
        backgroundColor: "primary.main",
        "& svg": {
          "& path": {
            fill: "#fff",
          },
        },
        "&:hover": {
          backgroundColor: "#CEAFF5",
          "& svg": {
            "& path": {
              fill: "#755798",
            },
          },
        },
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.733"
        height="32.563"
        viewBox="0 0 34.733 32.563"
      >
        <path
          id="Icon_metro-cart"
          data-name="Icon metro-cart"
          d="M15.6,33.162a3.256,3.256,0,1,1-3.256-3.256A3.256,3.256,0,0,1,15.6,33.162Zm21.708,0a3.256,3.256,0,1,1-3.256-3.256A3.256,3.256,0,0,1,37.3,33.162Zm0-14.11V6.027H11.254A2.171,2.171,0,0,0,9.083,3.856H2.571V6.027H6.912L8.543,20a4.341,4.341,0,0,0,2.711,7.732H37.3V25.564H11.254a2.171,2.171,0,0,1-2.171-2.171c0-.007,0-.015,0-.022L37.3,19.052Z"
          transform="translate(-2.571 -3.856)"
        />
      </svg>
    </StyledIcon>
  );
};

export default Cart;
