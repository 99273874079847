import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { MdOutlineExpandMore } from "react-icons/md";
import ShoppingCartProduct from "./ShoppingCartProduct";
import React from "react";

type Props = {
  items: any;
  title: string;
  id: number;
  handleExpanded: (id: number) => void;
  expandedItem: number;
};

const CartAccordion = ({
  items,
  title,
  id,
  expandedItem,
  handleExpanded,
}: Props) => {
  if (items.length === 0) return null;
  return (
    <Accordion
      sx={{
        "&.MuiPaper-root": {
          boxShadow: "none",
        },
      }}
      expanded={id === expandedItem}
      onChange={() => {
        handleExpanded(id);
      }}
    >
      <AccordionSummary expandIcon={<MdOutlineExpandMore />}>
        <Typography
          sx={{
            fontWeight: 800,
            fontSize: {
              mobile: "14px",
              xs: "1rem",
              sm: "1.25rem",
            },
            lineHeight: "1.75rem",
            color: "primary.main",
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: {
            mobile: "grid",
          },
          gridTemplateColumns: {
            mobile: "1fr",
            xs: "1fr",
            sm: "1fr",
            md: "1fr",
          },
          justifyItems: {
            mobile: "center",
            sm: "space-between",
            md: "center",
          },
          gap: "1rem",
        }}
      >
        {items?.map((item: any) => (
          <ShoppingCartProduct {...item} key={item?.id} priceType={id} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(CartAccordion);
