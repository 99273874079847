import Slider from "react-slick";
import "./SliderNav.scss";

import { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  activeSlide: number;
  sliderRef: any;
  data: any;
  setNav2?: any;
  nav1?: any;
};

const SliderNav = ({ activeSlide, sliderRef, data }: Props) => {
  return (
    //       sx={{
    //         position: "absolute",
    //         left: "0%",
    //         top: "50%",
    //         transform: "translateY(-50%)",
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Box
    //         sx={{ width: "100px", height: "1px", backgroundColor: "white" }}
    //       />
    //       <Box
    //         sx={{
    //           width: "75px",
    //           height: "75px",
    //           backgroundColor: "#ffffff30",
    //           borderRadius: "20px",
    //         }}
    //       />
    //     </Box>
    //     {data &&
    //       data?.map((slide: any) => {
    //         return (
    //           <Typography
    //             sx={{
    //               color: "white",
    //               fontSize: "1.5rem",
    //               position: "absolute",
    //               left: "100%",
    //               top:
    //                 activeSlide + 1 == slide?.id
    //                   ? "50%"
    //                   : activeSlide + 1 > slide?.id
    //                   ? `calc(50% + ${(activeSlide + 1 - slide?.id) * 40}px)`
    //                   : `calc(50% - ${(slide?.id - activeSlide - 1) * 40})px`,
    //               // transform: "translateY(-50%)",
    //             }}
    //             key={slide?.id}
    //           >
    //             {`0${slide?.id}`}
    //           </Typography>
    //         );
    //       })}
    //   </Box>
    // </Box>
    // <Box
    //   sx={{
    //     position: "absolute",
    //     left: "0%",
    //     top: "30%",
    //     width: "450px",
    //     zIndex: "99",
    //     transition: "all 0.5s ease-in-out",
    //   }}
    // >
    //   <Slider
    //     {...setting}
    //     // slide={value}
    //     centerMode={true}
    //     asNavFor={nav1}
    //     ref={(slider2) => setNav2(slider2)}
    //   >
    //     {data &&
    //       data?.map((slide: any) => {
    //         return (
    //           <Typography
    //             sx={{
    //               color: "white",
    //               fontSize: activeSlide + 1 == slide?.id ? "2.5rem" : "1.5rem",
    //               cursor: "pointer",
    //             }}
    //             key={slide?.id}
    //             // onClick={() => {
    //             //   sliderRef?.current?.slickGoTo(slide?.id - 1);
    //             // }}
    //           >
    //             {`0${slide?.id}`}
    //           </Typography>
    //         );
    //       })}
    //   </Slider>
    // </Box>
    // <Box
    //   sx={{
    //     position: "absolute",
    //     left: "0%",
    //     top: "30%",
    //     width: "200px",
    //     zIndex: "99",
    //     transition: "all 0.5s ease-in-out",
    //   }}
    // >
    //   {data && (
    //     <Picker
    //       selectedValue={value}
    //       onValueChange={onChange}
    //       // onScrollChange={this.onScrollChange}
    //     >
    //       {data.map((slide: any, index: number) => (
    //         <Picker.Item
    //           onClick={() => {
    //             sliderRef.current.slickGoTo(slide?.id - 1);
    //           }}

    //           value={slide?.id - 1}
    //           key={slide?.id}
    //         >
    //           <Typography
    //             sx={{
    //               color: "white",
    //               fontSize: activeSlide + 1 == slide?.id ? "2.5rem" : "1.5rem",
    //               cursor: "pointer",
    //             }}
    //             key={slide?.id}
    //             onClick={() => {
    //               sliderRef.current.slickGoTo(slide?.id - 1);
    //             }}
    //           >
    //             {`0${slide?.id}`}
    //           </Typography>
    //         </Picker.Item>
    //       ))}
    //     </Picker>
    //   )}
    // </Box>
    <Box
      sx={{
        position: "absolute",
        left: {
          xs: "12px",
          sm: "25px",
          xl: "40px",
          xxl: "65px",
        },
        top: "20%",
        width: "200px",
        zIndex: "99",
        transition: "all 0.5s ease-in-out",
        height: "250px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 0.5s ease-in-out",
        }}
      >
        {data &&
          data?.map((slide: any) => {
            return (
              <Box
                key={slide?.id}
                sx={{
                  position: "relative",
                  width: "100%",
                  transition: "all 0.5s ease-in-out",
                }}
              >
                <Typography
                  sx={{
                    width: {
                      xs: "35px",
                      sm: "50px",
                      xl: "55px",
                      xxl: "70px",
                    },
                    padding: "5px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "12px",
                    backgroundColor:
                      activeSlide + 1 == slide?.key
                        ? "rgba(238, 238, 238, 0.3)"
                        : "",
                    position: "relative",
                    color: "white",
                    fontSize: {
                      xs: activeSlide + 1 == slide?.key ? "1.25rem" : "1rem",
                      sm: activeSlide + 1 == slide?.key ? "1.75rem" : "1.25rem",
                      xxl: activeSlide + 1 == slide?.key ? "2.5rem" : "1.5rem",
                    },
                    cursor: "pointer",
                    transition: "all 0.5s ease-in-out",

                    "&::after": {
                      content: "''",
                      display: "block",
                      opacity: activeSlide + 1 == slide?.key ? "1" : "0",
                      position: "absolute",
                      right: {
                        xs: "35px",
                        sm: "50px",
                        xl: "55px",
                        xxl: "70px",
                      },
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: {
                        xs: "50px",
                        sm: "75px",
                        xxl: "100px",
                      },
                      height: "1px",
                      backgroundColor: "white",
                      transition: "all 0.5s ease-in",
                    },
                  }}
                  key={slide?.key}
                  onClick={() => {
                    sliderRef.current.slickGoTo(slide?.key - 1);
                  }}
                >
                  {`${slide?.key <= 9 ? '0'+slide?.key : slide?.key}`}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
    // <Box
    //   sx={{
    //     position: "absolute",
    //     left: "0%",
    //     top: "30%",
    //     width: "200px",
    //     zIndex: "99",
    //     transition: "all 0.5s ease-in-out",
    //   }}
    // >
    //   <Box
    //     sx={{
    //       position: "relative",
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       flexDirection: "column",
    //       gap: "1rem",
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         position: "absolute",
    //         left: "0%",
    //         top: "50%",
    //         transform: "translateY(-50%)",
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Box
    //         sx={{ width: "100px", height: "1px", backgroundColor: "white" }}
    //       />
    //       <Box
    //         sx={{
    //           width: "75px",
    //           height: "75px",
    //           backgroundColor: "#ffffff30",
    //           borderRadius: "20px",
    //         }}
    //       />
    //     </Box>
    //     {data &&
    //       data?.map((slide: any) => {
    //         return (
    //           <Typography
    //             sx={{
    //               color: "white",
    //               fontSize: "1.5rem",
    //               position: "absolute",
    //               left: "100%",
    //               top:
    //                 activeSlide + 1 == slide?.id
    //                   ? "50%"
    //                   : activeSlide + 1 > slide?.id
    //                   ? `calc(50% + ${(activeSlide + 1 - slide?.id) * 40}px)`
    //                   : `calc(50% - ${(slide?.id - activeSlide - 1) * 40})px`,
    //               // transform: "translateY(-50%)",
    //             }}
    //             key={slide?.id}
    //           >
    //             {`0${slide?.id}`}
    //           </Typography>
    //         );
    //       })}
    //   </Box>
    // </Box>
  );
};

export default SliderNav;
