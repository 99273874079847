import { Box } from "@mui/material";
import React from "react";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import RequestForm from "../../Components/Blocks/Forms/RequestForm";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import { useTranslation } from "react-i18next";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";
import WhatsApp from "../../Components/Blocks/WhatsApp/WhatsApp";
import useActivityLog from "../../Hooks/useGetLocation";

type Props = {};

const Request = (props: Props) => {
  const { t } = useTranslation();
  useActivityLog()
  return (
    <Box>
      <WhatsApp />
      <ParticlesBackGround />
      <MainContainer>
        {" "}
        <Box
          sx={{
            padding: {
              mobile: "180px 0",
              sm: "200px 0",
              md: "250px 0",
              lg: "300px 0",
            },
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: {
              xs: "50px",
              md: "100px",
              xl: "120px",
            },
          }}
        >
          <SectionHead
            color="#81CEFC"
            title={t("requestForm.title")}
            subTitle={t("requestForm.subTitle")}
          />
          <RequestForm />
        </Box>
      </MainContainer>
    </Box>
  );
};

export default Request;
