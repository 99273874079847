import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import CustomPagination from "../../Components/Blocks/CustomPagination/CustomPagination";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import MediaListGrid from "../../Components/Blocks/MediaListGrid/MediaListGrid";
import NotFound from "../../Components/Blocks/NotFound/NotFound";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import useGetData from "../../Hooks/useGetData";

type Props = {};

// This component displays a list of media items based on the selected category.

const MediaCategoryPage = (props: Props) => {
  const { t, i18n } = useTranslation();
  //get the category slug from the url and the params
  const { slug } = useParams<string>();
  const location = useLocation();
  const query = queryString.parse(location.search);
  //get data from api using custom hook
  const { data, loading, error } = useGetData(
    `category/${slug}/list?page=${query?.page || "1"}&sort_by=${
      query?.sort_by || ""
    }`,
    [query.page, query?.sort_by]
  );

  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  return (
    <>
      <ParticlesBackGround />
      <MainContainer>
        <PageContentContainer>
          {data?.data?.data && (
            <SectionHead
              color="#CEAFF5"
              title={i18n?.language === "ar" ? query?.name : query?.nameEn}
              // subTitle={t("filter.subTitle")}
              actions={true}
              sortButton
            />
          )}
          {data?.data?.data && data?.data?.data.length > 0 ? (
            <MediaListGrid cardsList={data?.data?.data} />
          ) : (
            <NotFound text={t("noCategories")} />
          )}
          {data?.data?.last_page && (
            <CustomPagination
              totalPages={data?.data?.last_page}
              from={data?.data?.from}
              to={data?.data?.to}
              total={data?.data?.total}
            />
          )}
        </PageContentContainer>
      </MainContainer>
      <RequestBlock />
    </>
  );
};

export default MediaCategoryPage;
