import { styled } from "@mui/material";
import MenuContainer from "./StyledMenuContainer";
const DefaultMenu = styled(MenuContainer)({
  backgroundColor: "#fff",
  top: "60px !important",
  boxShadow: "0px 3px 30px #00000080",
  "&:before": {
    display: "none"
  }
})

export default DefaultMenu;