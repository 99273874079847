import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StyledTypography } from "../../StyledComponents/SmallCard/SmallCard";
import CartAccordion from "./CartAccordion";
import SelectStorage from "./SelectStorage";
import ShoppingCartHead from "./ShoppingCartHead";
import ShoppingCartSummary from "./ShoppingCartSummary";

type Props = {
  expanded: number;
  setExpanded: (id: number) => void;
  data: any;
};

const ShoppingCartMain = ({ expanded, setExpanded, data }: Props) => {
  const { t } = useTranslation();

  // State
  const cartState = useSelector((state: any) => state.cart);

  // Handle Accordion expanded
  const handleExpanded = (id: number) => {
    if (expanded === id) {
      setExpanded(0);
    } else {
      setExpanded(id);
    }
  };

  return (
    <Box
      sx={{
        background: "white",
        width: "100%",
        "@media (max-width: 1024px)": {
          order: 1,
        },
      }}
    >
      {/* <ShoppingCartHead /> */}

      <Box
        sx={{
          mx: {

            mobile: "8px",
            sm: "28px",
            md: "44px",
            lg: "60px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderBottom: "1px solid rgb(117 87 152)",
            borderTop: "1px solid rgb(117 87 152)",
            py: "2rem",
          }}
        >
          {cartState?.dvdItems?.length > 0 && (
            <CartAccordion
              id={4}
              items={cartState?.dvdItems}
              title={t("dvd")}
              handleExpanded={handleExpanded}
              expandedItem={expanded}
            />
          )}
          {cartState?.onlineItems?.length > 0 && (
            <CartAccordion
              id={1}
              items={cartState?.onlineItems}
              title={t("online")}
              handleExpanded={handleExpanded}
              expandedItem={expanded}
            />
          )}
          {cartState?.downloadItems?.length > 0 && (
            <CartAccordion
              id={2}
              items={cartState?.downloadItems}
              title={t("download")}
              handleExpanded={handleExpanded}
              expandedItem={expanded}
            />
          )}

          {cartState?.storageItems?.length > 0 && (
            <CartAccordion
              id={3}
              items={cartState?.storageItems}
              title={t("storage")}
              handleExpanded={handleExpanded}
              expandedItem={expanded}
            />
          )}
          {cartState?.storageItems?.length > 0 && <SelectStorage />}

          {data?.cart?.length === 0 && (
            <StyledTypography
              sx={{
                cursor: "auto",
                fontSize: "2rem",
              }}
            >
              {t("purchases.purchases-cart.noItems")}
            </StyledTypography>
          )}
        </Box>
        {data?.cart?.length !== 0 && <ShoppingCartSummary />}
      </Box>
    </Box>
  );
};

export default React.memo(ShoppingCartMain);
