import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { IconButton, InputBase, Stack, Typography } from "@mui/material";
import { FaMinus, FaPlus } from "react-icons/fa";

interface FormInputProps {
  name: string;
  control: any;
  label: string;
  defaultValue: number;
}

const QuantityTextfield = ({ name, control, defaultValue }: FormInputProps) => {
  const iconStyles = {
    borderRadius: "50%",
    fontSize: ".875rem",
    width: "16px",
    height: "16px",
    padding: "0",
    minHeight: "unset",
    color: "white",
  };
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography
        sx={{
          fontSize: "1.15rem",
          color: "white",
        }}
      >
        عدد نسخ ال DVD :
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              onClick={() => onChange(Number(value || defaultValue) + 1)}
              sx={iconStyles}
            >
              <FaPlus />
            </IconButton>
            <InputBase
              type="number"
              size="small"
              color="secondary"
              onChange={(e) => {
                if (Number(e.target.value) <= 99) {
                  onChange(e.target.value);
                }
              }}
              defaultValue={1}
              value={value}
              sx={{
                width: "100%",
                maxWidth: "40px",
                textAlign: "center",
                // border: "1px solid #e8e8e8",

                borderRadius: "8px",
                fontWeight: "700",
                "& input": {
                  color: "secondary.main",
                  textAlign: "center",
                  fontSize: "1.25rem",
                  "&::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "&::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                },
              }}
            />
            <IconButton
              onClick={() => {
                if (value > 1) {
                  onChange(Number(value) - 1);
                }
              }}
              sx={iconStyles}
            >
              <FaMinus />
            </IconButton>
          </Stack>
        )}
      />
    </Stack>
  );
};

export default QuantityTextfield;
