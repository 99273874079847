import { Box, ClickAwayListener, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaArrowDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import useConstructAddress from "../../../Hooks/useConstructAddress";
import useGetData from "../../../Hooks/useGetData";
import { cartActions } from "../../../store/cart-slice";
import MenuContainer from "../../StyledComponents/MenuContainer/StyledMenuContainer";
import { StyledSecondaryButton } from "../../StyledComponents/StyledButton/StyledButton";
type Props = {};

const AddressesButton = (props: Props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  // Handle Language
  const { t, i18n } = useTranslation();

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const { control, handleSubmit } = useForm({});
  const refreshAddress = useSelector((state: any) => state.refreshAddress);

  // const onSubmit: SubmitHandler<IFormInput> = async () => {};
  const {
    data: address,
    error,
    loading,
  } = useGetData("address/all", [refreshAddress]);

  const selectedAddressId = useSelector((state: any) => state.cart?.address_id);

  const [selectedAddress, setSelectedAddress] = useState<any>(undefined);

  useEffect(() => {
    if (address?.addresses?.length > 0) {
      setSelectedAddress(
        address?.addresses?.find((item: any) => item.id === selectedAddressId)
      );
    }
  }, [address, selectedAddressId]);

  // add address to redux store
  const dispatch = useDispatch();
  const cartState = useSelector((state: any) => state.cart);

  // address constructor
  const { constructAddress } = useConstructAddress();
  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <Box
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <StyledSecondaryButton
          onClick={() => {
            setShowMenu(!showMenu);
          }}
          variant="contained"
          sx={{
            backgroundColor: "white",
            color: "#755798",
            textTransform: "none",
            whiteSpace: "nowrap",
            overflow: "hidden",

            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "22px",
              lg: selectedAddress?.detailed_address ? "18px" : "22px",
            },
            borderRadius: "12px",
            height: "60px",
            width: "100%",
            "&:hover": {
              backgroundColor: "#E8D6FF",
            },
            maxWidth: {
              xs: "280px",
              sm: "350px",
              lg: "unset",
            },
            alignItems: "center",
            justifyContent: "space-between",
          }}
          endIcon={<FaArrowDown />}
        >
          <Tooltip
            title={
              selectedAddress
                ? constructAddress(selectedAddress, i18n.language)
                : t("purchases.payment.address")
            }
          >
            <Typography
              sx={{
                maxWidth: "280px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {selectedAddress
                ? constructAddress(selectedAddress, i18n.language)
                : t("purchases.payment.address")}
            </Typography>
          </Tooltip>
        </StyledSecondaryButton>

        {showMenu && address?.addresses?.length > 0 && (
          <MenuContainer
            sx={{
              width: "100%",
              top: "70px",
            }}
          >
            {address?.addresses?.map((item: any, index: number) => {
              if (item?.is_active === false) return null;
              return (
                <Controller
                  key={item.id}
                  name="sort"
                  control={control}
                  render={({ field, fieldState: { error, isTouched } }) => (
                    <Typography
                      onClick={() => {
                        field.onChange(item.id);
                        setShowMenu(false);
                        setSelectedAddress(item);
                        dispatch(cartActions.setAddress(item.id));
                        dispatch(cartActions.setShippingArea(item?.inside));
                      }}
                      key={item.id}
                      sx={{
                        position: "relative",

                        "&::after": {
                          display:
                            index === address?.addresses?.length - 1
                              ? "none"
                              : "block",
                        },
                      }}
                    >
                      {constructAddress(item, i18n.language)}
                    </Typography>
                  )}
                />
              );
            })}
          </MenuContainer>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default React.memo(AddressesButton);
