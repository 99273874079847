import { Outlet } from "react-router-dom";
import Maintenance from "../../Components/Layout/Maintenance/Maintenance";
import useGetData from "../../Hooks/useGetData";
import Cookies from "universal-cookie";

type Props = {};
const cookies = new Cookies();
const handleSaveNotAvailable = (data: any) => {
  cookies.set("notAvailableAr", data?.not_available_ar, { path: "/" });
  cookies.set("notAvailableEn", data?.not_available_en, { path: "/" });
};

const MaintenancePage = (props: Props) => {
  const { data, error } = useGetData("settings/all", []);
  if (data?.settings) {
    handleSaveNotAvailable(data?.settings);
  }

  if (data?.settings?.in_maintenance_flag === "1")
    return <Maintenance {...data?.settings} />;
  return (
    <>
      <Outlet />
    </>
  );
};

export default MaintenancePage;
