import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

export interface trailerSlice {
  open: boolean;
  url: string;
}

const initialState: trailerSlice = {
  open: false,
  url: "",
};

const trailerSlice = createSlice({
  name: "trailer",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.open = true;
      state.url = action.payload;
    },
    closeModal: (state) => {
      state.open = false;
      state.url = "";
    },
  },
});

export const trailerActions = trailerSlice.actions;
export default trailerSlice;
