import { styled } from "@mui/material";

const LinearGradient = styled("div")({
  backgroundImage:
    "linear-gradient(183deg, #000000b5 0%, rgb(0 87 123 / 4%) 35%, #321c4af7 53%, #2917391f 84%, #2c203000 100%)",
  position: "absolute",
  height: "150vh",
  width: "100%",
  top: "0",
  right: "0",
  zIndex: "-1",
});

export default LinearGradient;
