import { Fab } from '@mui/material';
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';

type Props = {}

const ScrollToTop = ({}: Props) => {
  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  const { i18n } = useTranslation();
  return (
    <Fab color="primary" aria-label="add" sx={{
      position: "fixed",
      bottom: 30,
      left: i18n.language === "ar" ? "calc(100% - 90px)" : '30px',
    }} 
    onClick={scrollToTop}
    >
      <KeyboardArrowUpIcon />
    </Fab>
  )
}

export default ScrollToTop;