import { useLocation, useNavigate } from 'react-router-dom'
import MainLoader from '../../Components/Blocks/MainLoader/MainLoader';
import { Box, Typography } from '@mui/material';
import { CircleLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { UnsubscripeNewsLetter } from '../../Services/DataServices';

type Props = {}

function Unsubscripe({}: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('')
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const token = queryParams.get('token');
  // console.log(email, token);

  const handleUnsubscripe = async (email: any, token: any) => {
    try {
      await UnsubscripeNewsLetter('user/unsubscribe', {email, token})
        .then((res) => {
          setLoading(false)
          setMessage(res?.data?.message)
          setTimeout(() => {
            navigate('/')
          }, 15000);
        })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleUnsubscripe(email, token)
  }, [])

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading && <CircleLoader color="#FFAD0A" size={250} />}

      {!loading &&  <Typography
          sx={{
            color: "#FFAD0A",
            textAlign: "center",
            margin: "auto",
            fontSize: {
              mobile: "14px",
              md: "24px",
            },
            fontWeight: 600,
            padding: {
              mobile: "6px 0",
              md: "8px 0",
            },
          }}
        >{message}</Typography>}
    
    </Box>
  )
}

export default Unsubscripe