import React from "react";
import AuthLayout from "../../../Components/Auth/AuthLayout";
import LoginForm from "../../../Components/Auth/LoginForm";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const Login = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();

  const authInfoProps = {
    title: matches ? t("loginPage.title") : t("loginPage.secondTitle"),
    subTitle: t("loginPage.text"),
    subText: t("loginPage.desc"),
    buttonText: t("loginPage.newAccount"),
    buttonAction: () => {
      navigate("/register");
    },
  };
  return (
    <AuthLayout
      register={false}
      authTitle={t("loginPage.login")}
      authInfo={authInfoProps}
    >
      <LoginForm />
    </AuthLayout>
  );
};

export default Login;
