// import { Box } from "@mui/material";
import ReactWhatsappButton from "react-whatsapp-button";
import "react-whatsapp-widget/dist/index.css";
// import { displayToastSuccess } from "../../../Helpers/toastHelper";
import { useState } from "react";

const WhatsApp = (props) => {
  const [active, setActive] = useState(false)
  return (
    // <div 
    //   onMouseEnter={(e) => {
    //     e.stopPropagation()
    //     setActive(true)
    //     displayToastSuccess(props.message)
    //   }}
    //   onMouseLeave={() => setActive(false)}
    //   style={{position: "fixed", right: "2rem", bottom: "2rem", width: "60px", height: "60px"}} >
    //   {/* <p style={{position: "absolute", width:"50vw" , backgroundColor: "#fff", opacity: active ? 1 : 0, top: "50%", transform: "translateY(-50%)", right: "100%"}} >{props.message}</p> */}
    // </div>
    <ReactWhatsappButton countryCode="965" phoneNumber="67042008" animated />
  );
};

export default WhatsApp;
