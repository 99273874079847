import React, { useEffect, useState } from "react";
import TiltAnimation from "../../Animation/TiltAnimation";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Tooltip,
  Button,
} from "@mui/material";
import { singleItemType } from "../../../Types/Types";
import { useTranslation } from "react-i18next";
import imagePlaceholder from "../../../Assets/Images/default-movie.jpg";
import useOrderUrl from "../../../Hooks/useOrderUrl";
import { useParams } from "react-router-dom";
import MainLoader from "../MainLoader/MainLoader";

const SmallOrderCard = ({
  poster,
  name_en,
  name_ar,
  id,
  itemType,
  type,
  mediaType,
  product_id,
}: singleItemType) => {
  const { t, i18n } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  
  const { url, loading, error, getUrl } = useOrderUrl(
    orderId || "",
    itemType,
    mediaType,
    String(product_id)
  );
  const [disabled, setDisabled] = useState(false);

  return (
    <Box sx={{ width: "fit-content", position: "relative" }}>
      <TiltAnimation>
        <Card
          sx={{
            position: "relative",
            borderRadius: 4,
            backgroundColor: "transparent",
            boxShadow: "14px 0px 30px #00000054",
            width: "200px",
            height: "auto"
          }}
        >
          <CardActionArea
            onClick={() => {
              getUrl();
              if(itemType === "1") {
                setDisabled(true)
              }
              if(itemType === "2") {
                setDisabled(true)
                setTimeout(() => {
                  setDisabled(false)
                }, 3000);
              }
            }}
            sx={{
              zIndex: 2,
            }}
          >
            <CardMedia
              component="img"
              height="250"
              image={poster}
              alt={name_en}
              onError={(e) => {
                e.currentTarget.src = imagePlaceholder;
              }}
            />
            <CardContent
              sx={{
                position: "relative",
                width: "100%",
                overflow: "hidden",
                padding: "8px",
                minHeight: "80px",
                backgroundImage:
                  " linear-gradient(180deg, rgb(83 78 78 / 6%) 0%, rgb(0 0 0 / 92%) 100%)",
              }}
            >
              <Typography
                variant="h5"
                component="h6"
                sx={{
                  color: "white",
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                  fontSize: {
                    mobile: "14px",
                    md: "16px",
                  },
                }}
              >
                {i18n.language === "en"
                  ? name_en || name_ar
                  : name_ar || name_en}
              </Typography>
              {(itemType === "1" || itemType === "2") && (
                <div style={{minWidth: "100%", minHeight: "34px", display: "inline-block", position: "relative"}} >
                  <Button
                    sx={{
                      width: {
                        mobile: "100%",
                      },
                      height: {
                        mobile: "34px",
                      },
                      borderRadius: "8px",
                      backgroundImage: "linear-gradient(180deg, #FFAD0A 0%, #FFAD0A 100%)",
                      color: "white",
                      fontSize: {
                        mobile: "18px",
                      },
                      position: "absolute",
                      bottom: "0",
                      left: "50%",
                      transform: "translateX(-50%)",
                      transition: "all 0.5s ease-in-out",
                      lineHeight: "24px",
                      "&.Mui-disabled": {
                        backgroundColor: "#FFAD0A",
                        backgroundImage: "none",
                      },
                    }}
                    variant="contained"
                    // onClick={() => {
                      // openAddToCartModal();
                      // handleAddToCart(type, id, 1);
                    // }}
                  >
                    {itemType === "1" ? t("addToCartModal.online") : t("addToCartModal.download")}
                  </Button>
                </div>
              ) }
            </CardContent>
          </CardActionArea>
        </Card>
      </TiltAnimation>
      {(itemType === "1" || itemType === "2") && (
        disabled && (
          <Box sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundColor: "#00000060",
            borderRadius: 4,
            cursor: "not-allowed"
          }}/>
        )
      )}
    </Box>
  );
};

export default React.memo(SmallOrderCard);
