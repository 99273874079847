import { Stack } from "@mui/material";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import ProfileAddress from "../../Components/Blocks/Profile/ProfileAddress";
import ProfilePersonal from "../../Components/Blocks/Profile/ProfilePersonal";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";

type Props = {};

const ProfilePage = (props: Props) => {
  return (
    <Stack>
      <ParticlesBackGround />
      <MainContainer>
        <PageContentContainer>
          <Stack
            spacing={{
              mobile: 10,
              sm: 20,
              lg: 30,
            }}
          >
            <ProfilePersonal />
            <ProfileAddress />
          </Stack>
        </PageContentContainer>
      </MainContainer>
      <RequestBlock />
    </Stack>
  );
};

export default ProfilePage;
