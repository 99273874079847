import { Box } from "@mui/material";
import { CircleLoader } from "react-spinners";

type Props = {};

const MainLoader = (props: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircleLoader color="#FFAD0A" size={250} />
    </Box>
  );
};

export default MainLoader;
