import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { MdOutlineExpandMore } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DrawerSubItem from "./DrawerSubItem";

type Props = {
  menu: any;
  openedList: any;
  setOpenedList: any;
};

function DrawerNavItem({ menu, openedList, setOpenedList }: Props) {
  // console.log("menu", menu);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const handleOpen = () => {
    if (menu?.subMenu) {
      if (openedList === menu?.id) {
        setOpenedList(null);
      } else {
        setOpenedList(menu?.id);
      }
    }
  };

  return (
    <Accordion
      expanded={openedList === menu.id}
      sx={{
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "#81cefc21",
        },
        "&.Mui-expanded": {
          margin: "0px !important",
          backgroundColor: "#81cefc21",
          boxShadow: "0px 3px 12px #7c1b95bf",
        },
        "&:before": {
          display: "none",
        },
        "& svg": {
          fontSize: "1.5rem",
          color: "#ade7ff",
        },
      }}
      onChange={handleOpen}
    >
      <AccordionSummary
        expandIcon={menu?.subMenu && <MdOutlineExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={(e) => {
          if (!menu?.subMenu) {
            navigate(menu?.slug_en);
            e.stopPropagation();
          } else {
            handleOpen();
          }
        }}
      >
        <Typography
          sx={{
            color: "#ade7ff",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
          onClick={(e) => {
            if (!menu?.subMenu) {
              navigate(menu?.slug_en);
              e.stopPropagation();
            } else {
              handleOpen();
            }
          }}
        >
          {i18n.language === "en" ? menu.name_en : menu.name_ar}
        </Typography>
      </AccordionSummary>
      {menu?.subMenu && menu?.subMenu.length > 0 && (
        <AccordionDetails
          sx={{
            maxHeight: "300px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#ade7ff",
              //thumb radius
              borderRadius: "5px",
            },
          }}
        >
          {menu?.subMenu.map((subMenuItem: any) => (
            <DrawerSubItem key={subMenuItem?.id} item={subMenuItem} />
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
}

export default React.memo(DrawerNavItem);
