import { Box } from "@mui/material";
import React from "react";
import Currency from "./Currency";
import Profile from "./Profile";
import Favorite from "./Favorite";
import Cart from "./Cart";
import Language from "./Language";
import MemoNotifications from "./Notifications";
import { useSelector } from "react-redux";

type Props = {};

const Controls = (props: Props) => {
  const authState = useSelector((state: any) => state.auth);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: {
          mobile: "0.75rem",
          // lg: "1.25rem",
          // xl: "1.5rem",
          // xxl: "2rem",
        },
      }}
    >
      <Currency />
      <Profile />
      <Favorite />
      <Cart />
      {authState.isAuthenticated && <MemoNotifications/>}
      <Language />
    </Box>
  );
};

export default Controls;
