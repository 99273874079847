import { Box, Stack } from "@mui/material";
import ContactUsForm from "../../Components/Blocks/Forms/ContactUsForm";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";
import { useTranslation } from "react-i18next";
import WhatsApp from "../../Components/Blocks/WhatsApp/WhatsApp";
import useActivityLog from "../../Hooks/useGetLocation";

type Props = {};

const ContactUs = (props: Props) => {
  const { t } = useTranslation();
  useActivityLog()

  return (
    <>
      {" "}
      <WhatsApp />
      <ParticlesBackGround />
      <Stack>
        <MainContainer>
          <PageContentContainer>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: {
                  xs: "50px",
                  md: "100px",
                  xl: "120px",
                },
              }}
            >
              <SectionHead
                color="#CEAFF5"
                title={t("contactUs.title")}
                subTitle={t("contactUs.subTitle")}
              />
              <ContactUsForm />
            </Box>
          </PageContentContainer>
        </MainContainer>

        <RequestBlock />
      </Stack>
    </>
  );
};

export default ContactUs;
