import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { handleRegister } from "../../../Helpers/AuthFunctions/RegisterHelpers";
// import MainButton from "../../Blocks/Buttons/MainButton/MainButton";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import AuthTextField from "../../Blocks/AuthTextField/AuthTextField";
import { StyledAuthButton } from "../../StyledComponents/StyledButton/StyledButton";
import "./index.scss";
import PhonInputReg from "../../Blocks/PhoneInput/PhonInputReg";
import { isValidPhoneNumber } from "libphonenumber-js";

const RegisterFrom = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    handleRegister(data, setError, navigate, setLoading);
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required(t("registerForm.validation.name")),
    username: yup.string().required(t("registerForm.validation.userName")),
    email: yup
      .string()
      .email(t("registerForm.validation.email"))
      .required(t("registerForm.validation.email")),
    mobile: yup
      .string()
      .required(t("registerForm.validation.mobile"))
      .test("is-valid-phone", t("registerForm.validation.mobile"), (value) => {
        if (value) {
          return isValidPhoneNumber(value);
        }
        return true;
      }),
    password: yup.string().required(t("registerForm.validation.password")),
    password_confirmation: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        t("registerForm.validation.confirmPassword")
      ),
  });
  const {
    handleSubmit,
    register,
    setError,
    control,
    formState: { errors, touchedFields, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  return (
    <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
      <AuthTextField
        placeholder={t("registerForm.name")}
        name="name"
        register={register}
        error={errors.name}
        touched={touchedFields.name}
      />
      <AuthTextField
        placeholder={t("registerForm.userName")}
        name="username"
        register={register}
        error={errors.username}
        touched={touchedFields.username}
      />
      <AuthTextField
        placeholder={t("registerForm.email")}
        name="email"
        register={register}
        error={errors.email}
        touched={touchedFields.email}
      />
      <PhonInputReg
        placeholder={t("registerForm.mobile")}
        name="mobile"
        register={register}
        error={errors.mobile}
        touched={touchedFields.mobile}
        control={control}
      />
      <AuthTextField
        placeholder={t("registerForm.password")}
        name="password"
        type="password"
        register={register}
        error={errors.password}
        touched={touchedFields.password}
      />
      <AuthTextField
        placeholder={t("registerForm.confirmPassword")}
        name="password_confirmation"
        type="password"
        register={register}
        error={errors.password_confirmation}
        touched={touchedFields.password_confirmation}
      />
      <div className="main-btn">
        <StyledAuthButton
          variant="contained"
          type="submit"
          disabled={loading}
          endIcon={loading && <CircularProgress size={20} />}
        >
          {t("registerForm.register")}
        </StyledAuthButton>
      </div>
    </form>
  );
};

export default RegisterFrom;
