import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledIcon } from "../../../StyledComponents/ControlsIcon/StyledIcon";

type Props = {};

const Language = (props: Props) => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
  };

  return (
    <StyledIcon
      sx={{
        backgroundColor: "white",
        "& h6": {
          color: "primary.main",
        },
        "&:hover": {
          backgroundColor: "primary.main",
          "& h6": {
            color: "white",
          },
        },
      }}
      onClick={handleChangeLanguage}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: {
            mobile: "1.25rem",
            lg: "1.5rem",
            xl: "1.75rem",
            xxl: "2rem",
          },
          fontWeight: "bold",
          transform: "translateY(0.2rem)",
        }}
      >
        {i18n.language === "en" ? "AR" : "EN"}
      </Typography>
    </StyledIcon>
  );
};

export default Language;
