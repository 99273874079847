import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  totalPages: number;
  from: number;
  to: number;
  total: number;
};

const StyledPagination = styled(Pagination)({
  backgroundColor: "#e8d6ff21",
  borderRadius: "15px",
  padding: "1.5rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",

  "& .MuiPaginationItem-icon": {
    color: "#FFAD0A",
    fontSize: "2.5rem",
  },
  "& .MuiPaginationItem-root": {
    fontSize: "1.75rem",
    fontWeight: "bold",
    color: "white",
    "&.Mui-selected": {
      backgroundColor: "#b27eef7a",
      color: "#FFAD0A",
    },
  },
  "@media (max-width: 600px)": {
    padding: "1rem .5rem",
    "& .MuiPaginationItem-root": {
      fontSize: "1rem",
    },
    "& .MuiPaginationItem-icon": {
      fontSize: "1.5rem",
    },
  },
});

const CustomPagination = ({ totalPages, from, to, total }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const query = queryString.parse(location.search);
  const handleChange = (e: any, page: number) => {
    let newQuery = queryString.stringify({
      ...query,
      page: page,
    });
    navigate(`${location.pathname}?${newQuery}`);
  };
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {totalPages > 1 && (
        <StyledPagination
          count={totalPages}
          size="large"
          boundaryCount={matches ? 1 : 3}
          page={Number(query?.page) || 1}
          onChange={handleChange}
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: BiSolidLeftArrow, next: BiSolidRightArrow }}
              {...item}
            />
          )}
        />
      )}
      <Typography
        sx={{
          color: "white",
          fontSize: {
            mobile: "1rem",
            md: "1.5rem",
          },
          textAlign: "center",
          marginTop: "1rem",
          "& span": {
            color: "secondary.main",
            fontWeight: "bold",
            margin: "0 .2rem",
          },
        }}
      >
        {t("pagination.showing")} {t("pagination.from")} <span> {from}</span>
        {t("pagination.to")}
        <span>{to}</span> {t("pagination.of")}
        <span> {total}</span>
      </Typography>
    </Box>
  );
};

export default CustomPagination;
