import React, { useEffect, useState } from "react";
import NavItem from "../NavItem";
import { Stack } from "@mui/material";
import useGetSettings from "../../../../../Hooks/useGetSettings";
import { useDispatch } from "react-redux";
import { mainFilterActions } from "../../../../../store/filter-slice";

type Props = {};

const MainItemsContainer = (props: Props) => {
  const { data, filterCategories } = useGetSettings();
  const [showMenu, setShowMenu] = useState<number>(0);

  const [menus, setMenus] = useState<any>([
    {
      id: 1,
      slug_en: "",
      slug_ar: "",
      name_ar: "القسم العربي",
      name_en: "Arabic Content",
      subMenu: [],
    },
    {
      id: 2,
      slug_en: "#",
      slug_ar: "#",
      name_ar: "القسم الأجنبي",
      name_en: "English content",
      subMenu: [],
    },
    {
      id: 3,
      slug_en: "/offers",
      slug_ar: "/offers",
      name_ar: "العروض",
      name_en: "Offers ",
    },
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        mainFilterActions.setMenus([
          {
            id: 1,
            slug_en: "",
            slug_ar: "",
            name_ar: "القسم العربي",
            name_en: "Arabic Content",
            subMenu: filterCategories(data?.genres[1]?.subs) || [],
          },
          {
            id: 2,
            slug_en: "#",
            slug_ar: "#",
            name_ar: "القسم الأجنبي",
            name_en: "English content",
            subMenu: filterCategories(data?.genres[0]?.subs) || [],
          },
          {
            id: 3,
            slug_en: "/offers",
            slug_ar: "/offers",
            name_ar: "العروض",
            name_en: "Offers ",
          },
        ])
      );
      setMenus([
        {
          id: 1,
          slug_en: "",
          slug_ar: "",
          name_ar: "القسم العربي",
          name_en: "Arabic Content",
          subMenu: filterCategories(data?.genres[1]?.subs) || [],
        },
        {
          id: 2,
          slug_en: "#",
          slug_ar: "#",
          name_ar: "القسم الأجنبي",
          name_en: "English content",
          subMenu: filterCategories(data?.genres[0]?.subs) || [],
        },
        {
          id: 3,
          slug_en: "/offers",
          slug_ar: "/offers",
          name_ar: "العروض",
          name_en: "Offers ",
        },
      ]);
    }
  }, [data]);
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ 
        display: "flex",
        "@media (max-width: 1024px)": {
          display: "none",
        }
      }}
    >
      {menus.map((menu: any) => (
        <NavItem
          key={menu.id}
          {...menu}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      ))}
    </Stack>
  );
};

export default React.memo(MainItemsContainer);
