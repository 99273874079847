import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useGetData from "../../../Hooks/useGetData";
import { CustomTextField } from "../SearchTextField/SearchTextField";

const ResidentialSelect = ({ control, name, area_id }) => {
  const { t, i18n } = useTranslation();
  // Fetch area data from the API using a custom hook
  const { data, loading } = useGetData(
    `address/get/residential/${area_id}`,
    []
  );
  // Define the menus state and update it based on the fetched data and the current language
  const [menus, setMenus] = useState([]);
  useEffect(() => {
    if (data) {
      setMenus([]);
      data?.residential_areas?.map((area) => {
        setMenus((prev) => [
          ...prev,
          {
            label:
              i18n.language === "ar"
                ? area.name_ar || area?.name_en
                : area?.name_en || area?.name_ar,
            id: area.id,
            ship_cost: area.ship_cost,
          },
        ]);
      });
    }
  }, [data, i18n.language]);
  if (!data) return null;
  return (
    <Controller
      name={name}
      control={control}
      // defaultValue={""}
      // defaultValue={disabled && ""}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          disablePortal
          fullWidth
          onChange={(e, newValue) => {
            field.onChange(newValue ? newValue.id : "");
          }}
          isOptionEqualToValue={(option, value) => option === value}
          value={
            menus.find((item) => {
              return item.id === field.value;
            }) || ""
          }
          sx={{
            "& .MuiInput-input": {
              fontSize: "1.5rem",
            },
            "& .MuiSvgIcon-root": {
              color: !!error ? "red" : "white",
            },
          }}
          ListboxProps={{
            sx: {
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#3db9ec",
                borderRadius: "20px",
              },
              "& li": {
                fontSize: "1.5rem",
                fontWeight: "400",
                color: "#755798",
                position: "relative",
              },
            },
          }}
          id="area_id"
          options={menus}
          title="area_id"
          loading={loading}
          getOptionLabel={(option) => (option.label ? option.label : "")}
          renderInput={(params) => (
            <CustomTextField
              helperText={!!error && error.message}
              error={!!error}
              fullWidth
              {...params}
              label={t("profile.addressForm.residential_area")}
              variant="standard"
            />
          )}
        />
      )}
    />
  );
};

export default React.memo(ResidentialSelect);
