import { Box, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import gif from "../../../Assets/Images/flag.webp";
import useGetData from "../../../Hooks/useGetData";

type Props = {
  in_maintenance_sentence_en: string;
  in_maintenance_sentence_ar: string;
  logo: string;
  copyrights_ar: string;
  copyrights_en: string;
};

const Maintenance = ({
  in_maintenance_sentence_ar,
  in_maintenance_sentence_en,
  logo,
  copyrights_ar,
  copyrights_en,
}: Props) => {
  const { i18n } = useTranslation();
  const { data } = useGetData("settings/all", []);
  const updatedLogo = data && data?.settings?.logo;
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: {
          mobile: "1.5rem",
          md: "2.5rem",
          lg: "1rem",
        },
        padding: "2rem 1rem",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: {
            mobile: "0rem",
            md: "1rem",
            lg: "2rem",
          },
          top: {
            mobile: "1rem",
            lg: "2rem",
          },
          width: "25vw",
          minWidth: "120px",
          maxWidth: "180px",
          objectFit: "cover",
          height: "55px",
        }}
        component="img"
        src={updatedLogo}
        alt="logo"
      />
      <Stack spacing={2} alignItems="center">
        <Box
          sx={{
            width: {
              mobile: "250px",
              lg: "400px",
            },
            height: "auto",
            objectFit: "contain",
          }}
          component="img"
          src={gif}
          alt="gif"
        />
        <Typography
          sx={{
            fontSize: {
              mobile: "1.5rem",
              md: "2rem",
              lg: "3rem",
            },
            fontWeight: "bold",
            color: "#81cefc",
          }}
        >
          {i18n.language === "ar"
            ? in_maintenance_sentence_ar
            : in_maintenance_sentence_en}
        </Typography>
      </Stack>

      <Typography
        sx={{
          position: "absolute",
          bottom: "1rem",
          left: "50%",
          transform: "translateX(-50%)",
          color: "#FFAD0A",
          fontSize: {
            mobile: "12px",
            md: "1rem",
          },
        }}
      >
        {i18n.language === "ar" ? copyrights_ar : copyrights_en}
      </Typography>
    </Box>
  );
};

export default Maintenance;
