export const genre_id = [
  {
    id: 1,

    title: {
      ar: "أفلام عربي",
      en: "Arabic Movies",
    },
  },
  {
    id: 2,

    title: {
      ar: "مسلسلات وبرامج عربية",
      en: "Arabic Series",
    },
  },
  {
    id: 3,

    title: {
      ar: "أفلام أجنبي : رعب ,أكشن جريمة",
      en: "English Movies: Horror, Action and Crime",
    },
  },
  {
    id: 4,
    title: {
      ar: "أفلام أجنبي : كوميدي",
      en: "English Movies: Comedy",
    },
  },
  {
    id: 5,
    title: {
      ar: "مسلسلات أجنبية",
      en: "English Series",
    },
  },
  {
    id: 6,
    title: {
      ar: "متفرقات",
      en: "Mix",
    },
  },
];

export const sort_by = [
  {
    id: 12,
    title: {
      ar: "سنة الإنتاج",
      en: "Production Year",
    },
  },
  {
    id: 22,

    title: {
      ar: "التقييم",
      en: "Rank",
    },
  },
  {
    id: 3,

    title: {
      ar: "الأكثر مشاهدة",
      en: "Most Viewed",
    },
  },
];
export const country = [
  {
    id: 1,

    title: {
      ar: "الكويت",
      en: "Kuwait",
    },
  },
  {
    id: 2,

    title: {
      ar: "المملكة العربية السعودية",
      en: "KSA",
    },
  },
  {
    id: 3,

    title: {
      ar: "الإمارات العربية المتحدة",
      en: "United Arab Emirates",
    },
  },
];

export const city = [
  [
    {
      id: 1,
      title: {
        ar: "القاهرة",
      },
    },
    {
      id: 2,
      title: {
        ar: "الجيزة",
      },
    },
    {
      id: 3,
      title: {
        ar: "الإسكندرية",
      },
    },
  ],
  [
    {
      id: 1,
      title: {
        ar: "الكويت",
      },
    },
    {
      id: 2,
      title: {
        ar: "الكويت2",
      },
    },
    {
      id: 3,
      title: {
        ar: "الكويت3",
      },
    },
  ],
  [
    {
      id: 1,
      title: {
        ar: "مكة",
      },
    },
    {
      id: 2,
      title: {
        ar: "المدينة المنورة",
      },
    },
    {
      id: 3,
      title: {
        ar: "الدمام",
      },
    },
  ],
];
