import { Button } from "@mui/material";
import ScaleOnHover from "../../Animation/ScaleOnHover";
import {
  StyledBox,
  StyledImage,
  StyledOverlay,
  StyledTypography,
} from "../../StyledComponents/SmallCard/SmallCard";
import { useNavigate } from "react-router-dom";

type Props = {
  id: number;
  poster: string;
  name: string;
  name_ar: string;
};

const SeriesCard = ({ id, poster, name, name_ar }: Props) => {
  const navigate = useNavigate();
  return (
    <StyledBox
      sx={{
        background: "#E8D6FF",
        "&:hover": {
          ".text": {
            fontWeight: "700",
          },
          ".overlay": {
            opacity: "1",
          },
        },
      }}
    >
      {/* <StyledOverlay className="overlay">
        <Button
          variant="contained"
          color="secondary"
          sx={{
            width: "120px",
            height: "45px",
            color: "white",
          }}
        >
          شراء
        </Button>
      </StyledOverlay> */}
      <StyledImage alt="series" src={poster} />
      <StyledTypography
        className="text"
        onClick={() => navigate(`/media/season/${id}`)}
      >
        {name}
      </StyledTypography>
    </StyledBox>
  );
};

export default SeriesCard;
