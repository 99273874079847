import { Box, Divider, Stack } from "@mui/material";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import StyledText from "../../Components/StyledComponents/StyledText/StyledText";
import useGetData from "../../Hooks/useGetData";
import { useTranslation } from "react-i18next";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import getLocalizedProperty from "../../Helpers/Functions/getLocalizedProperty";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";

type Props = {};

const DMCA = (props: Props) => {
  const { data, loading, error } = useGetData("footer/dmca", []);
  const { i18n } = useTranslation();
  if (error) return <ErrorComponent />;
  if (loading) return <MainLoader />;
  let pageData = data?.dmca[0];
  return (
    <>
      {data && (
        <MainContainer>
          {" "}
          <Box
            sx={{
              padding: "300px 0 0 0",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "40px",
                md: "75px",
              },
            }}
          >
            <SectionHead
              color="#CEAFF5"
              title={getLocalizedProperty("title", pageData, i18n.language)}
              subTitle={getLocalizedProperty(
                "subtitle",
                pageData,
                i18n.language
              )}
            />
            <Stack
              spacing={6}
              divider={
                <Divider
                  sx={{
                    width: "60%",
                    opacity: "0.18",
                    backgroundColor: "#fff",
                  }}
                />
              }
            >
              <Stack spacing={3}>
                <StyledText
                  dangerouslySetInnerHTML={{
                    __html: getLocalizedProperty(
                      "body",
                      pageData,
                      i18n.language
                    ),
                  }}
                />
              </Stack>
              {/* <Stack spacing={3}>
                <Stack>
                  <StyledText
                    sx={{
                      color: "#fff",
                    }}
                  >
                    {getLocalizedProperty(
                      "subsection_title",
                      pageData,
                      i18n.language
                    )}
                  </StyledText>
                  <StyledText>
                    {"-"}{" "}
                    {getLocalizedProperty(
                      "subsection_note",
                      pageData,
                      i18n.language
                    )}
                  </StyledText>
                  <StyledText>
                    {"-"}{" "}
                    {getLocalizedProperty(
                      "subsection_note2",
                      pageData,
                      i18n.language
                    )}
                  </StyledText>
                  <StyledText>
                    {"-"}{" "}
                    {getLocalizedProperty(
                      "subsection_note3",
                      pageData,
                      i18n.language
                    )}
                  </StyledText>
                  <StyledText>
                    {"-"}{" "}
                    {getLocalizedProperty(
                      "subsection_note4",
                      pageData,
                      i18n.language
                    )}
                  </StyledText>
                  <StyledText>
                    {"-"}{" "}
                    {getLocalizedProperty(
                      "subsection_note5",
                      pageData,
                      i18n.language
                    )}
                  </StyledText>
                  <StyledText>
                    {"-"}{" "}
                    {getLocalizedProperty(
                      "subsection_note6",
                      pageData,
                      i18n.language
                    )}
                  </StyledText>
                </Stack>
                <StyledText
                  sx={{
                    color: "#fff",
                  }}
                >
                  {getLocalizedProperty(
                    "subsection_footer",
                    pageData,
                    i18n.language
                  )}
                </StyledText>
              </Stack> */}
            </Stack>
          </Box>
        </MainContainer>
      )}
      <RequestBlock />
    </>
  );
};

export default DMCA;
