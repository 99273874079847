import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";

type Props = {};

const ProtectedRoutes = (props: Props) => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return token ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoutes;
