import { createData } from "../../Services/DataServices";
import { displayToastSuccess } from "../toastHelper";

export const handleRegister = async (data, setError, navigate, setLoading) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  try {
    setLoading(true);
    await createData("user/register", formData).then((res) => {
      if (res.status === 200) {
        displayToastSuccess(res?.data?.message);
        navigate("/login");
      }
    });
  } catch (error) {
    console.log(error?.response?.data?.errors);
    //loop over the errors object and set the errors
    for (let key in error?.response?.data?.errors) {
      setError(key, {
        message: error?.response?.data?.errors[key][0],
      });
    }
  } finally {
    setLoading(false);
  }
};
