import { createSlice } from "@reduxjs/toolkit";
import { category } from "../Types/Types";

interface initialStateType {
  arabicFilters: category[];
  englishFilters: category[];
  menus: any;
}

const initialState: initialStateType = {
  arabicFilters: [],
  englishFilters: [],
  menus: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setArabicFilters(state, action) {
      state.arabicFilters = action.payload;
    },
    setEnglishFilters(state, action) {
      state.englishFilters = action.payload;
    },
    setMenus(state, action) {
      state.menus = action.payload;
    },
  },
});

export const mainFilterActions = filterSlice.actions;
export default filterSlice;
