import React from "react";
import AuthInfo from "../AuthInfo";
import AuthTitle from "../AuthTitle";
import "./index.scss";

const AuthLayout = ({ children, authTitle, authInfo, register }) => {
  return (
    <div className="authLayout grid">
      <div className="form">
        <AuthTitle title={authTitle} />
        {children}
      </div>
      <AuthInfo {...authInfo} register={register} children={children} />
      {/* <div className="small-screen">{children}</div> */}
    </div>
  );
};

export default AuthLayout;
