import { Box, styled } from "@mui/material";

const StyledSliderContainer = styled(Box)({
  padding: "160px 0 0 0",
  display: "flex",
  flexDirection: "column",
  backgroundImage:
    " linear-gradient(180deg, #278CC900 0%, #664988 0%, #3B215A 45%, #291739B3 71%, #2C203057 100%)",
  "@media(max-width:1650px)": {
    padding: "100px 0 0 0",
  },
  "@media(max-width:1440px)": {
    padding: "80px 0 0 0",
  },
  "@media(max-width:1280px)": {
    padding: "60px 0 0 0",
  },
  "@media(max-width:768px)": {
    padding: "40px 0 0 0",
  },
});

export default StyledSliderContainer;
