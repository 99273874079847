import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, Container, Divider, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaFacebookF,
  FaInstagram,
  FaSkype,
  FaSnapchatGhost,
  FaTelegramPlane,
  FaTiktok,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

import { Link } from "react-router-dom";
import FooterBg from "../../../../Assets/Images/footer-bg.svg";
import Logo from "../../../../Assets/Images/main-logo.png";
import useGetData from "../../../../Hooks/useGetData";
import FollowUs from "../FollowUs/FollowUs";
import "./FooterInfo.scss";
import { BsTwitterX } from "react-icons/bs";

type SocialItem = {
  link: any;
  icon: ReactJSXElement;
};

const socialItemsIcons: SocialItem[] = [
  {
    link: "",
    icon: <FaSnapchatGhost />,
  },
  {
    link: "",
    icon: <FaInstagram />,
  },
  {
    link: "",
    icon: <FaWhatsapp />,
  },
  {
    link: "",
    icon: <FaTiktok />,
  },
  {
    link: "",
    icon: <FaTelegramPlane />,
  },
  {
    link: "",
    icon: <FaFacebookF />,
  },
  {
    link: "",
    icon: <BsTwitterX />,
  },
];
type Props = {
  logo?: string,
  rights?: string
}
const FooterInfo = ({logo, rights}: Props) => {
  const { t } = useTranslation();

  const { data } = useGetData("footer/social_link", []);

  const [socialItems, setSocialItems] =
    useState<SocialItem[]>(socialItemsIcons);
  
  useEffect(() => {
    if (data) {
      setSocialItems([
        {
          link: data?.links?.facebook,
          icon: <FaFacebookF />,
        },
        {
          link: data?.links?.instagram,
          icon: <FaInstagram />,
        },
        {
          link: data?.links?.snapchat,
          icon: <FaSnapchatGhost />,
        },
        {
          link: data?.links?.telegram,
          icon: <FaTelegramPlane />,
        },
        {
          link: data?.links?.tiktok,
          icon: <FaTiktok />,
        },
        {
          link: data?.links?.twitter,
          icon: <BsTwitterX />,
        },
        {
          link: data?.links?.whatsapp,
          icon: <FaWhatsapp />,
        },
        {
          link: data?.links?.youtube,
          icon: <FaYoutube />,
        },
        {
          link: data?.links?.skype,
          icon: <FaSkype />,
        },
      ]);
    }
  }, [data]);

  return (
    <section
      className="footer-info"
      style={{ backgroundImage: `url(${FooterBg})` }}
    >
      <Container maxWidth="lg">
        <div className="footer-container">
          <div className="footer-logo">
            <img src={logo ? logo : Logo} alt="site-logo" />
          </div>
          <div className="footer-nav">
            <Stack
              divider={
                <Divider
                  orientation="vertical"
                  sx={{
                    borderColor: "secondary.main",
                    height: "25px",
                  }}
                />
              }
              direction="row"
              component="ul"
              alignItems="center"
            >
              <li>
                <Link to="/about-us">{t("footer.aboutUs")}</Link>
              </li>
              <li>
                <Link to="/contact-us">{t("footer.contactUs")}</Link>
              </li>
              <li>
                <Link to="/policy">{t("footer.shippingPolicy")}</Link>
              </li>
              <li>
                <Link to="privacy">{t("footer.privacy")}</Link>
              </li>
              <li>
                <Link to="faq">{t("footer.faq")}</Link>
              </li>
              <li>
                <Link to="dmca">{t("footer.dmca")}</Link>
              </li>
            </Stack>
          </div>

          <FollowUs />
          <div className="footer_sub__info">
            <div className="socials">
              <ul>
                {socialItems &&
                  socialItems?.map((item, index) => {
                    return (
                      item?.link && (
                        <SocialContainer
                          key={index}
                          link={item.link}
                          icon={item.icon}
                        />
                      )
                    );
                  })}
              </ul>
            </div>
            <div className="copyrights">
              <p>
                {rights}
                {/* {t("footer.rights")}
                <span>2023</span> */}
              </p>
              <p>
                {t("footer.founded")} <span>2008</span>
              </p>
              <p>
                <span>{t("footer.developed")}</span>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FooterInfo;

const SocialContainer = ({
  link,
  icon,
}: {
  link: string;
  icon: ReactJSXElement;
}) => {
  return (
    <motion.li
      whileHover={{
        //scale it
        scale: 1.2,
      }}
    >
      <Box
        component={"a"}
        sx={{
          "& svg": {
            fontSize: "1.8rem",
          },
        }}
        href={link}
        target="__blank"
      >
        {icon}
      </Box>
    </motion.li>
  );
};
