import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { category } from "../../Types/Types";
import FilterItem from "../Blocks/FilterItem/FilterItem";
import RadioButton from "../Blocks/RadioButton/RadioButton";
import StyledButton, {
  StyledSecondaryButton,
} from "../StyledComponents/StyledButton/StyledButton";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { refreshApiActions } from "../../store/refresh-slice";
import queryString from "query-string";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "95vh",
  bgcolor: "rgba(148, 166, 210,0.5)",
  boxShadow: "0 3px 40px #000000AB",
  borderRadius: "30px",
  backdropFilter: "blur(39px)",
  webkitBackdropFilter: "blur(39px)",
  padding: "50px 100px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "3rem",
  "@media (max-width: 1440px)": {
    width: "80vw",
    padding: "40px 80px",
  },
  "@media (max-width: 1024px)": {
    width: "90vw",
  },
  "@media (max-width: 768px)": {
    padding: "35px 50px",
    gap: "2rem",
    width: "95vw",
  },
};

interface Props {
  color: string;
  categoryEnum?: number;
}

function FilterModal({ color, categoryEnum }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { i18n } = useTranslation();
  const [filtersType, setFiltersType] = useState<number>(1);
  const [selectedFilters, setSelectedFilters] = useState<number[]>([]);
  const filterState = useSelector((state: any) => state.filter);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);

  const handleSubmitFilter = () => {
    if (selectedFilters.length > 0) {
      navigate(
        `home/media/${categoryEnum}?enum=${categoryEnum}&genre_ids=${selectedFilters.join(
          ","
        )}&year=${parsedQuery.year || ""}&page=${parsedQuery.page || 1}`
      );
      setSelectedFilters([]);
      dispatch(refreshApiActions.refreshApi());
      handleClose();
    } else {
      toast.error(t("filter.error"));
    }
  };

  return (
    <>
      <StyledSecondaryButton
        variant="contained"
        sx={{
          color: color,
          backgroundColor: "#fff",
          "&:hover": {
            backgroundColor: "#e7e5e5",
          },
        }}
        disableElevation
        onClick={handleOpen}
      >
        {t("filter.filter")}
      </StyledSecondaryButton>{" "}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box sx={style}>
          <Stack justifyContent="center" alignItems="center">
            <Typography
              sx={{
                fontSize: {
                  mobile: "30px",
                  sm: "40px",
                  md: "50px",
                },
                color: "white",
                fontWeight: "500",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {t("filter.title")}
            </Typography>
            <Typography
              sx={{
                color: "#3B215A",
                fontSize: {
                  mobile: "16px",
                  sm: "18px",
                  md: "20px",
                  lg: "30px",
                },
                fontWeight: "400",
              }}
              id="modal-modal-description"
            >
              {t("filter.subTitle")}{" "}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{
              width: "100%",
              paddingBottom: "1rem",
              borderBottom: "4px solid #ffffff4f",
            }}
          >
            <Typography
              sx={{
                color: "#BAE4FE",
                fontSize: {
                  mobile: "20px",
                  sm: "24px",
                  md: "30px",
                },
                fontWeight: "400",
              }}
            >
              {t("filter.type")}
            </Typography>
            <RadioButton
              setFilterType={setFiltersType}
              filterType={filtersType}
            />
          </Stack>
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{
              gap: "1rem",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "4px",
                background: "#3EA9E92c",
              },
              "&::-webkit-scrollbar-thumb": {
                width: "4px",
                background: "#3EA9E9",
                borderRadius: "10px",
              },
              overflowY: {
                mobile: "scroll",
              },
              justifyContent: {
                mobile: "center",
              },
            }}
          >
            {filtersType === 1
              ? filterState.arabicFilters.map(
                  (filter: category, index: number) => (
                    <FilterItem
                      id={filter.id}
                      title={
                        i18n.language === "ar" ? filter.name_ar : filter.name_en
                      }
                      key={index}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                    />
                  )
                )
              : filterState.englishFilters.map(
                  (filter: category, index: number) => (
                    <FilterItem
                      id={filter.id}
                      title={
                        i18n.language === "ar" ? filter.name_ar : filter.name_en
                      }
                      key={index}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                    />
                  )
                )}
          </Stack>

          <StyledButton
            variant="contained"
            color="secondary"
            disableElevation
            sx={{
              marginTop: {
                mobile: "1rem",
                sm: "2rem",
                md: "3rem",
              },
              backgroundColor: "#3EA9E9",
              fontSize: {
                mobile: "20px",
                sm: "24px",
              },
              color: "white",
            }}
            onClick={handleSubmitFilter}
          >
            {t("filter.filter")}
          </StyledButton>
        </Box>
      </Modal>
    </>
  );
}

export default React.memo(FilterModal);
