import { Typography } from "@mui/material";
import { useState } from "react";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../../../Helpers/AuthFunctions/LogoutHelpers";
import { authActions } from "../../../../store/auth-slice";
import { StyledIcon } from "../../../StyledComponents/ControlsIcon/StyledIcon";
import MenuContainer from "../../../StyledComponents/MenuContainer/StyledMenuContainer";
import { displayToastSuccess } from "../../../../Helpers/toastHelper";
import { useTranslation } from "react-i18next";

type Props = {};
type Menu = {
  id: number;
  slug_en: string;
  slug_ar: string;
  title: {
    ar: string;
    en: string;
  };
  action: (e: any) => void;
};

const Profile = ({}: Props) => {
  const { i18n } = useTranslation();

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const authState = useSelector((state: any) => state.auth);
  const navigate = useNavigate();

  const handleCloseMenu = () => {
    setShowMenu(false);
  };
  const handleMouseEnter = () => {
    if (authState.isAuthenticated) {
      setShowMenu(true);
    }
  };
  const handleClick = () => {
    if (authState.isAuthenticated) {
      setShowMenu((prevState) => !prevState);
    } else {
      navigate("/login");
    }
  };

  const dispatch = useDispatch();

  const menu: Menu[] = [
    {
      id: 1,
      slug_en: "/profile",
      slug_ar: "/profile",
      title: {
        ar: "الملف الشخصي",
        en: "Profile",
      },
      action: (e: any) => {
        navigate("/profile");
      },
    },
    {
      id: 2,
      slug_en: "/purchases",
      slug_ar: "/purchases",
      title: {
        ar: "مشترياتي",
        en: "Orders",
      },
      action: (e: any) => {
        navigate("/purchases");
      },
    },
    {
      id: 3,
      slug_en: "/logout",
      slug_ar: "/logout",
      title: {
        ar: "تسجيل الخروج",
        en: "LogOut",
      },
      action: (e: any) => {
        handleLogout().finally(() => {
          dispatch(authActions.logout());
          navigate("/");
          displayToastSuccess("تم تسجيل الخروج بنجاح");
        });
      },
    },
  ];
  return (
    <StyledIcon
      sx={{
        backgroundColor: "primary.main",
        "& svg": {
          color: "white",
        },
        "&:hover": {
          backgroundColor: "primary.main",
        },
      }}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
      onMouseLeave={handleCloseMenu}
    >
      <FaUser />
      {showMenu && (
        <MenuContainer className="menu">
          {menu.map((item: Menu, index: number) => {
            return (
              <MenuItem
                key={index}
                title={i18n.language === "en" ? item.title.en : item.title.ar}
                action={item.action}
              />
            );
          })}
        </MenuContainer>
      )}
    </StyledIcon>
  );
};

export default Profile;

type MenuItemProps = {
  title: string;
  action: (e: any) => void;
};

export const MenuItem = ({ title, action }: MenuItemProps) => {
  return (
    <Typography
      sx={{
        transition: "all 0.1s ease-in",
        padding: "2px 12px",
        whiteSpace: "nowrap",
        "&:hover": {
          backgroundColor: "#8768ac30",
        },
        zIndex: 100,
      }}
      onClick={(e) => {
        action(e);
      }}
    >
      {title}
    </Typography>
  );
};
