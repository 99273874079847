import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import line from "../../../Assets/Images/line.svg";
import OrderStatus from "./OrderStatus";
interface props {
  orders: any[];
}
const CollapsibleTable = ({ orders }: props) => {
  const { t, i18n } = useTranslation();
  const localizePath = "purchases.table.";
  const tableHeadStyle = {
    fontSize: { xs: "14px", sm: "16px", md: "20px", lg: "24px" },
    color: "#ECECEC",
    border: 0,
    p: 0,
  };
  const tableHeadFirstIndexStyle = {
    ...tableHeadStyle,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  };
  const tableHeadLastIndexStyle = {
    ...tableHeadStyle,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  };

  const tableHeadRow = [
    {
      title: t(`${localizePath}order-no`),
      style: tableHeadFirstIndexStyle,
      hasDivider: true,
    },
    {
      title: t(`${localizePath}order-date`),
      style: tableHeadStyle,
      hasDivider: true,
    },
    // {
    //   title: t(`${localizePath}products`),
    //   style: tableHeadStyle,
    //   hasDivider: true,
    // },
    {
      title: t(`${localizePath}total-price`),
      style: tableHeadStyle,
      hasDivider: true,
    },
    {
      title: t(`${localizePath}order-delivery-address`),
      style: tableHeadStyle,
      hasDivider: true,
    },
    {
      title: t(`${localizePath}order-status`),
      style: tableHeadStyle,
      hasDivider: true,
    },
    {
      title: t(`${localizePath}details`),
      style: tableHeadLastIndexStyle,
      hasDivider: false,
    },
  ];
  // const products = [
  //   { name: "Test1", price: 1 },
  //   { name: "test2", price: 2 },
  //   { name: "test3", price: 3 },
  // ];

  const navigate = useNavigate();

  //get user currency
  const cookies = new Cookies();
  const currency = cookies.get("currency") || "KWD";
  return (
    <Box
      component={motion.div}
      initial={{
        scale: 0,
      }}
      animate={{
        scale: 1,
      }}
      transition={{
        duration: 0.5,
      }}
    >
      <TableContainer>
        <Table aria-label="collapsible table" sx={{ minWidth: 900 }}>
          <TableHead
            sx={{
              borderRadius: "14px",
              bgcolor: "#CEAFF542",
            }}
          >
            <TableRow sx={{ height: "78px" }}>
              {tableHeadRow.map((item, index) => (
                <React.Fragment key={index}>
                  <TableCell sx={item.style}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {item.title}
                      </Typography>
                      {item.hasDivider && (
                        <Box component={"img"} src={line} alt="" />
                      )}
                    </Box>
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order: any, index: number) => (
              <TableRow
                key={order.id}
                sx={{
                  height: { xs: "60px", sm: "70px", md: "90px", lg: "112.5px" },
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    borderColor: "rgba(232, 214, 255, .3)",
                    color: "#E69D0B",
                    fontSize: { xs: 12, sm: 14, md: 16, lg: 20 },
                    width: "20%",
                  }}
                >
                  {order?.number}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderColor: "rgba(232, 214, 255, .3)",
                    color: "#CEAFF5",
                    fontSize: { xs: 10, sm: 12, md: 14, lg: 18 },
                    width: "15%",
                  }}
                >
                  {format(new Date(order?.created_at), "dd/MM/yyyy")}
                </TableCell>
                {/* <TableCell
                  align="center"
                  sx={{
                    borderColor: "rgba(232, 214, 255, .3)",
                  }}
                >
                  <OrderProduct products={products} />
                </TableCell> */}
                <TableCell
                  align="center"
                  sx={{
                    borderColor: "rgba(232, 214, 255, .3)",
                    color: "#E69D0B",
                    fontSize: { xs: 12, sm: 14, md: 16, lg: 20 },
                    width: "15%",
                  }}
                >
                  {Number(order?.total).toLocaleString()} {order?.currency}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderColor: "rgba(232, 214, 255, .3)",
                    color: "#CEAFF5",
                    fontSize: { xs: 10, sm: 12, md: 14, lg: 18 },
                    width: "25%",
                  }}
                >
                  {`${order?.address}`}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ borderColor: "rgba(232, 214, 255, .3)", width: "15%" }}
                >
                  <OrderStatus
                    color={order?.status_color}
                    statusText={
                      i18n.language === "ar"
                        ? order?.status_ar || order?.status
                        : order?.status || order?.status_ar
                    }
                  />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    borderColor: "rgba(232, 214, 255, .3)",
                    color: "#CEAFF5",
                    fontSize: { xs: 10, sm: 12, md: 14, lg: 18 },
                    width: "10%",
                  }}
                >
                  <IconButton
                    sx={{
                      "& svg": {
                        color: "#E69D0B",
                      },
                    }}
                    onClick={() => {
                      navigate(`/purchases/${order?.id}`);
                    }}
                  >
                    <AiOutlineEye />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default CollapsibleTable;
