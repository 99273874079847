import { Box, Collapse, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import AreaSelect from "../AreaSelect/AreaSelect";
import CountrySelect from "../AreaSelect/CountrySelect";
import ResidentialSelect from "../AreaSelect/ResidentialSelect";
import SearchTextField from "../SearchTextField/SearchTextField";
import React from "react";

const AddressFields = ({
  control,
  setCountryId,
  countryId,
  areaId,
  setAreaId,
}: any) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <CountrySelect
        control={control}
        name={"country_id"}
        setCountryId={setCountryId}
      />
      {/* show area if countryId = 1 */}
      <Collapse in={countryId === 1}>
        <AreaSelect control={control} name={"area_id"} setAreaId={setAreaId} />
      </Collapse>

      <Collapse in={countryId === 1}>
        <>
          <Collapse in={areaId}>
            <ResidentialSelect
              control={control}
              name={"residential_area_id"}
              area_id={areaId}
            />
          </Collapse>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2,1fr)",
              rowGap: "16px",
              columnGap: "16px",
            }}
          >
            {/* القطعه */}
            <SearchTextField
              label={t("profile.addressForm.block")}
              name="block"
              control={control}
            />
            <SearchTextField
              label={t("profile.addressForm.street")}
              name="street"
              control={control}
            />
            {/* الجاده */}
            <SearchTextField
              label={t("profile.addressForm.avenue")}
              name="avenue"
              control={control}
            />

            <SearchTextField
              label={t("profile.addressForm.building")}
              name="building_no"
              control={control}
            />
            <SearchTextField
              label={t("profile.addressForm.floor")}
              name="floor"
              control={control}
            />

            <SearchTextField
              label={t("profile.addressForm.apartment")}
              name="apartment"
              control={control}
            />
          </Box>
        </>
      </Collapse>
      <Box sx={{ marginTop: "1rem" }}>
        <SearchTextField
          label={t("profile.addressForm.detailed")}
          name="detailed_address"
          control={control}
        />
      </Box>
    </Stack>
  );
};

export default React.memo(AddressFields);
