import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { AiOutlineDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const ProductionYearSelect = () => {
  // handle language
  const { t } = useTranslation();
  const maxDate = dayjs(new Date());
  const navigate = useNavigate();
  const query = queryString.parse(window.location.search);
  const handleYearChange = (date: any) => {
    navigate(`?enum=6&genre_ids=${query?.genre_ids || ""}&year=${date}`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={""}
        openTo="year"
        disableFuture
        views={["year"]}
        maxDate={maxDate}
        value={
          query.year
            ? dayjs().year(Number(query.year))
            : dayjs().year(Number(dayjs().year()))
        }
        onAccept={(e) => {
          handleYearChange(e!.year());
        }}
        slotProps={{
          textField: {
            onKeyDown: (e) => {
              e.preventDefault();
            },
            placeholder: t("homePage.production-year"),
            inputProps: {
              style: {},
            },
            sx: {
              "& .MuiInputBase-root": {
                color: "#81CEFC",
                width: "200px",
                border: "none",
                flexDirection: "row-reverse",
                alignItems: "baseline",
                userSelect: "none",
              },
              "& .MuiInputBase-input": {
                color: "#81CEFC",
                content: "''",
                fontSize: "45px",
                cursor: "default",
              },

              "& .MuiIconButton-root": {
                color: "#81CEFC",
                padding: "0",
                marginLeft: "0",
                fontSize: "16px",
                transform: "translateY(5px)",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiInputLabel-root": {
                color: "#81CEFC",
              },
            },
          },
          layout: {
            // sx: {
            //   md: mainStyles,
            // },
          },
        }}
        slots={{
          openPickerIcon: AiOutlineDown,
        }}
        sx={{
          "& .MuiYearCalendar-root": {
            "& ::webkit-scrollbar": {
              width: "0px",
              background: "transparent",
            },
            "& ::webkit-scrollbar-thumb": {
              background: "transparent",
            },
            "& ::-webkit-scrollbar-track": {
              background: "transparent",
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default ProductionYearSelect;
