import { CircularProgress, Stack } from "@mui/material";
import StyledButton from "../../StyledComponents/StyledButton/StyledButton";
import MainTextField from "../MainTextField/MainTextField";
import { createData } from "../../../Services/DataServices";
import {
  displayToastErrorApi,
  displayToastSuccess,
} from "../../../Helpers/toastHelper";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {};

const RequestForm = (props: Props) => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();
  //form logic

  const handleRequestForm = async (data: any) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    try {
      setSubmitting(true);
      await createData("special_request/create", formData).then((res) => {
        if (res?.status === 200) {
          navigate("/");
          displayToastSuccess(res?.data?.message);
        } else {
          displayToastErrorApi(res?.data?.message);
        }
      });
    } catch (error: any) {
      displayToastErrorApi(error?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required(t("requestForm.validation.name")),
    email: yup
      .string()
      .required(t("requestForm.validation.email"))
      .email(t("requestForm.validation.email")),
    phone: yup
      .string()
      .required(t("requestForm.validation.phone"))
      .min(10, t("requestForm.validation.phone")),
    request: yup
      .string()
      .required(t("requestForm.validation.request"))
      .min(10, t("requestForm.validation.request")),
  });
  const {
    handleSubmit,
    register,
    setError,
    control,
    reset,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const inputs = [
    {
      name: "name",
      label: t("requestForm.name"),
    },
    {
      name: "email",
      label: t("requestForm.email"),
    },
    {
      name: "phone",
      label: t("requestForm.phone"),
    },
    {
      name: "request",
      label: t("requestForm.request"),
      multiline: true,
    },
  ];
  return (
    <Stack
      component="form"
      spacing={2}
      sx={{
        "& > :last-child": {
          marginTop: "4rem",
        },
      }}
    >
      {inputs.map((input, index) => (
        <MainTextField
          background={"#81cefc33"}
          key={index}
          {...input}
          control={control}
        />
      ))}
      <StyledButton
        type="submit"
        sx={{
          alignSelf: "flex-end",
        }}
        variant="contained"
        color="secondary"
        disableElevation
        onClick={handleSubmit(handleRequestForm)}
        disabled={submitting}
        endIcon={submitting && <CircularProgress size={20} />}
      >
        {t("requestForm.send")}
      </StyledButton>
    </Stack>
  );
};

export default RequestForm;
