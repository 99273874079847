//scroll to top on route change or api call

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const useScrollToTop = (dependency: any[]) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [...dependency, i18n.language, location]);
};
