import { styled } from "@mui/material";
import { movie } from "../../../Types/Types";
import MainCard from "../Card/MainCard";
const GridContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(5,1fr)",
  columnGap: "1rem",
  rowGap: ".5rem",
  "@media (max-width: 1650px)": {
    gridTemplateColumns: "repeat(4,1fr)",
  },
  "@media (max-width: 1440px)": {
    gridTemplateColumns: "repeat(4,1fr)",
    rowGap: "0rem",
  },
  "@media (max-width: 1280px)": {
    gridTemplateColumns: "repeat(4,1fr)",
    // rowGap: "1rem",
  },
  "@media (max-width: 1024px)": {
    gridTemplateColumns: "repeat(3,1fr)",
    justifyItems: "center",
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(2,1fr)",
    justifyItems: "center",
  },
  "@media (max-width: 520px)": {
    gridTemplateColumns: "1fr",
    justifyItems: "center",

    // rowGap: ".5rem",
  },
});
type Props = {
  cardsList: movie[];
};

const MediaListGrid = ({ cardsList }: Props) => {
  return (
    <GridContainer sx={{}}>
      {cardsList.map((card, index: number) => {
        return <MainCard {...card} key={index} />;
      })}
    </GridContainer>
  );
};

export default MediaListGrid;
