import Box from "@mui/material/Box";
import * as React from "react";
import { useTranslation } from "react-i18next";
import useGetData from "../../../Hooks/useGetData";
import StoragesModal from "../../Modals/StoragesModal";
import { StyledSecondaryButton } from "../../StyledComponents/StyledButton/StyledButton";

export default function SelectStorage() {
  const [showMenu, setShowMenu] = React.useState(false);

  // Handle Language
  const { t } = useTranslation();

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const { data, loading, error } = useGetData("storage/list", []);

  return (
    <Box
      sx={{
        position: "relative",
        width: {
          mobile: "100%",
          md: "fit-content",
        },
        display: "flex",
        justifyContent: {
          mobile: "center",
          md: "flex-start",
        },
        gap: "16px",
      }}
    >
      <StyledSecondaryButton
        onClick={() => {
          setShowMenu(!showMenu);
        }}
        variant="outlined"
        sx={{
          color: "#755798",
          textTransform: "none",
          fontSize: {
            xs: "16px",
          },
          borderRadius: "12px",
          height: {
            mobile: "40px",
            md: "50px",
          },
          width: {
            mobile: "280px",
            // md: "250px",
          },
          "&:hover": {
            backgroundColor: "#E8D6FF",
          },
          maxWidth: "unset",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 576px)": {
            // maxWidth: ""
          }
        }}
      >
        {t("purchases.payment.chooseStorage")}
      </StyledSecondaryButton>

      {data && (
        <StoragesModal
          open={showMenu}
          handleClose={handleCloseMenu}
          storageItemsFromApi={data?.storages}
        />
      )}
    </Box>
  );
}
