import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import MainTabs from "../../Components/Blocks/SingleOrderComponents/MainTabs";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import useGetData from "../../Hooks/useGetData";

const SinglePurchasePage = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, error, data } = useGetData(`order/list-details/${id}`, []);
  // console.log('data',data);

  // //tabs state
  // let initialTabs: tabsType = [
  //   {
  //     label: t("purchases.tabs.download"),
  //     is_empty: false,
  //     LIVE: {
  //       movie: [],
  //       seasons: [],
  //       series: [],
  //     },
  //     DOWNLOAD: {
  //       movie: [],
  //       seasons: [],
  //       series: [],
  //     },
  //     DVD: {
  //       movie: [],
  //       seasons: [],
  //       series: [],
  //     },
  //     STORAGE: {
  //       movie: [],
  //       seasons: [],
  //       series: [],
  //     },
  //   },
  //   {
  //     label: t("purchases.tabs.online"),
  //     is_empty: false,
  //     movie: [],
  //     seasons: [],
  //     series: [],
  //   },
  //   {
  //     label: t("purchases.tabs.dvd"),
  //     is_empty: false,
  //     movie: [],
  //     seasons: [],
  //     series: [],
  //   },
  //   {
  //     label: t("purchases.tabs.storage"),
  //     is_empty: false,
  //     movie: [],
  //     seasons: [],
  //     series: [],
  //   },
  // ];
  // const [tabs, setTabs] = useState<tabType[]>(initialTabs);
  // useEffect(() => {
  //   if (data) {
  //     let newTabs = [...tabs];

  //     setTabs(newTabs);
  //   }
  // }, [data]);

  //end of snippet

  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  return (
    <Box component={"section"}>
      <ParticlesBackGround />
      <MainContainer>
        <PageContentContainer>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <MainTabs /> */}
            <MainTabs tabs={data && data?.order_details} />
          </Box>
        </PageContentContainer>
        <RequestBlock />
      </MainContainer>
    </Box>
  );
};

export default SinglePurchasePage;
