import { Box, Button, CircularProgress, Stack } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import queryString from "query-string";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import FormMenu from "../FormMenu/FormMenu";
import SearchTextField from "../SearchTextField/SearchTextField";
interface FormInput {
  keyword: string;
  category: string;
  director: string;
  actor: string;
  sort: string;
  language: string;
  productionYear: Date;
  productionYearEnd: Date;
  productionYearStart: Date;
  title: string;
}
const AdvancedSearchForm = () => {
  const { t } = useTranslation();
  const [submitting] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleNavigateToResults = (data: any) => {
    const newQuery = queryString.stringify({
      ...data,
    });
    navigate(`/search-results?${newQuery}`);
  };

  const handleAdvancedSearch = (data: any) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    handleNavigateToResults(data);
  };
  const { handleSubmit, control, register } = useForm<FormInput>();
  // const { data } = useGetData("get_languages", []);
  // console.log(data?.data);
  return (
    <>
      <Stack
        component={"form"}
        spacing={{
          mobile: 2,
          sm: 4,
        }}
        sx={{
          "& > :last-child": {
            marginTop: "4rem",
          },
          padding: {
            mobile: "50px 0",
            sm: "0",
          },
        }}
      >
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          spacing={2}
        >
          <TextField
            autoComplete="off"
            placeholder={t("advancedSearchPage.form.title")}
            id="input-with-icon-textfield"
            fullWidth
            sx={{
              color: "white",
              backgroundColor: "#CEAFF542",
              borderRadius: "12px",
              "& .MuiOutlinedInput-root": {
                background: "#81cefc38",
                color: "white",
                borderRadius: "12px",
                "&:hover": {
                  borderColor: "#CEAFF542",
                },
              },
              "& .MuiInputBase-input": {
                padding: "20px 14px",
              },
              "& .MuiInputLabel-root": {
                color: "white",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "white",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch color="#FFFFFF" />
                </InputAdornment>
              ),
            }}
            {...register("title")}
          />
          <TextField
            autoComplete="off"
            placeholder={t("advancedSearchPage.form.keyword")}
            id="input-with-icon-textfield"
            fullWidth
            sx={{
              color: "white",
              backgroundColor: "#CEAFF542",
              borderRadius: "12px",
              "& .MuiOutlinedInput-root": {
                background: "#81cefc38",
                color: "white",
                borderRadius: "12px",
                "&:hover": {
                  borderColor: "#CEAFF542",
                },
              },
              "& .MuiInputBase-input": {
                padding: "20px 14px",
              },
              "& .MuiInputLabel-root": {
                color: "white",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "white",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch color="#FFFFFF" />
                </InputAdornment>
              ),
            }}
            {...register("keyword")}
          />
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: { mobile: "column", sm: "row" },
            gap: "16px",
            flexWrap: { xs: "wrap", sm: "nowrap" },
            alignItems: {
              mobile: "unset",
              md: "flex-end",
            },
          }}
        >
          <Box sx={{ display: "flex", flexBasis: "40%" }}>
            <FormMenu
              label={t("advancedSearchPage.form.category")}
              menuType="genre_id"
              name="genre_id"
              control={control}
            />
          </Box>

          <SearchTextField
            label={t("advancedSearchPage.form.director")}
            name="directors"
            control={control}
          />
          <SearchTextField
            label={t("advancedSearchPage.form.actor")}
            name="casts"
            control={control}
          />
          <SearchTextField
            label={t("advancedSearchPage.form.language")}
            name="language"
            control={control}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { mobile: "column", sm: "row" },
            gap: "16px",
            flexWrap: { xs: "wrap", sm: "nowrap" },
            alignItems: {
              mobile: "unset",
              md: "flex-end",
            },
          }}
        >
          <Box sx={{ display: "flex", flexBasis: "40%" }}>
            <FormMenu
              label={t("advancedSearchPage.form.sort")}
              menuType="sort_by"
              name="sort_by"
              control={control}
            />
          </Box>
          <SearchTextField
            label={t("advancedSearchPage.form.productionYear")}
            name="year"
            control={control}
          />

          <SearchTextField
            label={t("advancedSearchPage.form.from")}
            name="from_year"
            control={control}
          />
          <SearchTextField
            label={t("advancedSearchPage.form.to")}
            name="to_year"
            control={control}
          />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            width: "100%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              marginTop: {
                mobile: "20px",
                sm: "32px",
              },
              color: "#755798",
              backgroundColor: "#F3F3F3",
              borderRadius: "8px",
              width: {
                mobile: "250px",
                md: "300px",
                lg: "400px",
                xl: "440px",
              },
              height: {
                mobile: "40px",
                md: "50px",
                lg: "60px",
                xl: "70px",
              },
              fontSize: {
                mobile: "1.25rem",
                md: "1.5rem",
              },
              "&:hover": {
                color: "white",
              },
            }}
            type="submit"
            onClick={handleSubmit(handleAdvancedSearch)}
            disabled={submitting}
            endIcon={submitting && <CircularProgress size={20} />}
          >
            {t("advancedSearchPage.form.search")}
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default AdvancedSearchForm;
