import { Box, Collapse, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsFillPlusCircleFill } from "react-icons/bs";
import AddressesButton from "../Buttons/AddressesButton";
import ProfileAddressForm from "../Forms/ProfileAddressForm";

type Props = {};
const defaultValues = {
  id: "",
  area_id: "",
  residential_area: "",
  street: "",
  building_no: "",
  floor: "",
  detailed_address: "",
  apartment: "",
};
const ChooseAddressContainer = (props: Props) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [formReset, setFormReset] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState(defaultValues);
  const [countryId, setCountryId] = useState(0);
  const [areaId, setAreaId] = useState(null);

  const { t } = useTranslation();
  return (
    <Stack spacing={{ mobile: "20px", sm: "40px" }}>
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "#FFAD0A",
            width: "22px",
            height: "22px",
            borderRadius: "50%",
          }}
        />
        <Typography
          sx={{
            color: "rgba(232, 214, 255, 1)",
            fontSize: {
              mobile: "18px",
              xs: "24px",
              sm: "30px",
              md: "34px",
              lg: "28px",
              xl: "34px",
            },
            fontWeight: "medium",
          }}
        >
          {t(`purchases.payment.address`)}
        </Typography>
      </Box>
      <Stack>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              mobile: "1fr",
              xxl: "1.25fr 1fr",
            },
            gap: "1rem",
            justifyItems: {
              mobile: "center",
              xxl: "start",
            },
            alignItems: "center",
          }}
        >
          <AddressesButton />
          <Box
            onClick={() => {
              setFormReset(!formReset);

              setShowForm(!showForm);
            }}
            sx={{
              display: "flex",
              flex: "1",
              flexDirection: "row",
              gap: "24px",
              alignItems: "center",
              width: "100%",
              justifyContent: {
                mobile: "center",
                md: "flex-end",
              },
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(117, 87, 152, 0.04)",
                borderRadius: "10px",
              },
            }}
          >
            <Box
              sx={{
                height: { mobile: "30px", md: "40px", lg: "50px", xl: "60px" },
                width: { mobile: "30px", md: "40px", lg: "50px", xl: "60px" },
                borderRadius: "8px",
                backgroundColor: "#E8D6FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: {
                  mobile: "0",
                  xxl: "32px",
                },
                fontSize: "2rem",
                padding: {
                  mobile: "6px",
                },
              }}
            >
              <BsFillPlusCircleFill color="#755798" />
            </Box>
            <Typography
              sx={{
                fontSize: {
                  mobile: "1rem",
                  sm: "1.5rem",
                  lg: "1.25rem",
                  xl: "1.5rem",
                },
                fontWeight: "400",
                color: "#ECECEC",
                marginTop: "5px",
                "@media (max-width: 400px)": {
                  fontSize: "1rem",
                },
              }}
            >
              {t("profile.new")}
            </Typography>
          </Box>
        </Box>

        <Collapse in={showForm} timeout={500}>
          <Box
            sx={{
              margin: "2rem 0",
            }}
          >
            <ProfileAddressForm
              addAddress={true}
              formReset={formReset}
              selectedAddress={selectedAddress}
              setAreaId={setAreaId}
              areaId={areaId}
              setCountryId={setCountryId}
              countryId={countryId}
            />
          </Box>
        </Collapse>
      </Stack>
    </Stack>
  );
};

export default ChooseAddressContainer;
