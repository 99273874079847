import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { StyledSecondaryButton } from "../../StyledComponents/StyledButton/StyledButton";

type Props = {
  text: string;
  action?: boolean;
  size?: "small";
};

const NotFound = ({ text, action = true, size }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: {
          mobile: "1rem",
          md: "2rem",
          lg: "3rem",
        },
      }}
    >
      <Typography
        sx={{
          color: "#FFAD0A",
          fontWeight: "bold",
          textAlign: "center",
          fontSize: {
            mobile: size === "small" ? "2rem" : "2rem",
            md: size === "small" ? "2.5rem" : "2.5rem",
            xl: size === "small" ? "3rem" : "3rem",
          },
        }}
      >
        {text}
      </Typography>
      {action && (
        <StyledSecondaryButton
          variant="contained"
          onClick={() => navigate("/")}
          sx={{
            color: "white",
          }}
        >
          {t("home")}
        </StyledSecondaryButton>
      )}
    </Box>
  );
};

export default NotFound;
