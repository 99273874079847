import { Box, Stack, Typography, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  setFilterType: React.Dispatch<React.SetStateAction<number>>;
  filterType: number;
};
const StyledRadioText = styled(Typography)({
  fontWeight: "300",
  fontSize: "28px",
  transition: "all .3s ease",
  cursor: "pointer",
  position: "relative",
  "&::before": {
    content: "''",
    opacity: 0,
    position: "absolute",
    width: "45px",
    height: "1px",
    backgroundColor: "#CCECFF",
    top: "50%",
    left: "-45px",
    transform: "translateY(-50%)",
  },
  "&::after": {
    content: "''",
    opacity: 0,
    position: "absolute",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "#CCECFF",
    top: "50%",
    left: "-12px",
    transform: "translateY(-50%)",
  },
  "@media (max-width: 768px)": {
    fontSize: "20px",
    "&::before": {
      width: "30px",
      left: "-30px",
    },
    "&::after": {
      width: "8px",
      height: "8px",
      left: "-8px",
    },
  },
});

const RadioButton = ({ setFilterType, filterType }: Props) => {
  const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        backgroundColor: "#5454542e",
        borderRadius: "10px",
        padding: ".5rem 1rem",
        transition: "all .3s ease",
      }}
    >
      <StyledRadioText
        sx={{
          color: filterType === 1 ? "#CCECFF" : "#ffffff2b",
          "&::before,&::after": {
            opacity: filterType === 1 ? 1 : 0,
            left: "unset",
          },
          "&::before": {
            right: {
              mobile: "-30px",
              sm: "-45px",
            },
          },
          "&::after": {
            right: {
              mobile: "-8px",
              sm: "-12px",
            },
          },
        }}
        onClick={() => setFilterType(1)}
      >
        {t("filter.arabic")}
      </StyledRadioText>
      <Box
        sx={{
          width: {
            mobile: "60px",
            sm: "90px",
          },
          height: "1px",
          backgroundColor: "#ffffff2b",
        }}
      />
      <StyledRadioText
        sx={{
          color: filterType === 2 ? "#CCECFF" : "#ffffff2b",
          "&::before,&::after": {
            opacity: filterType === 2 ? 1 : 0,
          },
        }}
        onClick={() => setFilterType(2)}
      >
        {t("filter.english")}
      </StyledRadioText>
    </Stack>
  );
};

export default RadioButton;
