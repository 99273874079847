import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { loginService } from "../../../Helpers/AuthFunctions/LoginHelpers";
import { displayToastErrorApi, displayToastSuccess } from "../../../Helpers/toastHelper";
import { authActions } from "../../../store/auth-slice";
import AuthTextField from "../../Blocks/AuthTextField/AuthTextField";
import { StyledAuthButton } from "../../StyledComponents/StyledButton/StyledButton";
import "./index.scss";
import * as yup from "yup";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";


const LoginForm = () => {
  const { t } = useTranslation();

  const cookies = new Cookies();

  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const handleShowPassword = () => {
    setShowPass(!showPass);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogin = async (data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    try {
      setLoading(true);
      await loginService(formData).then((res) => {
        if (res.status === 200) {
          displayToastSuccess(res?.message);
          cookies.set("token", res?.data?.access_token, {
            path: "/",
          });
          cookies.set("vip", res?.data?.is_vip, { path: "/" });
          cookies.set("currency", res?.data?.currency?.code, { path: "/" });
          cookies.set("currency_ar", res?.data?.currency?.name_ar, { path: "/" });
          cookies.set("currency_en", res?.data?.currency?.name, { path: "/" });
                    
          dispatch(
            authActions.login({
              token: res?.data?.access_token,
              isVip: res?.data?.is_vip,
            })
          );
          navigate("/");
          setTimeout(() => {
            // console.log("test1",cookies.get("token"), res?.data);
          }, 5000)
        } else {
          displayToastErrorApi(res?.data?.message);
        }
      });
    } catch (error) {
      displayToastErrorApi(error?.response?.data?.message);

      //loop over the errors object and set the errors
      for (let key in error?.response?.data?.errors) {
        setError(key, {
          message: error?.response?.data?.errors[key][0],
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const validationSchema = yup.object().shape({
    username: yup.string().required(t("loginForm.validation.userName")),
    password: yup.string().required(t("loginForm.validation.password")),
  });
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  return (
    <form className="login-form flex flex-col" onSubmit={handleSubmit(handleLogin)}>
      <div className="form__fields">
        <AuthTextField
          placeholder={t("loginForm.userName")}
          name="username"
          register={register}
          error={errors.username}
          touched={touchedFields.username}
        />
        <Box
           sx={{
            display: "flex",
            width: "100%",
            position: "relative"
          }}
        >

          <AuthTextField
            placeholder={t("loginForm.password")}
            name="password"
            type={showPass ? "text" : "password"}
            register={register}
            error={errors.password}
            touched={touchedFields.password}
          />
          <Box 
            onClick={handleShowPassword}
            sx={{
            display: "flex",
            position: "absolute",
            right: "1rem",
            top: "1.6rem",
            zIndex: 2,
            cursor: "pointer"
          }} >
            {showPass ?
              <IoEyeOffOutline />
              : 
              <IoEyeOutline />
            }
          </Box>
        </Box>

        <div className="forget__password">
          <Link to="/forget-password">{t("loginForm.forgetPassword")}</Link>
        </div>
      </div>

      <StyledAuthButton
        variant="contained"
        type="submit"
        disabled={loading}
        endIcon={loading && <CircularProgress size={20} />}
        sx={{
          color: "#E8D6FF",
        }}
      >
        {t("loginForm.login")}
      </StyledAuthButton>
    </form>
  );
};

export default LoginForm;
