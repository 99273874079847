import { Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddToCartButton from "../Buttons/AddToCartButton";
import AddToFavoriteButton from "../Buttons/AddToFavoriteButton";
import { Price } from "../../../Types/Types";

type Props = {
  name: string;
  category: string;
  category_ar: string;
  category_en: string;
  id: string;
  type: string;
  is_favorite: boolean;
  name_ar: string;
  price: Price;
  is_available: boolean;
};

const MediaHeaderMainInfo = ({
  name,
  category,
  id,
  type,
  is_favorite,
  category_ar,
  category_en,
  name_ar,
  price,
  is_available,
}: Props) => {
  const matches = useMediaQuery("(max-width:768px");
  const { i18n, t } = useTranslation();
  return (
    <Stack>
      <Stack
        spacing={1.5}
        sx={{
          width: "100%",
          borderBottom: "1px solid #e8d6ff47",
          paddingBottom: "1rem",
          position: "relative",
        }}
      >
        <Stack
          direction={{
            mobile: "column",
            // sm: "column",
            sm: "row",
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={{
            mobile: 2,
            lg: 4,
          }}
        >
          <Stack>
            <Typography
              sx={{
                fontSize: {
                  mobile: "20px",
                  md: "24px",
                  lg: "28px",
                },
                fontWeight: 600,
                color: "white",
                marginBottom: "20px"
              }}
            >
              {t("youAreInCategory") + " "}
              {i18n.language === "ar" ? category_ar : category_en}
            </Typography>
            <Tooltip title={name}>
              <Typography
                sx={{
                  fontSize: {
                    mobile: "32px",
                    md: "36px",
                    lg: "40px",
                  },
                  textAlign: "unset",
                  fontWeight: 700,
                  color: "#E8D6FF",
                  justifySelf: "flex-start",
                  marginBottom: "20px",
                  textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                  width: {
                    mobile: "100%",
                    lg: "unset",
                  },
                  maxWidth: {
                    mobile: "100%",
                    sm: "100%",
                    // lg: "80%"
                  }
                }}
              >
                {name}
              </Typography>
            </Tooltip>
            <Typography
              sx={{
                fontSize: {
                  mobile: "18px",
                  md: "22px",
                  lg: "24px",
                },
                fontWeight: 600,
                color: "#FFAD0A",
                marginBottom: "20px",
              }}
            >
              {category}
            </Typography>
          </Stack>

          <Stack
            spacing={1}
            direction="row"
            sx={{
              justifyContent: "flex-end",
            }}
          >
            <AddToFavoriteButton
              is_favorite={is_favorite}
              id={id}
              type={type}
            />
            <AddToCartButton
              id={id}
              type={type}
              price={price}
              is_available={is_available}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MediaHeaderMainInfo;
