import React from "react";
import { useTranslation } from "react-i18next";

type Props = {};

const useConstructAddress = () => {
  const { t } = useTranslation();
  const constructAddress = (
    address: {
      area: any;
      residential_area: any;
      street: string;
      building_no: string;
      floor: string;
      detailed_address: string;
      apartment: string;
      block: string;
      avenue: string;
      country: any;
    },
    language: any
  ) => {
    const {
      area,
      residential_area,
      street,
      building_no,
      floor,
      apartment,
      block,
      avenue,
      country,
      detailed_address,
    } = address;
    const addressParts = [];

    if (country?.id === 1) {
      addressParts.push(
        `${t("profile.addressForm.country")} : ${country[`name_${language}`]}`
      );

      if (area)
        addressParts.push(
          `${t("profile.addressForm.zone")} : ${area[`name_${language}`]}`
        );

      if (residential_area)
        addressParts.push(
          `${t("profile.addressForm.residential_area")} : ${
            residential_area[`name_${language}`]
          }`
        );

      if (street)
        addressParts.push(`${t("profile.addressForm.street")} : ${street}`);

      if (building_no)
        addressParts.push(
          `${t("profile.addressForm.building")} : ${building_no}`
        );

      if (floor)
        addressParts.push(`${t("profile.addressForm.floor")} : ${floor}`);

      if (apartment)
        addressParts.push(
          `${t("profile.addressForm.apartment")} : ${apartment}`
        );

      if (block)
        addressParts.push(`${t("profile.addressForm.block")} : ${block}`);

      if (avenue)
        addressParts.push(`${t("profile.addressForm.avenue")} : ${avenue}`);

      return addressParts.join("  --  ");
    } else {
      return `${t("profile.addressForm.country")} : ${
        country[`name_${language}`]
      } -- ${t("profile.titleAddress")} : ${detailed_address}`;
    }
  };
  return { constructAddress };
};

export default useConstructAddress;
