import React, { useState } from "react";
import { createData } from "../Services/DataServices";
import { displayToastSuccess } from "../Helpers/toastHelper";
import { useDispatch } from "react-redux";
import { refreshApiActions } from "../store/refresh-slice";

const useDeleteFromCart = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleDeleteFromCart = async (id: string) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("id", id);
    try {
      await createData(`cart/remove`, formData).then((res) => {
        if (res.status === 200) {
          displayToastSuccess(res?.data?.message);
          dispatch(refreshApiActions.refreshApi());
        }
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return { loading, handleDeleteFromCart };
};

export default useDeleteFromCart;
