import { styled } from "@mui/material";

const StyledText = styled("p")({
  color: "#CEAFF5",
  fontSize: "1.5rem",
  lineHeight: " 50px",
  letterSpacing: "1.4px",
  "@media (max-width: 768px)": {
    fontSize: "1.25rem",
    lineHeight: " 40px",
    letterSpacing: "1.2px",
  },
});

export default StyledText;
