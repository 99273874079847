import { Box, Button, Typography } from "@mui/material";
import PageContentContainer from "../../StyledComponents/PageContentContainer/PageContentContainer";
import MainContainer from "../MainContainer/MainContainer";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ParticlesBackGround from "../Particles/ParticlesBackGround";
const ErrorComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <ParticlesBackGround />
      <MainContainer>
        <PageContentContainer>
          <Box
            sx={{
              margin: "0 auto",
              padding: {
                mobile: "2rem",
                md: "4rem 6rem",
              },
              backgroundColor: "#CEAFF542",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15vh",
              borderRadius: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                textAlign: "center",
                cursor: "default",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                variant="h4"
              >
                {t("error.title")}
              </Typography>
              <Typography
                sx={{
                  color: "#d7b5ff",
                  textAlign: "center",
                }}
                variant="h6"
              >
                {t("error.tryAgain")}
              </Typography>

              <Button
                component={"a"}
                href="/"
                variant="contained"
                sx={{
                  marginTop: "1.5rem",
                  color: "#755798",
                  backgroundColor: "#F3F3F3",
                  width: "180px",
                  borderRadius: "10px",
                  margin: "0 auto",
                }}
              >
                {t("home")}{" "}
              </Button>
            </Box>
          </Box>
        </PageContentContainer>
      </MainContainer>
    </>
  );
};

export default ErrorComponent;
