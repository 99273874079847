import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import CollapsibleTable from "../tables/CollapsibleTable";
import { styled } from "@mui/material";
import { seasonItemType, seriesItemType } from "../../../Types/Types";
import SingleSectionContainer from "../SingleOrderComponents/SingleSectionContainer";
import { useDispatch, useSelector } from "react-redux";
import { singleOrderActions } from "../../../store/singleOrder-slice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledTab = styled(Tab)({
  fontSize: "1rem",
  fontWeight: "bold",
  color: "white",
  alignItems: "baseline",
  "&.Mui-selected": {
    color: "#E69D0B",
    background: "#e69d0b21",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
  },
});

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

type Props = {
  seasons: seasonItemType[];
  itemType: string;
};

const SeriesTabs = ({ seasons, itemType }: Props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const singleOrderState = useSelector((state: any) => state.singleOrder);
  const handleExpandSeason = (title: string) => {
    if (singleOrderState.expandedItem === title) {
      dispatch(singleOrderActions.setExpandedItem(""));
    } else {
      dispatch(singleOrderActions.setExpandedItem(title));
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 5fr",
        gap: "2rem",
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs purchase"
        sx={{
          width: "100%",
          //   padding: "1rem 0",
          //   border: "1px solid #CEAFF5",
          borderRadius: "0px",

          "& .MuiTabs-flexContainer": {
            justifyContent: "space-around",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#E69D0B",
            left: "0",
          },
        }}
      >
        {seasons?.map((season, index) => {
          return (
            <StyledTab
              key={index}
              label={i18n.language === "en" ? season.name_en : season.name_ar}
              {...a11yProps(index)}
              onClick={() => {
                let title =
                  i18n.language === "en" ? season.name_en : season.name_ar;
                handleExpandSeason(title);
              }}
            />
          );
        })}
      </Tabs>
      {seasons?.map((season, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            <SingleSectionContainer
              itemType={itemType}
              title={i18n?.language === "en" ? season.name_en : season.name_ar}
              type="non-expandable"
              items={season?.episodes}
              color="#E8D6FF"
              mediaType="episode"
            />
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default SeriesTabs;
