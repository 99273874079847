import { Box, Typography } from "@mui/material";
import React from "react";
import { singleItemType } from "../../../Types/Types";
import SmallOrderCard from "../SmallOrderCard/SmallOrderCard";
import { useTranslation } from "react-i18next";

type Props = {};

const ItemsContainer = ({ items, itemType, mediaType, seasonQ }: any) => {
  const { t } = useTranslation();
  return (
    <>
      { seasonQ > 0 && items  &&

        <Typography sx={{
          fontSize: '18px',
          color: '#FFAD0A',
          lineHeight: '18px',
          fontWeight: 'bold',
          display: "block",
          marginBottom: '24px'
        }} >{t('purchases.copy_number')} : {seasonQ}</Typography>
      }
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "24px",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {items?.map((item: singleItemType) => {
          return (
            <Box sx={{
              display: 'flex',
              flexDirection: "column",
              gap: "8px"
            }} >
              {mediaType === 'movie' && item.quantity  && <Typography sx={{
              fontSize: '18px',
              color: '#FFAD0A',
              lineHeight: '18px',
              fontWeight: 'bold',
              display: "block",
              textAlign: 'center'
            }} >{t('purchases.copy_number')} : {item.quantity}</Typography>}
              <SmallOrderCard
                key={item?.id}
                {...item}
                itemType={itemType}
                mediaType={mediaType}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
};
export default React.memo(ItemsContainer);
