import { Box, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import playButton from "../../Assets/Images/play-button.svg";
import ActorCard from "../../Components/Blocks/ActorComponents/ActorCard";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import MediaHeader from "../../Components/Blocks/MediaHeader/MediaHeader";
import MediaListSlider from "../../Components/Blocks/MediaListSlider/MediaListSlider";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SeriesCard from "../../Components/Blocks/SeriesComponents/SeriesCard";
import StyledSliderWithIcon from "../../Components/StyledComponents/StyledSliderWithIcon/StyledSliderWithIcon";
import useGetData from "../../Hooks/useGetData";
import { trailerActions } from "../../store/trailer-slice";
import StyledMainBox from "../MoviesPage/Styles/StyledMainBox";
import StyledSliderContainer from "../MoviesPage/Styles/StyledSliderContainer";
import changeImageQuality from "../../Helpers/changeImageQuality";

type Props = {};

const SeriesPage = (props: Props) => {
  const { i18n, t } = useTranslation();

  const { id } = useParams<string>();
  const { data, loading, error } = useGetData(`series/show/${id}`, [
    id,
    i18n.language,
  ]);

  const dispatch = useDispatch();
  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  let tags = [
    // {
    //   name: "Tags",
    //   name_ar: "التصنيف",
    //   label_value: data?.series?.series_data?.media_tags,
    //   label_value_ar: data?.series?.series_data?.media_tags,
    // },

    {
      name: "Product No",
      name_ar: "رقم المنتج",
      label_value: data?.series?.series_serial,
      label_value_ar: data?.series?.series_serial,
    },
    {
      name: "Quality",
      name_ar: "الجودة",
      label_value: data?.series?.quality,
      label_value_ar: data?.series?.quality_ar,
    },
    {
      name: "Size",
      name_ar: "الحجم",
      label_value: `${data?.series?.actual_size} GB`,
      label_value_ar: `${data?.series?.actual_size} جيجا`,
    },
    {
      name: "Dvd Quantity",
      name_ar: " عدد ال DVD",
      label_value: data?.series?.dvd_quantity || "0",
      label_value_ar: data?.series?.dvd_quantity || "0",
    },
    {
      name: "Production Year",
      name_ar: "سنة الانتاج",
      label_value: data?.series?.year,
      label_value_ar: data?.series?.year,
    },
    {
      name: "Duration in minutes",
      name_ar: "مدة العرض بالدقائق",
      label_value: `${data?.series?.duration} min`,
      label_value_ar: `${data?.series?.duration} دقيقة`,
    },
    {
      name: "Language",
      name_ar: "اللغة",
      label_value: data?.series?.language,
      label_value_ar: data?.series?.language_ar,
    },
    {
      name: "Subtitles",
      name_ar: "الترجمة",
      label_value: data?.series?.subtitles?.join(", "),
      label_value_ar: data?.series?.subtitles_ar?.join(", "),
    },
    {
      name: "Director",
      name_ar: "المخرج",
      label_value: data?.series?.directors,
      label_value_ar: data?.series?.directors_ar,
    },

    {
      name: "Notes",
      name_ar: "الملاحظات",
      label_value: data?.series?.notes,
      label_value_ar: data?.series?.notes,
    },
  ];

  const SeriesSlider = MediaListSlider(SeriesCard);
  const ActorsSlider = MediaListSlider(ActorCard);

  return (
    <Stack>
      <StyledMainBox
        sx={{
          background: `url(
            ${data?.series?.poster}
          ) no-repeat center center / fill`,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          sx={{
            marginTop: "50vh",
            height: "100%",
            "@media (max-width: 768px) and (min-height: 600px)": {
              marginTop: "200px",
            },
            "@media (max-width: 1280px) and (min-height: 800px)": {
              marginTop: "40vh",
            },
            gap: {
              mobile: "4rem",
              sm: "8rem",
              md: "12rem",
              lg: "15rem",
            },
          }}
        >
          <Box
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{
              scale: 0.9,
            }}
            component={motion.img}
            src={playButton}
            alt="play-button"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(trailerActions.openModal(data?.series?.youtube_code));
            }}
          />
          <MainContainer>
            <MediaHeader
              category={data?.series?.category}
              category_ar={data?.series?.category_ar}
              descAr={data?.series?.description_ar}
              descEn={data?.series?.description_en}
              image={data?.series?.poster}
              thumb={data?.series?.poster_thumb}
              noPeopleRated={data?.series?.count_rate}
              productId={data?.series?.id}
              is_favorite={data?.series?.is_favorite}
              rating={data?.series?.avg_rate}
              type="series"
              {...data?.series}
              tags={tags}
            />
          </MainContainer>
        </Stack>
      </StyledMainBox>
      <StyledSliderContainer>
        <MainContainer>
          <Stack
            spacing={{
              mobile: 5,
              sm: 10,
              md: 15,
              lg: 20,
            }}
          >
            {data?.series?.cast?.length > 0 && (
              <StyledSliderWithIcon title={t("actors")}>
                <ActorsSlider
                  items={data?.series?.cast}
                  dots={true}
                  small={true}
                />
              </StyledSliderWithIcon>
            )}
            {data?.series?.seasons?.length > 0 && (
              <StyledSliderWithIcon title={t("seasons")}>
                <SeriesSlider
                  items={data?.series?.seasons}
                  dots={true}
                  small={true}
                />
              </StyledSliderWithIcon>
            )}
          </Stack>
        </MainContainer>
        <RequestBlock />
      </StyledSliderContainer>
    </Stack>
  );
};

export default SeriesPage;
