import { createSlice } from "@reduxjs/toolkit";

export type item = {
  id: number;
  price_type: number;
  price: number;
  has_cover: boolean;
  cover_price: number;
  discount_price: number;
};

export type cartState = {
  openCheckOutModal: boolean;
  refreshCartToken: boolean;
  open: boolean;
  items: item[];
  store_items: any;
  address_id?: number | string;
  client_note?: string;
  payment_method?: number; // 1: cash, 2: my fatoorah
  storage_items?: any[];
  totalPrice?: number;
  totalCost?: number;
  offer_discount?: number;
  totalDiscountPrice?: number;
  discount?: number;
  totalActualSize?: number;
  shippingArea?: boolean;
  downloadItems: item[];
  onlineItems: item[];
  storageItems: item[];
  dvdItems: item[];
  offer?: {
    amount: string;
    percentage: string;
  } | null;
  shippingData?: any;
  shippingPrice?: number;
  dvdQuantity?: number;
  checkoutModal?: boolean;
  checkoutModalData?: any;
  dvdPrices?: any;
  totalStoragesPrice?: number;
  fatoorahUrl?: string;
  uPayment?: string;
};

const initialState: cartState = {
  refreshCartToken: false,
  openCheckOutModal: false,
  open: false,
  items: [],
  store_items: [],
  address_id: "",
  client_note: "",
  payment_method: 4,
  storage_items: [],
  totalPrice: 0,
  totalCost: 0,
  offer_discount: 0,
  totalDiscountPrice: 0,
  discount: 0,
  totalActualSize: 0,
  shippingPrice: 0,
  shippingData: undefined,
  shippingArea: false,
  downloadItems: [],
  onlineItems: [],
  storageItems: [],
  dvdItems: [],
  dvdQuantity: 0,
  offer: null,
  checkoutModal: false,
  checkoutModalData: undefined,
  dvdPrices: 0,
  totalStoragesPrice: 0,
  fatoorahUrl: "",
  uPayment:""
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setFatoorahUrl: (state, action) => {
      state.fatoorahUrl = action.payload;
    },
    setUPaymentUrl: (state, action) => {
      state.uPayment = action.payload;
    },
    handleOpenCheckOutModal: (state) => {
      state.openCheckOutModal = true;
    },
    handleCloseCheckOutModal: (state) => {
      state.openCheckOutModal = false;
    },
    handleCheckOutModal: (state, action) => {
      state.checkoutModal = action.payload;
    },
    setDvdPrices: (state, action) => {
      state.dvdPrices = action.payload;
    },
    setCheckoutModalData: (state, action) => {
      state.checkoutModalData = action.payload;
    },
    addClientNote: (state, action) => {
      state.client_note = action.payload;
    },
    refreshCart: (state) => {
      state.refreshCartToken = !state.refreshCartToken;
    },
    openCart: (state) => {
      state.open = true;
    },
    setStoragesPrice: (state, action) => {
      state.totalStoragesPrice = action.payload;
    },
    closeCart: (state) => {
      state.open = false;
      state.items = [];
      state.address_id = "";
      state.storage_items = [];
      state.totalPrice = undefined;
      state.client_note = "";
      state.shippingData = undefined;
      state.shippingArea = false;
      state.downloadItems = [];
      state.onlineItems = [];
      state.storageItems = [];
      state.dvdItems = [];
      state.dvdPrices = 0;
      state.totalStoragesPrice = 0;
    },
    addItems: (state, action) => {
      state.items = action.payload;
    },
    addDownloadItems: (state, action) => {
      state.downloadItems = action.payload;
    },
    addOnlineItems: (state, action) => {
      state.onlineItems = action.payload;
    },
    addStorageItems: (state, action) => {
      state.storageItems = action.payload;
    },
    addDvdItems: (state, action) => {
      state.dvdItems = action.payload;
    },
    addDvdQuantity: (state, action) => {
      state.dvdQuantity = action.payload;
    },
    removeItem: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
      if (action.payload.price_type === 1) {
        state.downloadItems = state.onlineItems.filter(
          (item) => item.id !== action.payload.id
        );
      }
      if (action.payload.price_type === 2) {
        state.onlineItems = state.downloadItems.filter(
          (item) => item.id !== action.payload.id
        );
      }
      if (action.payload.price_type === 3) {
        state.storageItems = state.storageItems.filter(
          (item) => item.id !== action.payload.id
        );
      }
      if (action.payload.price_type === 4) {
        state.dvdItems = state.dvdItems.filter(
          (item) => item.id !== action.payload.id
        );
      }
    },
    clearItems: (state) => {
      state.items = [];
    },
    setOffer: (state, action) => {
      state.offer = action.payload;
    },
    setStoreItems: (state, action) => {
      state.store_items = action.payload;
    },
    setAddress: (state, action) => {
      state.address_id = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.payment_method = action.payload;
    },
    setTotalPrice: (state, action) => {
      state.totalPrice = action.payload;
    },
    setTotalCost: (state, action) => {
      state.totalCost = action.payload;
    },
    setOfferDiscount: (state, action) => {
      state.offer_discount = action.payload;
    },
    setTotalDiscountPrice: (state, action) => {
      state.totalDiscountPrice = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    setTotalActualSize: (state, action) => {
      state.totalActualSize = action.payload;
    },
    setShippingArea: (state, action) => {
      state.shippingArea = action.payload;
    },
    setShippingPrice: (state, action) => {
      state.shippingPrice = action.payload;
    },
    addStorageItem: (state, action) => {
      state.store_items = action.payload;
    },
    removeStorageItem: (state, action) => {
      state.storage_items = state?.storage_items?.filter(
        (item) => item.id !== action.payload
      );
    },
    emptyStorageItems: (state) => {
      state.storage_items = [];
    },

    //set shipping data
    setShippingData: (state, action) => {
      state.shippingData = action.payload;
    },
    clearCart: (state) => {
      state.open = false;
      state.items = [];
      state.address_id = undefined;
      state.payment_method = 4;
      state.storage_items = [];
      state.totalPrice = undefined;
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice;
