import { useLocation, useParams } from "react-router-dom";
import CustomPagination from "../../Components/Blocks/CustomPagination/CustomPagination";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MediaListGrid from "../../Components/Blocks/MediaListGrid/MediaListGrid";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import useGetData from "../../Hooks/useGetData";
import { Stack, Box } from "@mui/material";
import queryString from "query-string";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import NotFound from "../../Components/Blocks/NotFound/NotFound";
import { useTranslation } from "react-i18next";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";
import ProductionYearSelect from "../../Components/Blocks/ProductionYearSelect/ProductionYearSelect";
import { useScrollToTop } from "../../Hooks/useScrollToTop";

type Props = {};

const MoreItemsPage = (props: Props) => {
  const { id } = useParams<string>();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { data, loading, error } = useGetData(
    `pages/home_more/${id}?page=${query?.page || "1"}&sort_by=${
      query?.sort_by || ""
    }&genre_ids=${query?.genre_ids || ""}&year=${query?.year || ""}`,
    [query?.page, query?.sort_by, query?.genre_ids, query?.year]
  );

  useScrollToTop([data]);

  let key = "movies";
  if (id === "5") key = "series";

  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;

  return (
    <>
      <ParticlesBackGround />
      <MainContainer>
        <PageContentContainer>
          {data?.[key] && (
            <Stack>
              <SectionHead
                color="#CEAFF5"
                title={
                  i18n.language === "ar" ? data?.title.txt_ar : data?.title.txt
                }
                actions={true}
                sortButton
              />
              {id === "6" && (
                <Stack alignItems={"flex-end"}>
                  <ProductionYearSelect />
                </Stack>
              )}
            </Stack>
          )}
          {data?.[key]?.data && data?.[key]?.data?.length > 0 ? (
            <MediaListGrid cardsList={data?.[key]?.data} />
          ) : (
            <NotFound text={id==="6"?t("noItemsThisYear"):t("noCategories")} />
          )}

          {data?.[key]?.data && data?.[key]?.data?.length > 0 && (
            <CustomPagination
              totalPages={data?.[key]?.last_page}
              from={data?.[key]?.from}
              to={data?.[key]?.to}
              total={data?.[key]?.total}
            />
          )}
        </PageContentContainer>
      </MainContainer>
      <RequestBlock />
    </>
  );
};

export default MoreItemsPage;
