// @ts-nocheck
import { Box, IconButton, Modal, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../../store/cart-slice";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalButton from "../PaypalButtons/PaypalButton";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

type Props = {
  handleSubmitOrder: () => any;
  total_usd?: string;
};

const CheckoutModal = ({ handleSubmitOrder, total_usd }: Props) => {
  const cartState = useSelector((state: any) => state.cart);
  const { i18n, t } = useTranslation();
  const translationPath = "purchases.purchases-cart.possessions.";
  const isMobile = useMediaQuery("(max-width:650px)");
  const boxStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    height: "100vh",
    bgcolor: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const containerStyles = {
    width: isMobile ? "95vw" :"40vw",
    maxHeight: "90vh",
    display: "flex",
    overflowY: "auto",
    borderRadius: "24px",
    backgroundColor: "white",
    boxShadow: 24,
    padding: "56px 24px 24px",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#3db9ec",
      borderRadius: "40px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "16px 0",
    },
  };

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(cartActions.handleCloseCheckOutModal());
  };
  const cookies = new Cookies();
  const lang = cookies.get("i18next")

  // console.log('cartstate', lang);
  
  return (
    <Modal open={cartState.openCheckOutModal} onClose={handleClose}>
      <Box sx={boxStyles}>
        <Box sx={containerStyles}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: ".5rem",
              right: ".5rem",
              "& svg": {
                color: "error.main",
              },
            }}
          >
            <MdClose />
          </IconButton>
          {cartState?.payment_method === 2 && (
            <Box
              sx={{
                minHeight: "60vh",
                width: "100%",
              }}
            >
              <iframe
                src={cartState?.fatoorahUrl}
                title="payment"
                width="100%"
                height="100%"
                frameBorder="0"
              />
            </Box>
          )}
          {cartState?.payment_method === 4 && (
            <Box
              sx={{
                minHeight: "60vh",
                width: "100%",
              }}
            >
              <iframe
                id="paymentIframe"
                src={cartState?.uPayment}
                title="payment"
                width="100%"
                height="100%"
                lang="en"
                frameBorder="0"
              />
            </Box>
          )}
          {cartState?.payment_method === 3 && (
            <Stack 
              sx={{
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                <Typography
                  sx={{
                    color: "#755798",
                    fontSize: {
                      mobile: "18px",
                      md: "20px",
                      lg: "24px",
                    },
                  }}
                >
                  {t(`${translationPath}total`)}
                </Typography>
                <Typography
                  sx={{
                    color: "#755798",
                    fontSize: {
                      mobile: "18px",
                      md: "20px",
                      lg: "24px",
                    },
                  }}
                >
                  {`${total_usd} ${t('purchases.purchases-cart.usd')}`}
                </Typography>
              </Box>
            
              <PayPalScriptProvider
                options={{
                  // clientId:
                  //   "AfXQcE3yLywOZzJWm2U2CH8aLt3q1GM2EJv97SlaWrxk1iTBlVtVnbz0NHKmQ3xdCK3yUPFnqfSRSoIj",
                  // "",
                  clientId: "AZ9QW2Ox2JaIR_UBgcIw5Vjmbv_zgPF5w7PfIj7yNgNnNd72x0HnE3LbIjJN9gKLGXR_uexdCH3h4hO5",
                  currency: "USD",
                  intent: "capture",
                  // components: "card-fields",
                }}
              >

                <PaypalButton handleSubmit={handleSubmitOrder} />
              </PayPalScriptProvider>
            </Stack>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CheckoutModal;
