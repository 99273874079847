import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { handleForgetPasswordStepTwo } from "../../../Helpers/AuthFunctions/ForgetPasswordHelpers";
import Otp from "../../Blocks/Otp/Otp";
import { StyledAuthButton } from "../../StyledComponents/StyledButton/StyledButton";

const StepTwo = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    handleForgetPasswordStepTwo(data, setError, setLoading, navigate);
  };
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("forgetPassword.stepOne.validation.email"))
      .required(t("forgetPassword.stepOne.validation.email")),
  });
  const { handleSubmit, setError } = useForm({
    resolver: yupResolver(validationSchema),
  });
  return (
    <form className="forget-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__fields">
        <h6>{t("forgetPasswordPage.stepTwo.title")}</h6>
        <Otp setCode={setCode} code={code} />
      </div>

      <StyledAuthButton
        variant="contained"
        onClick={() => {
          handleForgetPasswordStepTwo(code, setError, setLoading, navigate);
        }}
        disabled={loading}
        endIcon={loading && <CircularProgress size={20} />}
      >
        {t("forgetPasswordPage.stepTwo.send")}
      </StyledAuthButton>
    </form>
  );
};

export default StepTwo;
