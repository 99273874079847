import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  open: boolean;
}

const initialState: initialStateType = {
  open: false,
};

const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
  },
});

export const verificationsActions = verificationSlice.actions;
export default verificationSlice;
