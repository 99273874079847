import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  displayToastErrorApi,
  displayToastSuccess,
} from "../Helpers/toastHelper";
import { createData } from "../Services/DataServices";
import { useDispatch } from "react-redux";
import { refreshApiActions } from "../store/refresh-slice";
import { movie } from "../Types/Types";

const useRating = (rating: string) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const createRating = async (rating: number, productId: number, type: any) => {
    let formData = new FormData();
    formData.append("rating", rating.toString());
    formData.append("product_id", productId.toString());
    formData.append("type", type);
    try {
      setLoading(true);
      await createData("rating/create", formData).then((response) => {
        if (response.status === 200) {
          displayToastSuccess(response?.data?.message);
          // dispatch(refreshApiActions.refreshApi());
        }
      });
    } catch (error: any) {
      displayToastErrorApi(
        error?.response?.data?.message || "Something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };
  return {
    createRating,
    loading,
  };
};

export default useRating;
