import React from "react";
import OtpInput from "react-otp-input";

export default function Otp({ setCode, code }) {
  const matches = window.matchMedia("(max-width: 768px)").matches;
  return (
    <OtpInput
      value={code}
      onChange={setCode}
      numInputs={6}
      renderSeparator={false}
      renderInput={(props) => <input {...props} />}
      containerStyle={{
        flexDirection: "row-reverse",
        justifyContent: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "12px",
        width: "100%",
      }}
      inputStyle={{
        width: matches ? "50px" : "70px",

        height: matches ? "50px" : "70px",

        borderRadius: matches ? "6px" : "12px",
        background: "#CEAFF542",
        fontSize: "1.5rem",
        color: matches ? "#e7d2ff" : "#755798",
        border: "none",
        outline: "none",
      }}
    />
  );
}
