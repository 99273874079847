import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  name_ar: string;
  label_value: any;
  label_value_ar: any;
};

const TagItem = ({ name, label_value, name_ar, label_value_ar }: Props) => {
  const { i18n } = useTranslation();

  if (!label_value && !label_value_ar) {
    return null;
  }
  if (name === "Director") {
    if (label_value?.length == 0) return null;
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={{
          mobile: 1,
          md: 2,
        }}
      >
        <Box
          sx={{
            padding: {
              mobile: ".25rem .75rem",
              md: ".5rem 1.25rem",
            },
            borderRadius: {
              mobile: "6px",
              md: "10px",
            },
            background: "#4A3860",
            color: "white",
          }}
        >
          {i18n.language === "en" ? name || name_ar : name_ar || name}
        </Box>
        <Typography
          sx={{
            color: "#FFAD0A",
            fontSize: {
              mobile: "14px",
              md: "18px",
            },
            fontWeight: 600,
            padding: {
              mobile: "6px 0",
              md: "8px 0",
            },
          }}
        >
          {label_value?.length > 0 && label_value[0]?.name}
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box
        sx={{
          padding: ".5rem 1.25rem",
          borderRadius: "10px",
          background: "#4A3860",
          color: "white",
        }}
      >
        {i18n.language === "en" ? name || name_ar : name_ar || name}
      </Box>
      <Typography
        sx={{
          color: "#FFAD0A",
          fontSize: "18px",
          fontWeight: 600,
          padding: "8px 0",
        }}
      >
        {i18n.language === "en"
          ? label_value || label_value_ar
          : label_value_ar || label_value}
      </Typography>
    </Stack>
  );
};

export default TagItem;
