import React from "react";
import "./index.scss";

const AuthTitle = ({ title }) => {
  return (
    <div className="auth__title">
      <h1>
        <span>{title}</span>
      </h1>
      <div className="line" />
    </div>
  );
};

export default AuthTitle;
