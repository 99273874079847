import { styled } from "@mui/material";

const PageContentContainer = styled("div")({
  padding: "280px 0 0 0",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  "@media (max-width: 1280px)": {
    padding: "200px 0 0 0",
    gap: "20px",
  },
  "@media (max-width: 1024px)": {
    padding: "180px 0 0 0",
    gap: "20px",
  },
});

export default PageContentContainer;
