import { Box, Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../../store/cart-slice";
import CheckoutModal from "../../Modals/CheckoutModal";
import ShoppingCartMain from "./ShoppingCartMain";
import ShoppingCartSideBox from "./ShoppingCartSideBox";
const ShoppingCart = () => {
  const cartState = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(cartActions.closeCart());
  };

  return (
    <>
      {/* <Dialog
        fullWidth={true}
        open={cartState.open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            "&::-webkit-scrollbar": {
              width: "0px",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,0)",
              borderRadius: "20px",
            },
            bgcolor: "#fff",
            boxShadow: 0,
            maxWidth: { mobile: "80%", sm: "100%" },
            borderRadius: "20px",
            marginX: {
              mobile: "0px",
              sm: "60px",
              md: "80px",
            },
          },
          my: {
            mobile: "10px",
          },
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              mobile: "1fr",
              md: "1.75fr 3fr",
              lg: "2.25fr 3fr",
            },
          }}
        >
          <ShoppingCartSideBox />
          <ShoppingCartMain />
        </Box>
      </Dialog> */}
      <CheckoutModal />
    </>
  );
};

export default ShoppingCart;
