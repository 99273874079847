import { Box, Typography } from "@mui/material";
import React from "react";
import icon from "../../../Assets/Images/title-icon.svg";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  color: string;
};

const TitleWithIcon = ({ title, color }: Props) => {
  const { i18n } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "2.5rem",
        position: "relative",
        padding: "0 0 0 20px",
        "&::before": {
          content: "''",
          position: "absolute",
          display: "block",
          width: "1px",
          height: "70%",
          backgroundColor: color,
          left: "0",
          top: "50%",
          transform: "translateY(-50%)",
        },
      }}
    >
      <Typography sx={{ fontSize: "2.5rem", fontWeight: "400", color: color }}>
        {title}
      </Typography>
      <Box
        component="img"
        src={icon}
        alt="icon"
        sx={{
          transform: i18n.language === "ar" ? "rotate(0deg)" : "rotate(180deg)",
        }}
      />
    </Box>
  );
};

export default TitleWithIcon;
