import React from "react";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomPagination from "../../Components/Blocks/CustomPagination/CustomPagination";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import MediaListGrid from "../../Components/Blocks/MediaListGrid/MediaListGrid";
import NotFound from "../../Components/Blocks/NotFound/NotFound";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import useGetData from "../../Hooks/useGetData";
import { useScrollToTop } from "../../Hooks/useScrollToTop";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";

type Props = {};

const SearchResultPage = (props: Props) => {
  const { t } = useTranslation();

  const location = useLocation();
  const query = queryString.parse(location.search);
  const queryParams = queryString.stringify(query);

  const { data, loading, error } = useGetData(`search?${queryParams}`, []);
  useScrollToTop([queryParams]);

  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  return (
    <>
      <ParticlesBackGround />
      <MainContainer>
        <PageContentContainer>
          <SectionHead
            color="#CEAFF5"
            title={t("searchResults.title")}
            sortButton={true}
            actions={true}
          />
          {data?.data?.data && data?.data?.data.length > 0 ? (
            <MediaListGrid cardsList={data?.data?.data} />
          ) : (
            <NotFound text={t("searchResults.noResults")} />
          )}
          {data?.data?.last_page && (
            <CustomPagination
              totalPages={data?.data?.last_page}
              from={data?.data?.from}
              to={data?.data?.to}
              total={data?.data?.total}
            />
          )}
        </PageContentContainer>
      </MainContainer>
      <RequestBlock />
    </>
  );
};

export default React.memo(SearchResultPage);
