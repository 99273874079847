import { Box } from "@mui/material";
import React from "react";
import TitleWithIcon from "../TitleWithIcon/TitleWithIcon";

type Props = {
  title: string;
  children: React.ReactNode;
};

const StyledSliderWithIcon = ({ title, children }: Props) => {
  return (
    <Box
      component={"section"}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: {
          mobile: "2rem",
          sm: "3rem",
          lg: "4rem",
        },
      }}
    >
      <TitleWithIcon title={title} color="#E8D6FF" />
      {children}
    </Box>
  );
};

export default StyledSliderWithIcon;
