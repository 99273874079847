import { Container } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import FavoriteSliderContainer from "../../Components/Blocks/FavoriteSliderContainer/FavoriteSliderContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import MediaSliderSection from "../../Components/Blocks/MediaSliderSection/MediaSliderSection";
import NotFound from "../../Components/Blocks/NotFound/NotFound";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SliderArrows from "../../Components/Blocks/SliderArrows/SliderArrows";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import useGetData from "../../Hooks/useGetData";
import { inflate } from "zlib";

type Props = {};

const FavoritesPage = (props: Props) => {
  const { data, loading, error } = useGetData("pages/wishlist", []);
  const { i18n, t } = useTranslation();
  const firstSliderRef = useRef<any>(null);
  const secondSliderRef = useRef<any>(null);
  const thirdSliderRef = useRef<any>(null);
  const [noData, setNoData] = useState(true);

  const filterItems = (items: any[]): any[] => {
    let newItems: any[] = [];
    items.forEach((item) => {
      newItems.push({
        ...item?.wishlistable,
        is_favorite: true,
      });
    });
    return newItems;
  };

  const getMainSliderLength = (items: any[]): any => {
    let returnedItemsLength: number = 0;
    if (items && items?.length > 0) {
      returnedItemsLength = items?.length;
    }
    return returnedItemsLength;
  };

  const getSliderSettings = (index: number): any => {
    let sliderSettings = {
      arrows: false,
      // infinite: data?.data[index]?.items < 2 ? false: true ,
      inflate: true,
      autoplay: true,
      speed: 500,
      slidesToShow:
        getMainSliderLength(data?.data[index]?.items) < 4
          ? getMainSliderLength(data?.data[index]?.items)
          : 4,
      slidesToScroll:
        getMainSliderLength(data?.data[index]?.items) < 4
          ? getMainSliderLength(data?.data[index]?.items) - 1
          : 4,
      initialSlide: 0,
      rows: 1,
      pauseOnHover: true,
      pauseOnFocus: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow:
              getMainSliderLength(data?.data[index]?.items) < 3
                ? getMainSliderLength(data?.data[index]?.items)
                : 3,
            slidesToScroll:
              getMainSliderLength(data?.data[index]?.items) < 3
                ? getMainSliderLength(data?.data[index]?.items) - 1
                : 3,
          },
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow:
              getMainSliderLength(data?.data[index]?.items) < 2
                ? getMainSliderLength(data?.data[index]?.items)
                : 2,
            slidesToScroll:
              getMainSliderLength(data?.data[index]?.items) < 2
                ? getMainSliderLength(data?.data[index]?.items) - 1
                : 2,
            centerMode: true,
            centerPadding: "65px",
          },
        },
        {
          breakpoint: 620,

          settings: {
            slidesToShow:
              getMainSliderLength(data?.data[index]?.items) < 2
                ? getMainSliderLength(data?.data[index]?.items)
                : 2,
            slidesToScroll:
              getMainSliderLength(data?.data[index]?.items) < 2
                ? getMainSliderLength(data?.data[index]?.items) - 1
                : 2,
            centerPadding: "25px",
          },
        },
        {
          breakpoint: 500,

          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "80px",
          },
        },
        {
          breakpoint: 400,

          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "30px",
          },
        },
      ],
    };
    return sliderSettings;
  };

  useEffect(() => {
    data?.data?.length > 0 &&
      data?.data?.map((item: any) => {
        if (item?.items?.length > 0) {
          setNoData(false);
        }
      });
  }, [data]);

  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  
  return (
    <>
      <ParticlesBackGround />
      <Container
        maxWidth={false}
        sx={{
          maxWidth: {
            mobile: "90%",
            md: "95%",
            xl: "90%",
          },
        }}
      >
        <PageContentContainer>
          {data?.data?.length > 0 &&
            data?.data?.map((item: any) => {
              if (item?.id === 1) {
                if (filterItems(item?.items).length === 0) return null;
                return (
                  <FavoriteSliderContainer
                    icon={
                      <FavoriteIcon
                        rectColor="#BAE4FE"
                        gColor="#81CEFC"
                        borderColor="#278CC9"
                      />
                    }
                  >
                    <MediaSliderSection
                      movies={item?.items}
                      title={
                        i18n.language === "en"
                          ? item?.title.txt
                          : item?.title.txt_ar
                      }
                      subTitle={
                        i18n.language === "en"
                          ? item?.subtitle.txt
                          : item?.subtitle.txt_ar
                      }
                      color="#81CEFC"
                      sliderRef={firstSliderRef}
                      sliderSettings={getSliderSettings(0)}
                    />
                    <SliderArrows
                      sliderRef={firstSliderRef}
                      color="#3ea9e961"
                    />
                  </FavoriteSliderContainer>
                );
              }
              if (item?.id === 2) {
                if (filterItems(item?.items).length === 0) return null;

                return (
                  <FavoriteSliderContainer
                    icon={
                      <FavoriteIcon
                        rectColor="#E8D6FF"
                        gColor="#CEAFF5"
                        borderColor="#8768AC"
                      />
                    }
                  >
                    <MediaSliderSection
                      movies={item?.items}
                      title={
                        i18n.language === "en"
                          ? item?.title.txt
                          : item?.title.txt_ar
                      }
                      subTitle={
                        i18n.language === "en"
                          ? item?.subtitle.txt
                          : item?.subtitle.txt_ar
                      }
                      color="#CEAFF5"
                      sliderRef={secondSliderRef}
                      sliderSettings={getSliderSettings(1)}
                    />
                    <SliderArrows
                      sliderRef={secondSliderRef}
                      color="#e8d6ff4a"
                    />
                  </FavoriteSliderContainer>
                );
              }
              if (item?.id === 3) {
                if (filterItems(item?.items).length === 0) return null;

                return (
                  <FavoriteSliderContainer
                    icon={
                      <FavoriteIcon
                        rectColor="#FFF1D6"
                        gColor="#FEB423"
                        borderColor="#D99A00"
                      />
                    }
                  >
                    <MediaSliderSection
                      movies={item?.items}
                      title={
                        i18n.language === "en"
                          ? item?.title.txt
                          : item?.title.txt_ar
                      }
                      subTitle={
                        i18n.language === "en"
                          ? item?.subtitle.txt
                          : item?.subtitle.txt_ar
                      }
                      color="#FEB423"
                      sliderRef={thirdSliderRef}
                      sliderSettings={getSliderSettings(1)}
                    />
                    <SliderArrows
                      sliderRef={thirdSliderRef}
                      color="#d5992169"
                    />
                  </FavoriteSliderContainer>
                );
              }
              if (item?.id === 4) {
                if (filterItems(item?.items).length === 0) return null;

                return (
                  <FavoriteSliderContainer
                    icon={
                      <FavoriteIcon
                        rectColor="#E8D6FF"
                        gColor="#CEAFF5"
                        borderColor="#8768AC"
                      />
                    }
                  >
                    <MediaSliderSection
                      movies={item?.items}
                      title={
                        i18n.language === "en"
                          ? item?.title.txt
                          : item?.title.txt_ar
                      }
                      subTitle={
                        i18n.language === "en"
                          ? item?.subtitle.txt
                          : item?.subtitle.txt_ar
                      }
                      color="#CEAFF5"
                      sliderRef={thirdSliderRef}
                      sliderSettings={getSliderSettings(2)}
                    />
                    <SliderArrows
                      sliderRef={thirdSliderRef}
                      color="#e8d6ff4a"
                    />
                  </FavoriteSliderContainer>
                );
              }
            })}
          {noData && <NotFound text={t("noFavorite")} />}
        </PageContentContainer>
      </Container>
      <RequestBlock />
    </>
  );
};

export default FavoritesPage;

type FavoriteIconProps = {
  rectColor: string;
  gColor: string;
  borderColor: string;
};

const FavoriteIcon = ({
  rectColor,
  gColor,
  borderColor,
}: FavoriteIconProps) => {
  return (
    <svg
      id="Component_171_1"
      data-name="Component 171 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="95"
      viewBox="0 0 95 95"
    >
      <rect
        id="Rectangle_1176"
        data-name="Rectangle 1176"
        width="95"
        height="95"
        rx="10"
        fill={rectColor}
      />
      <g
        id="Icon_material-favorite"
        data-name="Icon material-favorite"
        transform="translate(17.114 17.873)"
        fill={gColor}
      >
        <path
          d="M 30.38905906677246 53.40425872802734 L 27.08864593505859 50.39973831176758 L 27.05254554748535 50.36700057983398 C 13.17080497741699 37.77910995483398 4.00000524520874 29.46306991577148 4.00000524520874 19.56257820129395 C 4.00000524520874 11.6770191192627 10.17702484130859 5.499999046325684 18.06258583068848 5.499999046325684 C 22.43816566467285 5.499999046325684 26.7606258392334 7.5082688331604 29.62515449523926 10.87211894989014 L 30.38650512695312 11.76617908477783 L 31.14785575866699 10.87211894989014 C 34.01238632202148 7.5082688331604 38.33484649658203 5.499999046325684 42.71043395996094 5.499999046325684 C 50.59599685668945 5.499999046325684 56.77301406860352 11.6770191192627 56.77301406860352 19.56257820129395 C 56.77301406860352 29.44573974609375 47.63108444213867 37.75329971313477 33.79304504394531 50.3283576965332 L 33.68501663208008 50.42652893066406 L 30.38905906677246 53.40425872802734 Z"
          stroke="none"
        />
        <path
          d="M 30.39161109924316 52.05427551269531 L 33.12051391601562 49.58828735351562 C 39.95570373535156 43.37693786621094 45.85878372192383 38.01261901855469 49.8359260559082 33.13764953613281 C 53.94195556640625 28.10469818115234 55.77301406860352 23.91799926757812 55.77301406860352 19.56257820129395 C 55.77301406860352 12.23776912689209 50.03524398803711 6.499999046325684 42.71043395996094 6.499999046325684 C 38.62406539916992 6.499999046325684 34.58622360229492 8.376809120178223 31.90920448303223 11.52045917510986 L 30.38650512695312 13.30858898162842 L 28.86380577087402 11.52045917510986 C 26.1867847442627 8.376809120178223 22.14894485473633 6.499999046325684 18.06258583068848 6.499999046325684 C 10.73777484893799 6.499999046325684 5.00000524520874 12.23776912689209 5.00000524520874 19.56257820129395 C 5.00000524520874 23.92555999755859 6.837255001068115 28.11878967285156 10.95714473724365 33.15879821777344 C 14.94731521606445 38.04011917114258 20.86832427978516 43.40926742553711 27.72428512573242 49.6262092590332 L 27.76182556152344 49.66025924682617 L 30.39161109924316 52.05427551269531 M 30.38650512695312 54.75423812866211 L 26.41546440124512 51.13921737670898 C 12.31141471862793 38.34972763061523 3.00000524520874 29.9146785736084 3.00000524520874 19.56257820129395 C 3.00000524520874 11.12753868103027 9.627534866333008 4.499999046325684 18.06258583068848 4.499999046325684 C 22.82783508300781 4.499999046325684 27.40137481689453 6.718308925628662 30.38650512695312 10.22377872467041 C 33.37163543701172 6.718308925628662 37.94518661499023 4.499999046325684 42.71043395996094 4.499999046325684 C 51.14547348022461 4.499999046325684 57.77301406860352 11.12753868103027 57.77301406860352 19.56257820129395 C 57.77301406860352 29.9146785736084 48.46160507202148 38.34972763061523 34.3575439453125 51.16659927368164 L 30.38650512695312 54.75423812866211 Z"
          stroke="none"
          fill={borderColor}
        />
      </g>
    </svg>
  );
};
