import { Box } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../Assets/Images/main-logo.svg";
import SearchContainer from "../../../Blocks/SearchContainer/SearchContainer";
import Favorite from "../Controls/Favorite";
import Language from "../Controls/Language";
import DrawerNavItem from "./DrawerNavItem";
import useGetData from "../../../../Hooks/useGetData";

type Props = {
  menus: any;
};

const DrawerContent = ({ menus }: Props) => {
  const navigate = useNavigate();
  const [openedList, setOpenedList] = useState<number | null>(null);
  const { data } = useGetData("settings/all", []);
  const updatedLogo = data && data?.settings?.logo;
  return (
    <Box
      sx={{
        padding: "1rem 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Box
          component="img"
          src={data && updatedLogo}
          alt="logo"
          onClick={() => navigate("/")}
          sx={{
            cursor: "pointer",
            // maxWidth: "120px",
            // OObjectFit: "fill",
            width: {
              mobile: "140px",
            },
            height: {
              mobile: "55px",
            },
            objectFit: "contain",
          }}
          style={{objectFit: "fill"}}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "0 1rem",
            gap: "1rem",
          }}
        >
          <SearchContainer />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
          }}
        >
          {menus.map((menu: any) => (
            <DrawerNavItem
              openedList={openedList}
              setOpenedList={setOpenedList}
              key={menu.id}
              menu={menu}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "4rem",
          }}
        >
          <Favorite />
          <Language />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(DrawerContent);
