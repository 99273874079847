import { Box, Tooltip, styled, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import { getSmallerText } from "../../../Helpers/Functions/getSmallerText";

type Props = {
  id: number;
  title: string;
  selectedFilters: number[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<number[]>>;
};

const StyledBox = styled(Box)({
  borderRadius: "12px",
  position: "relative",
  overflow: "hidden",
  backgroundColor: "#ffffff29",
  padding: "1rem 2rem",
  minWidth: "130px",

  // maxWidth: "200px",
  width: "fit=content",
  textAlign: "center",
  gap: ".5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  transition: "all .3s ease",
  "& h5": {
    fontSize: "1.5rem",
    color: "white",
    fontWeight: "400",
  },

  "@media (max-width: 1024px)": {
    maxWidth: "180px",
    padding: ".75rem",
    "& h5": {
      fontSize: "1.25rem",
    },
  },
  "@media (max-width: 768px)": {
    maxWidth: "150px",
    "& h5": {
      fontSize: "1rem",
    },
  },

  "& svg": {
    position: "absolute",
    top: "50%",
    left: "8px",
    transform: "translateY(-50%) !important",
  },
});

const FilterItem = ({
  id,
  title,
  selectedFilters,
  setSelectedFilters,
}: Props) => {
  const handleFilter = () => {
    if (selectedFilters) {
      if (selectedFilters.includes(id)) {
        setSelectedFilters(selectedFilters.filter((item) => item !== id));
      } else {
        setSelectedFilters([...selectedFilters, id]);
      }
    }
  };
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <StyledBox
      onClick={handleFilter}
      sx={{
        backgroundColor: selectedFilters.includes(id) ? "#81CEFC" : "#ffffff29",
      }}
    >
      {selectedFilters.includes(id) && (
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.636"
          height="17.636"
          viewBox="0 0 17.636 17.636"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
        >
          <motion.path
            id="Icon_awesome-check-circle"
            data-name="Icon awesome-check-circle"
            d="M18.2,9.381A8.818,8.818,0,1,1,9.381.563,8.818,8.818,0,0,1,18.2,9.381ZM8.361,14.05,14.9,7.507a.569.569,0,0,0,0-.8l-.8-.8a.569.569,0,0,0-.8,0L7.958,11.234,5.467,8.743a.569.569,0,0,0-.8,0l-.8.8a.569.569,0,0,0,0,.8l3.7,3.7a.569.569,0,0,0,.8,0Z"
            transform="translate(-0.563 -0.563)"
            fill="#fff"
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={{ pathLength: 1, pathOffset: 0 }}
            exit={{ pathLength: 0, pathOffset: 1 }}
            transition={{ duration: 0.5 }}
          />
        </motion.svg>
      )}

      <h5> {title}</h5>
    </StyledBox>
  );
};

export default FilterItem;
