import queryString from "query-string";
import { useMemo } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import useGetData from "../../Hooks/useGetData";

type Props = {};

const SubmitOrder = (props: Props) => {
  const location = useLocation();
  const { token } = useParams<string>();
  const parsedLocation = queryString.parse(location.search);
  const resultString = Object.entries(parsedLocation).map(([key, value]) => `${key}=${value}`).join('&')
  // console.log(parsedLocation);
  const apiUrl = useMemo(
    () =>
      // `up_response/${parsedLocation?.token}?paymentId=${parsedLocation?.paymentId}`,
      // `up_response/${parsedLocation?.token}`,
      `up_response/${token}?${resultString}`,
    []
  );
  const { data, loading } = useGetData(apiUrl, []);
  // console.log('test0', data);
  
  if (loading) return <MainLoader />;
  if (data?.IsSuccess === "true") {
    return <Navigate to="/purchases" />;
  } else {
    return <Navigate to="/404" />;
  }
};

export default SubmitOrder;
