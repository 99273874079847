import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import NotFound from "../../Components/Blocks/NotFound/NotFound";
import Offer from "../../Components/Blocks/Offer/Offer";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import useGetData from "../../Hooks/useGetData";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import CustomPagination from "../../Components/Blocks/CustomPagination/CustomPagination";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";

const OffersPage = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { data, loading, error } = useGetData(
    `offers/list/10?page=${query.page}`,
    [query.page]
  );
  const { t, i18n } = useTranslation();

  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  return (
    <>
      <ParticlesBackGround />
      <MainContainer>
        <PageContentContainer>
          <SectionHead
            color="#E8D6FF"
            title={
              i18n.language === "en" ? data?.title.txt : data?.title?.txt_ar
            }
            subTitle={
              i18n.language === "en"
                ? data?.subtitle.txt
                : data?.subtitle?.txt_ar
            }
          />
          <Stack spacing={7}>
            {data?.offers?.data.length > 0 ? (
              data?.offers?.data.map((offer: any) => (
                <Offer {...offer} key={offer.id} />
              ))
            ) : (
              <NotFound text={t("offersPage.noOffers")} />
            )}
            {data?.offers?.last_page !== 1 && (
              <CustomPagination
                totalPages={data?.offers?.last_page}
                from={data?.offers?.from}
                to={data?.offers?.to}
                total={data?.offers?.total}
              />
            )}
          </Stack>
          <RequestBlock />
        </PageContentContainer>
      </MainContainer>
    </>
  );
};

export default OffersPage;
