import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
interface props {
  statusText: string;
  color: string;
}
const OrderStatus = ({ statusText, color }: props) => {
  return (
    <Box
      sx={{
        width: { xs: "70px", sm: "85px", md: "100px", lg: "121px" },
        height: "47px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        // bgcolor:
        //   statusNumber == 1
        //     ? "#E69D0B"
        //     : statusNumber == 2
        //     ? "#70C570"
        //     : statusNumber == 3
        //     ? "#A47FD1"
        //     : "#D5D5D5",
        backgroundColor: color,
      }}
    >
      <Typography
        sx={{
          color: "#FFFFFF",
          fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
        }}
      >
        {statusText}
      </Typography>
    </Box>
  );
};

export default OrderStatus;
