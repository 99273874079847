import { Box, IconButton } from "@mui/material";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

type Props = {
  sliderRef: any;
  color: string;
};

const SliderArrows = ({ sliderRef, color }: Props) => {
  const btnStyles = {
    borderRadius: "4px",
    backgroundColor: color,
    width: "46px",
    height: "40px",
    padding: "4px",
    fontSize: "1.5rem",
    "& svg": {
      color: "#ffffff24",
      fontSize: "3rem",
    },
    "&:hover": {
      backgroundColor: color,
    },
  };
  return (
    <Box
      sx={{
        display: {
          mobile: "none",
          sm: "flex",
        },
        flexDirection: "column",
        position: "absolute",
        left: ".4rem",
        top: "50%",

        transform: "translateY(-50%)",
        gap: "1rem",
      }}
    >
      <IconButton
        onClick={() => sliderRef?.current?.slickNext()}
        sx={btnStyles}
      >
        <MdOutlineKeyboardArrowUp />
      </IconButton>
      <IconButton
        onClick={() => sliderRef?.current?.slickPrev()}
        sx={btnStyles}
      >
        <MdOutlineKeyboardArrowDown />
      </IconButton>
    </Box>
  );
};

export default SliderArrows;
