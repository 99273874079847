import axios from "axios";
const BaseUrl = process.env.REACT_APP_API_ENDPOINT;

const AxiosInstance = axios.create({
  baseURL: BaseUrl,
  // headers: {
  //   "Content-Type": "multipart/form-data",
  //   Accept: "application/json",
  //   // Authorization: `Bearer ${getCookie("token")}`,
  // },
});

export const getData = async (url: string) => {
  const response = await axios.get(`${BaseUrl}/${url}`);

  if (response !== undefined) if (response.status === 200) return response;
};

export const getNotification = async (url: string, page?: number) => {
  const response = await axios.get(`${BaseUrl}/${url}?page=${page}`)
  if (response !== undefined) if (response.status === 200) return response;
}

export const getNotificationRead = async (url: string, id: string) => {
  const response = await axios.get(`${BaseUrl}/${url}/${id}`)
  if (response !== undefined) if (response.status === 200) return response;
}

export const getLocation = async (url: string, user_location: any) => {
  const response = await axios.get(`${BaseUrl}/${url}?user_location=${user_location}`)
  if (response !== undefined) if (response.status === 200) return response;
}

export const getDataById = async (url: string, id: number | string) => {
  const response = await AxiosInstance.get(`${url}/${id}`);
  if (response !== undefined) if (response.status === 200) return response.data;
};

export async function createData(url: string, formData: any) {
  const response = await axios.post(`${BaseUrl}/${url}`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      Accept: "application/json",
      // Authorization: `Bearer ${getCookie("token")}`,
    },
  });
  return response;
}

export const UnsubscripeNewsLetter = async (url: string, body: {email: string, token: string} ) => {
  const response = await axios.post(`${BaseUrl}/${url}`, body, {
    headers: {
      "content-type": "multipart/form-data",
      Accept: "application/json",
      // Authorization: `Bearer ${getCookie("token")}`,
    },
  });
  return response;
}

export const editData = async (
  url: string,
  id: string | number,
  formData: any
) => {
  const response = await AxiosInstance.put(`${url}/${id}`, formData);
  if (response !== undefined) if (response.status === 200) return response.data;
};

export const postWithoutData = async (url: string) => {
  const response = await axios.post(`${BaseUrl}/${url}`);
  return response;
};

