import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import useGetData from "../../../Hooks/useGetData";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import ProfileForm from "../Forms/ProfileForm";
import MainLoader from "../MainLoader/MainLoader";
import SectionHead from "../SectionHead/SectionHead";
const ProfilePersonal = () => {
  // Language handler
  const { t } = useTranslation();
  //   Get personal data
  const { data: profile, error, loading } = useGetData("profile/view", []);
  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: {
          xs: "30px",
        },
      }}
    >
      <SectionHead
        color="#CEAFF5"
        title={t("profile.title")}
        subTitle={t("profile.subTitle")}
      />
      {profile?.user && <ProfileForm data={profile?.user} />}
    </Box>
  );
};

export default ProfilePersonal;
