import { createSlice } from "@reduxjs/toolkit";
import { currency } from "../Types/Types";

interface initialStateType {
  currencies: currency[];
}

const initialState: initialStateType = {
  currencies: [],
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setCurrencies(state, action) {
      state.currencies = action.payload;
    },
  },
});

export const mainCurrencyActions = currencySlice.actions;
export default currencySlice;
