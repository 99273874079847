import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export interface singleOrderState {
  expandedItem: string;
}

const initialState: singleOrderState = {
  expandedItem: "",
};

const singleOrderSlice = createSlice({
  name: "singleOrder",
  initialState,
  reducers: {
    setExpandedItem: (state, action) => {
      state.expandedItem = action.payload;
    },
  },
});

export const singleOrderActions = singleOrderSlice.actions;
export default singleOrderSlice;
