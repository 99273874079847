import { styled } from "@mui/material";

const MenuContainer = styled("div")({
  padding: "20px 0",
  zIndex: "5",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  // boxShadow: "0px 3px 30px #00000080",
  borderRadius: "10px",
  position: "absolute",
  top: "60px",
  minWidth: "120px",
  backgroundColor: "#fff",
  maxHeight: "180px",
  overflowY: "auto",
  // paddingTop: "30px",
  "&:before": {
    content: "''",
    // boxShadow: "0px 3px 30px #00000080",
    borderRadius: "10px",
    position: "absolute",
    top: "-20px",
    left: "50%",
    width: "100%",
    transform: "translateX(-50%)",
    // height: "100vh",
    // backgroundColor: "red",
  },
  "&::-webkit-scrollbar": {
    width: "1px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: "20px",
  },

  "& .MuiTypography-root": {
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "400",
    color: "#8768AC",
    position: "relative",
    textAlign: "left",

    "&:after": {
      content: "''",
      position: "absolute",
      bottom: "-5px",
      left: "50%",
      width: "90%",
      transform: "translateX(-50%)",
      height: "1px",
      backgroundColor: "#7427c924",
    },
    
  },
  "@media (max-width: 1650px)": {
    "&.menu": {
      top: "60px",
    },
  },
  "@media (max-width: 1440px)": {
    "&.menu": {
      top: "60px",
    },
  },
  "@media (max-width: 1280px)": {
    "&.menu": {
      top: "45px",
    },
  },
});

export const defaultMenu = styled(MenuContainer)({
  backgroundColor: "#fff"
})

export default MenuContainer;
