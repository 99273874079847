import { createSlice } from "@reduxjs/toolkit";
import { Price } from "../Types/Types";

export type ModalState = {
  open: boolean;
  movieId?: string;
  type?: "movie" | "season" | "series" | "episode";
  price?: Price;
  is_available?: boolean;
};

const initialState: ModalState = {
  open: false,
  movieId: undefined,
  type: undefined,
  price: undefined,
  is_available: true,
};

const addToCartSlice = createSlice({
  name: "addToCartModal",
  initialState,
  reducers: {
    openAddToCartModal: (state, action) => {
      state.open = true;
      state.movieId = action.payload.movieId;
      state.type = action.payload.type;
      state.price = action.payload.price;
      state.is_available = action.payload.is_available;
    },
    closeAddToCartModal: (state) => {
      state.open = false;
      state.movieId = undefined;
      state.type = undefined;
      state.price = undefined;
      state.is_available = true;
    },
  },
});

export const addToCartModalActions = addToCartSlice.actions;
export default addToCartSlice;
