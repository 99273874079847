import { Box, Button, Stack, Typography, styled } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {};

const StyledRequestContainer = styled("div")({
  borderRadius: "20px",
  boxShadow: "5px 3px 20px #00000029",
  backgroundImage: "linear-gradient(180deg, #755798 0%, #3B2C4C 100%)",
  width: "100%",
  maxWidth: "940px",
  padding: "80px 20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "3rem",
  textAlign: "center",
  "@media (max-width: 1440px)": {
    padding: "50px 20px",
    gap: "2rem",
    maxWidth: "630px",
  },

  "@media (max-width: 768px)": {
    padding: "30px 10px",
    gap: "1.5rem",
    maxWidth: "400px",
  },
});
const StyledContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  margin: "70px 0",
  "@media (max-width: 1440px)": {
    margin: "40px 0",
  },
  "@media (max-width: 1280px)": {
    margin: "30px 0",
  },
  // "@media (max-width: 1024px)": {
  //   margin: "20px 0",
  // },
  "@media (max-width: 768px)": {
    margin: "20px 0",
  },
});

const RequestBlock = (props: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleNavigateToRequest = () => {
    navigate("/request");
  };

  return (
    <Box
      component={motion.section}
      initial={{
        scale: 0.2,
        opacity: 0,
      }}
      whileInView={{
        scale: 1,
        opacity: 1,
      }}
      transition={{
        duration: 0.7,
      }}
      viewport={{
        once: true,
      }}
    >
      <StyledContainer>
        <StyledRequestContainer>
          <Stack>
            <Typography
              variant="h3"
              sx={{
                textAlign: "center",
                color: "#E8D6FF",
                fontSize: {
                  mobile: "2rem",
                  md: "2.5rem",
                  xl: "3rem",
                },
                fontWeight: "700",
              }}
            >
              {t("requestBlock.title")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                color: "white",
                fontSize: {
                  mobile: "1.25rem",
                  md: "1.5rem",
                  xl: "1.75rem",
                },
              }}
            >
              {t("requestBlock.subTitle")}
            </Typography>
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              backgroundColor: "#3EA9E9",
              boxShadow: "0px 3px 22px #0000002E",
              width: { mobile: "180px", xl: "260px" },
              height: {
                mobile: "50px",
                xl: "70px",
              },
              borderRadius: "12px",
              fontSize: {
                mobile: "1rem",
                xl: "1.5rem",
              },
              color: "white",
            }}
            onClick={handleNavigateToRequest}
          >
            {t("requestBlock.order")}
          </Button>
        </StyledRequestContainer>
      </StyledContainer>
    </Box>
  );
};

export default RequestBlock;
