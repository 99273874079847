import { useEffect, useState } from "react";
import { getData } from "../Services/DataServices";
import { category } from "../Types/Types";
import { useDispatch } from "react-redux";
import { mainFilterActions } from "../store/filter-slice";
import Cookies from "universal-cookie";

// Define a custom hook with a generic type parameter
function useGetSettings() {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const filterCategories = (categories: category[]) => {
    let filteredCategory: category[] = [];
    categories.forEach((category: category) => {
      filteredCategory.push(category);
    });
    return filteredCategory;
  };
  const getCategories = async () => {
    try {
      await getData("categories/list").then((response) => {
        if (response !== undefined) {
          if (response.status === 200) {
            setData(response.data);
            dispatch(
              mainFilterActions.setArabicFilters(
                filterCategories(response.data?.genres[1]?.subs)
              )
            );
            dispatch(
              mainFilterActions.setEnglishFilters(
                filterCategories(response.data?.genres[0]?.subs)
              )
            );
          }
        }
      });
    } catch (err) {
      setError("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return { data, loading, error, filterCategories };
}

export default useGetSettings;
