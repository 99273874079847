import { Modal, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { trailerActions } from "../../store/trailer-slice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  height: "450px",
  "& iframe": {
    borderRadius: "20px",
  },
  "@media (max-width: 600px)": {
    height: "300px",
    maxWidth: "400px",
  },
  "@media (max-width: 350px)": {
    height: "200px",
    maxWidth: "250px",
  },
};

const TrailerModal = () => {
  const trailerState = useSelector((state: any) => state.trailer);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(trailerActions.closeModal());
  };
  //autoplay video

  return (
    <Modal open={trailerState.open} onClose={handleClose}>
      <Box style={style}>
        <iframe
          style={{ borderRadius: "20px" }}
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${trailerState?.url}?autoplay=1&showinfo=0`}
          title="YouTube video player"
          allowFullScreen
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;allowfullscreen;autoplay;"
        ></iframe>
      </Box>
    </Modal>
  );
};

export default TrailerModal;
