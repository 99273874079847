import { configureStore } from "@reduxjs/toolkit";
import filterSlice from "./filter-slice";
import authSlice from "./auth-slice";
import refreshApiSlice from "./refresh-slice";
import currencySlice from "./currency-slice";
import addToCartSlice from "./addToCart-slice";
import refreshAddressSlice from "./refreshAddress-slice";
import cartSlice from "./cart-slice";
import trailerSlice from "./trailer-slice";
import singleOrderSlice from "./singleOrder-slice";
import verificationSlice from "./verification-slice";
import paymentSlice from "./payment-slice";

const store = configureStore({
  reducer: {
    trailer: trailerSlice.reducer,
    filter: filterSlice.reducer,
    auth: authSlice.reducer,
    refreshApi: refreshApiSlice.reducer,
    currency: currencySlice.reducer,
    addToCart: addToCartSlice.reducer,
    refreshAddress: refreshAddressSlice.reducer,
    cart: cartSlice.reducer,
    singleOrder: singleOrderSlice.reducer,
    verification: verificationSlice.reducer,
    payment: paymentSlice.reducer,
  },
});

export default store;
