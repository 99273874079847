import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { BsWhatsapp } from "react-icons/bs";

const ConnectViaWhats = () => {
  const phoneNumber = "1234567890";
  const description = "Hi, I want to change my password, please.";

  return (
    <>
      <WhatsAppLinkConnect
        phoneNumber={phoneNumber}
        description={description}
      />
    </>
  );
};

export default ConnectViaWhats;

const WhatsAppLinkConnect = ({ phoneNumber, description }) => {
  const handleWhatsAppClick = () => {
    // Construct the default WhatsApp message with the title and description
    const defaultMessage = `${description}`;

    // Encode the default message for the WhatsApp API URL
    const encodedMessage = encodeURIComponent(defaultMessage);

    // Construct the WhatsApp API URL with the phone number and default message
    const whatsappApiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

    // Open the WhatsApp API URL in a new tab/window
    window.open(whatsappApiUrl, "_blank");
  };
  const { t } = useTranslation();

  return (
    <>
      <Box component="a" href="#" onClick={handleWhatsAppClick}>
        <BsWhatsapp /> {t("whats")}
      </Box>
    </>
  );
};
