import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import SearchComponent from "../SearchComponent/SearchComponent";

type Props = {};

const SearchContainer = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <SearchComponent />{" "}
      <Button
        onClick={() => navigate("/advanced-search")}
        sx={{
          color: "white",
          fontSize: "1.25rem",
          fontWeight: "700",
          position: "relative",
          "&::before": {
            display: {
              mobile: "none",
              md: "block",
            },
            content: "''",
            position: "absolute",
            left: "-4px",
            height: "50%",
            width: "1px",
            backgroundColor: "#ffffff54",
          },
        }}
      >
        {t("advancedSearch")}{" "}
      </Button>
    </>
  );
};

export default SearchContainer;
