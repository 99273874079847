import Cookies from "universal-cookie";
import { postWithoutData } from "../../Services/DataServices";
import { displayToastErrorApi } from "../toastHelper";

const cookies = new Cookies();

export const handleLogout = async () => {
  try {
    await postWithoutData("user/logout").then((res) => {
      if (res.status === 200) {
        let allCookies = cookies.getAll();
        for (let key in allCookies) {
          cookies.remove(key);
        }
      }
    });
  } catch (error) {
    displayToastErrorApi("حدث خطأ ما");
  }
};
