import React from "react";
import "../AuthTextField/AuthTextField.scss";
import { useTranslation } from "react-i18next";
import { MuiTelInput } from "mui-tel-input";
import { Controller } from "react-hook-form";

interface Props {
  type?: string;
  placeholder?: string;
  register?: any;
  error?: any;
  touched?: any;
  name: string;
  control?: any;
}

const AuthTextField = ({
  type,
  placeholder,
  register,
  error,
  touched,
  name,
  control,
}: Props) => {
  const { i18n } = useTranslation();
  return (
    <div className="auth-text-field-container">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <MuiTelInput
            {...field}
            placeholder={placeholder}
            sx={{
              "& img": {
                width: "40px",
              },
              direction: "rtl",
              backgroundColor: {
                xs: "rgb(129 206 252 / 30%)",
                sm: "#ceaff542",
              },
              width: "100%",
              display: "flex",
              justifyContent: "center",
              borderRadius: {
                xs: "6px",
                sm: "12px",
              },
              height: {
                xs: "60px",
                md: "70px",
                lg: "80px",
              },
              maxWidth: "550px",
              fontSize: "1.5rem",
              color: {
                xs: "white",
                sm: "#755798",
              },

              "& button": {
                marginTop: "0px",
              },
              "& .Mui-focused": {
                outline: "none",
              },
              "& .MuiOutlinedInput-root": {
                height: "100%",
                border: "1px solid transparent",
                padding: "0 2rem",
                "&:hover": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                },
                "&.Mui-focused": {
                  "& fieldset": {
                    borderColor: "primary.main",
                    borderWidth: "1px",
                    boxShadow: " 0 0 10px #75579870",
                  },
                },
                "& input": {
                  textAlign: "end",
                  color: {
                    xs: "white",
                    sm: "#755798",
                  },
                  fontSize: "1.5rem",
                },
              },
              "& fieldset": {
                borderColor: "transparent",
                borderRadius: {
                  xs: "6px",
                  sm: "12px",
                },
              },
            }}
          />
        )}
      />

      {error && touched && (
        <p className={`${i18n.dir()} error`}>{error.message}</p>
      )}
    </div>
  );
};

export default React.memo(AuthTextField);
