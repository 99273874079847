import { Box, Typography, styled } from "@mui/material";

// Create a styled version of the Box component
export const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "265px",
  height: "305px",
  borderRadius: "10px",
  border: `2px solid ${theme.palette.primary.main}`, // You can use the theme here
  background: theme.palette.primary.light, // You can use the theme here
  "@media (max-width: 1024px)": {
    width: "220px",
    height: "260px",
  },
  "@media (max-width: 768px)": {
    width: "200px",
    height: "230px",
  },
}));

// Create a styled version of the img component
export const StyledImage = styled("img")({
  width: "100%",
  height: "235px",
  objectFit: "cover",
  borderRadius: "10px 10px 0 0",
  borderBottom: "2px solid #E8D6FF", // You can customize this color
  "@media (max-width: 1024px)": {
    height: "200px",
  },
  "@media (max-width: 768px)": {
    height: "180px",
  },
});

export const StyledOverlay = styled("div")({
  backgroundColor: "#00000059",
  position: "absolute",
  top: "0",
  height: "235px",
  borderRadius: "10px 10px 0 0",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  opacity: "0",
  zIndex: "2",
  transition: "all .3s ease-in",
  "@media (max-width: 1024px)": {
    height: "200px",
  },
  "@media (max-width: 768px)": {
    height: "150px",
  },
});

// Create a styled version of the Typography component
export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  cursor: "pointer",
  fontWeight: 500,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "70px",
  transition: "all .3s ease-in",
  textAlign: "center",
  color: theme.palette.primary.main,
  "@media (max-width: 1024px)": {
    height: "50px",
  },
  "@media (max-width: 768px)": {
    // height: "40px",
    fontSize: "1rem",
  },
}));
