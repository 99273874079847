import { Box, ClickAwayListener, Typography } from "@mui/material";
import queryString from "query-string";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import MenuContainer from "../../StyledComponents/MenuContainer/StyledMenuContainer";
import { StyledSecondaryButton } from "../../StyledComponents/StyledButton/StyledButton";
import DefaultMenu from "../../StyledComponents/MenuContainer/DefaultMenu";
type Props = {};
type IFormInput = { sort_by: string };
type Menu = {
  id: number;
  title: {
    ar: string;
    en: string;
  };
};
const menu: Menu[] = [
  {
    id: 11,

    title: {
      ar: "من الأقدم الي الأحدث",
      en: "From Older to Newer",
    },
  },
  {
    id: 12,

    title: {
      ar: "من الأحدث الي الأقدم",
      en: "From Newer to Older",
    },
  },
  {
    id: 22,

    title: {
      ar: "الأعلي تقييما الي الأقل",
      en: "From Higher Ranking to Lower",
    },
  },
  {
    id: 21,
    title: {
      ar: "الأقل تقييما الي الأعلي",
      en: "From Lower Ranking to Higher",
    },
  },
];

const SortButton = (props: Props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  // Handle Language
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      sort_by: "",
    },
  });
  // Send Api Request to sort
  const location = useLocation();
  const path = location.pathname.substring(1);
  const navigate = useNavigate();
  const parsedQuery = queryString.parse(location?.search);

  const onSubmit: SubmitHandler<IFormInput> = async ({ sort_by }) => {
    const newQuery = queryString.stringify({
      ...parsedQuery,
      page: 1,
      sort_by: sort_by,
    });
    navigate(`/${path}?${newQuery}`);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <StyledSecondaryButton
          onClick={() => {
            setShowMenu(!showMenu);
          }}
          variant="contained"
          sx={{
            backgroundColor: "#CEAFF5",
            "& svg": {
              color: "white",
            },
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
        >
          {t("sort")}
        </StyledSecondaryButton>

        {showMenu && (
          <DefaultMenu
            sx={{
              width: "200px",
              top: "50px",
              padding: ".5rem 1rem",
              right: "50%",
              transform: "translateX(50%)",
            }}
          >
            {menu.map((item: Menu, index: number) => {
              return (
                <Controller
                  key={item.id}
                  name="sort_by"
                  control={control}
                  render={({ field, fieldState: { error, isTouched } }) => (
                    <Typography
                      onClick={() => {
                        field.onChange(item.id);
                        handleSubmit(onSubmit)();
                      }}
                      key={item.id}
                      sx={{
                        padding: ".1rem .5rem",
                        borderRadius: "6px",
                        transition: "all .3s ease-in-out",
                        "&:hover": {
                          backgroundColor: "#8768ac30",
                        },
                        "&::after": {
                          display: index === menu.length - 1 ? "none" : "block",
                        },
                      }}
                    >
                      {language === "ar" ? item.title.ar : item.title.en}
                    </Typography>
                  )}
                />
              );
            })}
          </DefaultMenu>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SortButton;
