import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import useRegisterFormHelper from "../../../Helpers/AuthFunctions/useRegisterFormHelper";
import StyledButton from "../../StyledComponents/StyledButton/StyledButton";
import MainTextField from "../MainTextField/MainTextField";
import PhoneInputt from "../PhoneInput/PhoneInput";

const ProfileForm = ({ data }: any) => {
  const { RegisterFromHelper } = useRegisterFormHelper();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // Language Handler
  const { t } = useTranslation();
  const { name, email, mobile, username, client_note } = data;

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("validation.required")),
    email: yup
      .string()
      .required(t("validation.required"))
      .email(t("validation.email")),
    mobile: yup
      .string()
      .required(t("validation.required"))
      // .test("len", t("validation.mobile"), (val) => {
      //   val = val?.replace(/\s/g, "");
      //   return val?.trim().length > 8;
      // })
      // .test("isValid", (val) => {
      //   return isValidPhoneNumber(val);
      // })
      ,
    username: yup.string().required(t("validation.required")),

    client_note: yup.string().notRequired(),
  });
  const {
    handleSubmit,
    register,
    setError,
    reset,
    control,

    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name,
      email,
      mobile,
      username,
      client_note,
    },
  });
  // fetch default values from API
  const handleProfileFormSubmit = async (data: any, setError: any) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    
    RegisterFromHelper(data, "profile/update", setError, setLoading);
    console.log('testFormData', formData);
  };
  console.log('testData', data);
  return (
    <>
      <Stack
        component={"form"}
        spacing={2}
        sx={{
          opacity: "0.75",
        }}
        onChange={() => {
          setSubmitting(true);
        }}
      >
        <Typography
          sx={{
            margin: 0,
            padding: 0,
            fontSize: "1.4rem",
            color: "#fff"
          }}
        >{t("profile.profileForm.name")}</Typography>
        <MainTextField
          background="#CEAFF542"
          color="#ECECEC"
          name="name"
          control={control}
          
        />
        <Typography
          sx={{
            margin: "10px 0 0 0 !important",
            padding: 0,
            fontSize: "1.4rem",
            color: "#fff"
          }}
        >{t("profile.profileForm.email")}</Typography>
        <MainTextField
          background="#CEAFF542"
          color="#ECECEC"
          name="email"
          control={control}
        />
        <Typography
          sx={{
            margin: "10px 0 0 0 !important",
            padding: 0,
            fontSize: "1.4rem",
            color: "#fff"
          }}
        >{t("profile.profileForm.mobile")}</Typography>
        <PhoneInputt
          control={control}
          name="mobile"
        />
        <Typography
          sx={{
            margin: "10px 0 0 0 !important",
            padding: 0,
            fontSize: "1.4rem",
            color: "#fff"
          }}
        >{t("profile.profileForm.userName")}</Typography>
        <MainTextField
          background="#CEAFF542"
          color="#ECECEC"
          name="username"
          control={control}
        />
        <Typography
          sx={{
            margin: "10px 0 0 0 !important",
            padding: 0,
            fontSize: "1.4rem",
            color: "#fff"
          }}
        >{t("profile.profileForm.password")}</Typography>
        <MainTextField
          background="#CEAFF542"
          color="#ECECEC"
          name="password"
          control={control}
          type="password"
          placeholder="password"
        />
        <Typography
          sx={{
            margin: "10px 0 0 0 !important",
            padding: 0,
            fontSize: "1.4rem",
            color: "#fff"
          }}
        >{t("profile.profileForm.client_note")}</Typography>
        <MainTextField
          background="#CEAFF542"
          color="#ECECEC"
          name="client_note"
          control={control}
          type="text"
        />
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignSelf: "flex-end",
        }}
      >
        <StyledButton
          sx={{
            backgroundColor: "#A47FD1",
            width: "180px",
          }}
          variant="contained"
          disabled={loading}
          endIcon={loading && <CircularProgress size={20} />}
          onClick={handleSubmit(handleProfileFormSubmit)}
        >
          {t("profile.profileForm.save")}
        </StyledButton>
      </Stack>
    </>
  );
};

export default ProfileForm;
