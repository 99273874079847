// Helper function to get the correct property based on the language
const getLocalizedProperty = (
  property: string,
  data: any,
  language: string
) => {
  if (!data || typeof data !== "object") {
    return "";
  }

  if (language === "en") {
    return data[property] || data[`${property}_ar`] || "";
  } else {
    if (!data[`${property}_ar`] && !data[property]) {
      return "";
    }

    return data[`${property}_ar`] || data[property] || "";
  }
};

export default getLocalizedProperty;
