import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./MainSlider.scss";
import SliderNav from "./SliderNav";
// import SliderNav from "../../Layout/Header/SliderNav";

type Props = {
  activeSlide: number;
  settings: any;
  data: any;
};
const MainSlider = ({ activeSlide, settings, data }: Props) => {
  const { t, i18n } = useTranslation();
  const sliderRef = useRef();

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0",
        left: "0",
      }}
    >
      <SliderNav activeSlide={activeSlide} sliderRef={sliderRef} data={data} />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          "& .slick-arrow": {
            display: "none !important",
          },
          "& [dir='rtl']": {
            "& .slick-slide": {
              float: "right",
            },
          },
        }}
      >
        <Slider {...settings} ref={sliderRef}>
          {data?.length > 0 &&
            data?.map((item: any) => {
              return (
                <div className="slider-item" key={item.id}>
                  <div className="header-overlay"></div>
                  <div
                    className="slider-item-single"
                    style={{
                      height: "155vh",
                      minHeight: "1200px",
                      maxHeight: "1600px",
                      backgroundImage: `url(${item?.image})`,
                    }}
                  >
                    <div className="container">
                      <div className="flex">
                        <div className="slider-caption">
                          <h4 className="caption-category">
                            {i18n.language === "ar"
                              ? item?.title_ar
                              : item?.title}
                          </h4>
                          <p >
                            {" "}
                            {i18n.language === "ar"
                              ? item?.sub_title_ar
                              : item?.sub_title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </Box>
    </Box>
  );
};

export default React.memo(MainSlider);
