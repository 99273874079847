import { createTheme } from "@mui/material/styles";

export const ltrTheme = createTheme({
  direction: "ltr",
  typography: {
    fontFamily: `"Cairo",sans-serif`,

    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 800,
    body2: {
      fontSize: 16,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          fontWeight: 400,
          textTransform: "none",
        },
      },
    },
  },

  palette: {
    primary: {
      light: "#bb84ff",
      main: "#755798",
    },
    secondary: {
      light: "#81cefc",
      main: "#3db9ec",
    },
    warning: {
      main: "#FFAD0A",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      xs: 320,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1440,
      xxl: 1650,
    },
  },
});
export const rtlTheme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: `"Cairo",sans-serif`,

    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 800,
    body2: {
      fontSize: 16,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          fontWeight: 400,
          textTransform: "none",
        },
      },
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      xs: 320,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1440,
      xxl: 1650,
    },
  },
  palette: {
    primary: {
      light: "#bb84ff",
      main: "#755798",
    },
    secondary: {
      light: "#81cefc",
      main: "#3db9ec",
    },
    warning: {
      main: "#FFAD0A",
    },
  },
});
