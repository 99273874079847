import React from "react";
import { Button } from "@mui/material";

interface props {
  bgcolor?: any;
  btnText: string;
  width?: any;
  borderRadius?: any;
  onClick?: any;
  boxShadow?: string;
}

const PaymentButton = ({
  bgcolor = "rgba(249, 245, 255, 1)",
  btnText,
  width = {
    xs: "70px",
    sm: "100px",
    md: "140px",
    lg: "155px",
    xl: "210px",
    xxl: "235px",
  },
  boxShadow,
  onClick,
  borderRadius = 0,
}: props) => {
  return (
    <Button
      variant="contained"
      sx={{
        width,
        bgcolor,
        boxShadow: boxShadow,
        fontSize: {
          mobile: "16px",
          md: "22px",
          lg: "26px",
          xl: "30px",
        },
        borderRadius,
        textTransform: "none",
        color: "rgba(117, 87, 152, 1)",
        ":hover": {
          bgcolor: "rgba(232, 214, 255, 1)",
        },
      }}
      onClick={onClick}
      disableElevation
    >
      {btnText}
    </Button>
  );
};

export default PaymentButton;
