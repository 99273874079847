import { useState } from "react";
import {
  displayToastErrorApi,
  displayToastSuccess,
} from "../Helpers/toastHelper";
import { createData } from "../Services/DataServices";
import { useDispatch } from "react-redux";
import { addToCartModalActions } from "../store/addToCart-slice";
import { useTranslation } from "react-i18next";

const useAddToCart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const handleAddToCart = async (type: string, formData: any) => {
    let apiUrl = `cart/add-${type}`;

    try {
      setLoading(true);
      await createData(apiUrl, formData).then((response) => {
        if (response.status === 200) {
          setData(response.data);
          displayToastSuccess(response?.data?.message);
          dispatch(addToCartModalActions.closeAddToCartModal());
        }
      });
    } catch (error: any) {
      setError(error);
      // console.log(error?.response?.data?.message, formData);
      displayToastErrorApi(error && (error?.response?.data?.message || t("addToCartModal.error")));
      // displayToastErrorApi(error?.response?.data?.errors && t("addToCartModal.error"));
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, handleAddToCart };
};

export default useAddToCart;
