import { Box, CircularProgress, Stack } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useProfileAddressForm from "../../../Hooks/useProfileAddressForm";
import StyledButton from "../../StyledComponents/StyledButton/StyledButton";
import AddressFields from "./AddressFields";
type Props = {
  selectedAddress?: any;
  addAddress?: boolean;
  formReset?: boolean;
  addNewAddress?: any;
  addresses?: any;
  countryId?: number;
  setCountryId?: any;
  areaId?: any;
  setAreaId?: any;
};
const ProfileAddressForm = ({
  selectedAddress,
  addAddress,
  formReset,
  addNewAddress,
  addresses,
  countryId,
  setCountryId,
  areaId,
  setAreaId,
}: Props) => {
  const { handleSubmit, loading, handleProfileAddressForm, control, reset } =
    useProfileAddressForm({
      selectedAddress,
      addAddress,
      formReset,
      addNewAddress,
      addresses,
    });

  // handle Language
  const { t } = useTranslation();

  useEffect(() => {
    reset(selectedAddress);
  }, [formReset, selectedAddress, addAddress]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8rem",
        flexBasis: "60%",
        marginTop: "-5px",
      }}
    >
      <Stack component={"form"} spacing={2}>
        <AddressFields
          areaId={areaId}
          countryId={countryId}
          control={control}
          setAreaId={setAreaId}
          setCountryId={setCountryId}
        />
        <Box sx={{ paddingTop: "40px" }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "flex-end",
            }}
          >
            <StyledButton
              sx={{
                backgroundColor: "#A47FD1",
                width: "180px",
              }}
              variant="contained"
              onClick={handleSubmit(handleProfileAddressForm)}
              disabled={loading}
              endIcon={loading && <CircularProgress size={20} />}
            >
              {t("profile.profileForm.save")}
            </StyledButton>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default ProfileAddressForm;
