import Slider from "react-slick";
import "swiper/css";
import { actor, movie } from "../../../Types/Types";
import "./MediaListSlider.scss";
import { Box } from "@mui/material";

type Props = {
  items: (movie | actor)[];
  sliderRef?: any;
  sliderSetting?: any;
  dots?: boolean;
  small?: boolean;
  rtl?: boolean;
};

const MediaListSlider =
  (WrappedComponent: React.ComponentType<any>) =>
  ({ items, sliderRef, sliderSetting, dots = false, small = false, rtl }: Props) => {
    const settings = {
      dots: dots,
      rtl: rtl,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: items.length < 5 ? items.length : 5,
      slidesToScroll: items.length < 5 ? items.length - 1 : 5,
      initialSlide: 0,
      rows: 1,
      pauseOnHover: true,
      pauseOnFocus: true,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: items.length < 4 ? items.length : 4,
            slidesToScroll: items.length < 4 ? items.length - 1 : 4,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: items.length < 3 ? items.length : 3,
            slidesToScroll: items.length < 3 ? items.length - 1 : 3,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: items.length < 2 ? items.length : 2,
            slidesToScroll: items.length < 2 ? items.length - 1 : 2,
            centerMode: true,
            centerPadding: "15px",
          },
        },
        {
          breakpoint: 500,

          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "65px",
          },
        },
        {
          breakpoint: 400,

          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "40px",
          },
        },
      ],
    };

    const renderItems = items.map((item) => (
      <WrappedComponent key={item.id} {...item} />
    ));

    return (
      <Box
        sx={{
          "& .slick-slide": {
            display: "flex",
            justifyContent: "center",
          },
          "& .slick-dots": {
            bottom: {
              mobile: small ? "-25px" : "25px",
              md: small ? "-25px" : "0px",
            },
            li: {
              button: {
                "&::before": {
                  color: "#00ceff",
                  fontSize: "8px",
                },
              },
            },
          },
        }}
      >
        {sliderSetting ? (
          <Slider ref={sliderRef} {...sliderSetting}>
            {renderItems}
          </Slider>
        ) : (
          <Slider ref={sliderRef} {...settings}>
            {renderItems}
          </Slider>
        )}
      </Box>
    );
  };

export default MediaListSlider;
