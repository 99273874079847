import {
  Box,
  ClickAwayListener,
  Stack,
  Typography,
  styled,
  useScrollTrigger,
} from "@mui/material";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import NavItemMenu from "./NavItemMenu";
import { category } from "../../../../Types/Types";
import { useTranslation } from "react-i18next";

type Props = {
  subMenu?: category[];
  id: number;
  name_ar: string;
  name_en: string;
  slug_en: string;
  slug_ar: string;
  showMenu: number;
  setShowMenu: React.Dispatch<React.SetStateAction<number>>;
};

const NavItem = ({
  id,
  name_ar,
  name_en,
  subMenu,
  slug_ar,
  slug_en,
  showMenu,
  setShowMenu,
}: Props) => {
  let scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const StyledNavItem = styled("div")({
    textAlign: "center",
    transition: "all 0.1s ease-in-out",
    position: "relative",
    h5: {
      // fontSize: "20px",
      color: "#fff",
      // color: scrollTrigger ? "#fff" : "#062132", 
      fontWeight: "700",
      cursor: "pointer",
      textWrap: "nowrap",
      borderRadius: "5px",
      padding: "0 4px",
    },
    "@media (min-width: 1650.1px)": {
      fontSize: "2.5rem",
    },
    //less than 1650px
    "@media (max-width: 1650px)": {
      h5: {
        fontSize: "1.25rem",
      }
    },
    // less than 1440px
    "@media (max-width: 1440px)": {
      h5: {
        fontSize: "1.2rem",
      },
    },
    "@media (max-width: 1200px)": {
      h5: {
        fontSize: "18px",
      },
    },
  });
  const handleShowMenu = (id: number) => {
    setShowMenu(id);
  };
  const handleCloseMenu = () => {
    setShowMenu(0);
  };
  const { i18n } = useTranslation();
  
  return (
    // <ClickAwayListener onClickAway={handleCloseMenu}>
    // </ClickAwayListener>
      <Box
        component="div"
        onMouseLeave={handleCloseMenu}
        sx={{
          alignSelf: "center",
          borderRadius: "5px",
        }}
      >
        <StyledNavItem
          onMouseEnter={() => {
            handleShowMenu(id);
          }}
          onMouseLeave={() => handleCloseMenu()}
          onClick={() => {
            setShowMenu(id);
          }}
        >
          <Link to={slug_en}>
            <Typography
              variant="h5"
              sx={{
                backgroundImage:
                  id === showMenu
                    ? "linear-gradient(180deg, #7E7E7E00 9%, #BAE4FE7A 100%)"
                    : "transparent",
              }}
              title={name_ar}
            >
              {i18n.language === "ar" ? name_ar : name_en}
            </Typography>
          </Link>

          {showMenu === id && subMenu && (
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{ duration: 0.2 }}
              onClick={handleCloseMenu}
            >
              <Stack
                sx={{
                  position: "absolute",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    height: "8px",
                    width: "100%",
                  }}
                ></Box>
                <NavItemMenu subMenu={subMenu} />
              </Stack>
            </motion.div>
          )}
        </StyledNavItem>
      </Box>
  );
};

export default NavItem;
