import { Container } from "@mui/material";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const MainContainer = ({ children }: Props) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        scrollSnapType: "y mandatory",

        maxWidth: {
          mobile: "95%",
          md: "90%",
          xxl: "1440px",
        },
      }}
    >
      {children}
    </Container>
  );
};

export default MainContainer;
