import { Box, CircularProgress, ListItem, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import useDebounce from "../../../Hooks/useDebounce";
import { getData } from "../../../Services/DataServices";

export default function CustomSearchField() {
  const searchStyles = {
    margin: "0 .5rem",
    "& .MuiInputBase-root": {
      color: "white",
      width: {
        mobile: "100%",
        md: "125px",
        lg: "200px",
      },
      marginBottom: "8px",
      fontSize: {
        mobile: ".725rem",
        xs: "1rem",
        sm: "1.25rem",
      },
      "& .Mui-focused": {
        color: "white",
        borderBottom: "1px solid white !important",
      },
      //   transform: "translateY(-0.4rem)",

      "& svg": {
        fontSize: { mobile: "1.25rem", xs: "1.25rem", sm: "1.625rem" },
        marginRight: "8px",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "1px solid white !important",
        },
      },
      "&::before": {
        borderBottom: "1px solid white !important",
      },
      "&::after": {
        borderBottom: "2px solid white !important",
      },
    },
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get("keyword");

  const navigate = useNavigate();
  const handleNavigate = (type, id) => {
    navigate(`/media/${type}/${id}`);
  };
  const { t } = useTranslation();
  const observer = useRef();

  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const lastOptionElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((pageNumber) => pageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const [searchTerm, setSearchTerm] = useState(keyword || "");
  const handleInputChange = (e) => {
    setPageNumber(1);
    setSearchTerm(e.target.value);
  };
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const fetchData = async (keyword, page) => {
    try {
      setLoading(true);
      const result = await getData(
        `searchList?keyword=${keyword}&page=${page}`
      );

      if (page === 1) {
        setItems(result?.data?.data?.data);
      } else {
        setItems((prev) => [...prev, ...result?.data?.data?.data]);
      }
      setHasMore(result?.data?.data?.last_page > page ? true : false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchData(debouncedSearchTerm, pageNumber);
    }
  }, [debouncedSearchTerm, pageNumber]);

  // useEffect(() => {
  //   if (hasMore && pageNumber > 1) {
  //     setPageNumber((pageNumber) => pageNumber + 1);
  //   }
  // }, [lastOptionElementRef]);
  const LoadingComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress size={20} />
      </Box>
    );
  };

  return (
    <Autocomplete
      onChange={(e, value) => {
        navigate(`/search-results?title=${searchTerm}`);
      }}
      loading={loading}
      loadingText={<LoadingComponent />}
      id="search-field"
      className="search-field"
      freeSolo
      slotProps={{
        paper: {
          sx: {
            "& ::-webkit-scrollbar": {
              width: ".2em",
            },
            "& ::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              width: ".2em",
            },
            "& ::-webkit-scrollbar-thumb": {
              backgroundColor: "#3db9ec",
              borderRadius: "20px",
              width: ".2em",
            },
            "& li": {
              fontSize: "1rem",
              fontWeight: "400",
              color: "#755798",
              position: "relative",
            },
          },
        },
      }}
      clearIcon={false}
      clearOnEscape
      options={items}
      filterOptions={(options, state) => {
        return options;
      }}
      renderOption={(props, option) => {
        const checkLastOption = items.indexOf(option) === items.length - 1;
        const { id, name, type } = option;
        if (loading && checkLastOption) {
          return (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <ListItem
                {...props}
                ref={checkLastOption ? lastOptionElementRef : null}
                onClick={() => handleNavigate(type, id)}
                key={id}
              >
                {name}
              </ListItem>
              <LoadingComponent />
            </Stack>
          );
        } else {
          return (
            <ListItem
              {...props}
              ref={checkLastOption ? lastOptionElementRef : null}
              onClick={() => handleNavigate(type, id)}
              key={id}
            >
              {name}
            </ListItem>
          );
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleInputChange}
          placeholder={t("search")}
          variant="standard"
          sx={searchStyles}
          InputProps={{
            ...params.InputProps,

            startAdornment: (
              <FiSearch
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (searchTerm) {
                    navigate(`/search-results?keyword=${searchTerm}`);
                  }
                }}
              />
            ),
          }}
        />
      )}
    />
  );
}
