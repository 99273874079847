import { Box, Divider, Rating, Stack, Typography } from "@mui/material";
import useRating from "../../../Hooks/useRating";
import TagItem from "./TagItem";
import icon from "../../../Assets/Images/big-start.svg";
import emptyIcon from "../../../Assets/Images/big-empty-star.svg";
import styled from "@emotion/styled";
import { Price } from "../../../Types/Types";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import PriceCard from "../PriceItem/PriceCard";
import { StyledSecondaryButton } from "../../StyledComponents/StyledButton/StyledButton";
import PriceItem from "../PriceItem/PriceItem";

type Props = {
  type: string;
  productId: number;
  image?: string;
  noPeopleRated: string | number;
  rating: string;
  descAr: string;
  descEn: string;
  thumb: string;
  price: Price;
  tags: {
    name: string;
    name_ar: string;
    label_value: string;
    label_value_ar: string;
  }[];
};

const MediaHeaderSecondaryInfo = ({
  image,
  noPeopleRated,
  descAr,
  descEn,
  tags,
  thumb,
  rating,
  type,
  productId,
  price
}: Props) => {
  const { loading, createRating } = useRating(rating);
  const { t, i18n } = useTranslation();
  const cookies = new Cookies();
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            mobile: "1fr",
            md: "repeat(12, 1fr)",
          },
          gap: {
            mobile: ".5rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "2.5rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "",
            gap: "2.5rem",
            maxWidth: "265px",
            gridColumn: {
              mobile: "1 / span 12",
              md: "1 / span 3",
            },
            "@media (max-width: 768px)":{
              margin: "0 auto"
            }
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "320px",
              borderRadius: "8px",
              "@media (max-width: 768px)":{
                margin: "0 auto 0 auto"
              }
            }}
            component="img"
            src={thumb}
            alt="movie-image"
          />
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems={"center"}
          >
            <Rating
              precision={0.5}
              size="small"
              icon={
                <Box
                  sx={{
                    margin: "0 2px",
                  }}
                  component="img"
                  src={icon}
                />
              }
              emptyIcon={
                <Box
                  sx={{
                    margin: "0 2px",
                  }}
                  component="img"
                  src={emptyIcon}
                />
              }
              value={Number(rating)}
              onChange={(e, value) => {
                value && createRating(value, productId, type);
              }}
            />
            <Typography
              sx={{
                color: "#FFAD0A",
                fontSize: "1.25rem",
              }}
            >
              {noPeopleRated ? `(${String(noPeopleRated)})` : "0"}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            gridColumn: {
              mobile: "1 / span 12",
              md: "4 / span 9",
            },
          }}
        >
          <Stack
            spacing={1}
            sx={{
              marginBottom: "2em"
            }}
            divider={
              descEn.length > 0 &&
              descAr.length > 0 && (
                <Divider
                  sx={{
                    margin: "0 auto",
                    width: "70%",
                    borderColor: "secondary.main",
                    alignSelf: "center",
                  }}
                />
              )
            }
          >
            <StyledDesc dir="ltr" >{descEn}</StyledDesc>
            <StyledDesc dir="rtl" >{descAr}</StyledDesc>
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "16px",
              gap: {
                mobile: ".5rem",
                md: "1rem",
              },
              maxWidth: {
                mobile: "100%",
                md: "96%",
              },
              "@media(max-width: 576px)":{
                flexDirection: "column"
              }
            }}
          >
            {tags &&
              tags.length > 0 &&
              tags?.map((tag, index: number) => <TagItem key={index} {...tag} />)}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: "1.5rem",
          gap: {
            mobile: ".5rem",
            md: "1rem",
          },
          maxWidth: {
            mobile: "100%",
            md: "96%",
          },
          "@media(max-width: 576px)":{
            flexDirection: "column"
          }
        }}
      >
        {price.watch?.price && 
          <StyledSecondaryButton
            variant={ "outlined"}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              color: "#755798",
              backgroundColor: "#0d042ae3",
              textTransform: "none",
              fontSize: {
                mobile: "16px",
              },
              borderRadius: "12px",
              height: {
                mobile: "auto",
                md: "auto",
              },
              width: {
                lg: "23%",
                md: "48%",
                mobile: "100%",
              },
              "&:hover": {
                backgroundColor: "#0d042ae3",
              },
              maxWidth: "unset",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "auto",
              "& svg": {
                position: "absolute",
                color: "#fff",
                fontSize: "1.5rem",
                backgroundColor: "rgb(19 190 66)",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                right: "8px",
                top: "8px",
              },
            }}
            
          >
            <Typography
              sx={{
                fontSize: "1rem",
                color: "#fff",
              }}
            >
              {t("streaming_price")}
            </Typography>
            <PriceItem
              smallGap
              price={price.watch?.price}
              discounted_price={price.watch?.discounted_price}
              has_discount={price.watch?.has_discount}
            />
          </StyledSecondaryButton>
        }
        {price.download?.price && 
          <StyledSecondaryButton
            variant={ "outlined"}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              color: "#755798",
              backgroundColor: "#0d042ae3",
              textTransform: "none",
              fontSize: {
                mobile: "16px",
              },
              borderRadius: "12px",
              height: {
                mobile: "auto",
                md: "auto",
              },
              width: {
                lg: "23%",
                md: "48%",
                mobile: "100%",
              },
              "&:hover": {
                backgroundColor: "#0d042ae3",
              },
              maxWidth: "unset",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "auto",
              "& svg": {
                position: "absolute",
                color: "#fff",
                fontSize: "1.5rem",
                backgroundColor: "rgb(19 190 66)",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                right: "8px",
                top: "8px",
              },
            }}
            
          >
            <Typography
              sx={{
                fontSize: "1rem",
                color: "#fff",
              }}
            >
              {t("download_price")}
            </Typography>
            <PriceItem
              smallGap
              price={price.download?.price}
              discounted_price={price.download?.discounted_price}
              has_discount={price.download?.has_discount}
            />
          </StyledSecondaryButton>
        }
      
        {price.dvd?.price && 
          <StyledSecondaryButton
            variant={ "outlined"}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              color: "#755798",
              backgroundColor: "#0d042ae3",
              textTransform: "none",
              fontSize: {
                mobile: "16px",
              },
              borderRadius: "12px",
              height: {
                mobile: "auto",
                md: "auto",
              },
              width: {
                lg: "23%",
                md: "48%",
                mobile: "100%",
              },
              "&:hover": {
                backgroundColor: "#0d042ae3",
              },
              maxWidth: "unset",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "auto",
              "& svg": {
                position: "absolute",
                color: "#fff",
                fontSize: "1.5rem",
                backgroundColor: "rgb(19 190 66)",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                right: "8px",
                top: "8px",
              },
            }}
            
          >
            <Typography
              sx={{
                fontSize: "1rem",
                color: "#fff",
              }}
            >
              {t("dvd_price")}
            </Typography>
            <PriceItem
              smallGap
              price={price.dvd?.price}
              discounted_price={price.dvd?.discounted_price}
              has_discount={price.dvd?.has_discount}
            />
          </StyledSecondaryButton>
        }
        {price.flash?.price && 
          <StyledSecondaryButton
            variant={ "outlined"}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              color: "#755798",
              backgroundColor: "#0d042ae3",
              textTransform: "none",
              fontSize: {
                mobile: "16px",
              },
              borderRadius: "12px",
              height: {
                mobile: "auto",
                md: "auto",
              },
              width: {
                lg: "23%",
                md: "48%",
                mobile: "100%",
              },
              "&:hover": {
                backgroundColor: "#0d042ae3",
              },
              maxWidth: "unset",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "auto",
              "& svg": {
                position: "absolute",
                color: "#fff",
                fontSize: "1.5rem",
                backgroundColor: "rgb(19 190 66)",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                right: "8px",
                top: "8px",
              },
            }}
            
          >
            <Typography
              sx={{
                fontSize: "1rem",
                color: "#fff",
              }}
            >
              {t("flash_price")}
            </Typography>
            <PriceItem
              smallGap
              price={price.flash?.price}
              discounted_price={price.flash?.discounted_price}
              has_discount={price.flash?.has_discount}
            />
          </StyledSecondaryButton>
        }
      </Box>
    </>
  );
};

export default MediaHeaderSecondaryInfo;

const StyledDesc = styled(Typography)({
  fontSize: "22px",
  color: "#E2E2E2",
  lineHeight: "41px",
  fontWeight: "300",
});
