import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export interface RefreshApiState {
  refreshApi: boolean;
}

const initialState: RefreshApiState = {
  refreshApi: false,
};

const refreshApiSlice = createSlice({
  name: "refreshApi",
  initialState,
  reducers: {
    refreshApi: (state) => {
      state.refreshApi = !state.refreshApi;
    },
  },
});

export const refreshApiActions = refreshApiSlice.actions;
export default refreshApiSlice;
