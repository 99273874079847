import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ConnectViaWhatsapp from "../../Auth/ConnectViaWhats";

import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { handleForgetPasswordStepOne } from "../../../Helpers/AuthFunctions/ForgetPasswordHelpers";
import AuthTextFIeld from "../../Blocks/AuthTextField/AuthTextField";
import { StyledAuthButton } from "../../StyledComponents/StyledButton/StyledButton";

const StepOne = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Submit handler function
  const onSubmit = async (data) => {
    handleForgetPasswordStepOne(data, setError, setLoading, navigate);
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("forgetPasswordPage.stepOne.validation.email"))
      .required(t("forgetPasswordPage.stepOne.validation.email")),
  });
  // React Hook Form
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <form className="forget-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__fields">
        <h6>{t("forgetPasswordPage.stepOne.title")}</h6>
        <AuthTextFIeld
          placeholder={t("forgetPasswordPage.stepOne.email")}
          name="email"
          type={"email"}
          register={register}
          error={errors?.email}
          touched={touchedFields?.email}
        />
        <div className="whats-contact">
          <ConnectViaWhatsapp />
        </div>
      </div>

      <StyledAuthButton
        disabled={loading}
        endIcon={loading && <CircularProgress size={20} />}
        variant="contained"
        type="submit"
      >
        {t("forgetPasswordPage.stepOne.next")}
      </StyledAuthButton>
    </form>
  );
};

export default StepOne;
