import { Box, Divider, Stack } from "@mui/material";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import StyledText from "../../Components/StyledComponents/StyledText/StyledText";
import useGetData from "../../Hooks/useGetData";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import { useTranslation } from "react-i18next";
import getLocalizedProperty from "../../Helpers/Functions/getLocalizedProperty";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";

type Props = {};

const Policy = (props: Props) => {
  const { data, loading, error } = useGetData("footer/shipping_policy", []);
  const { i18n } = useTranslation();
  if (error) return <ErrorComponent />;
  if (loading) return <MainLoader />;
  return (
    <>
      {data && (
        <MainContainer>
          <PageContentContainer>
            <SectionHead
              color="#CEAFF5"
              title={getLocalizedProperty(
                "title",
                data?.shipping_policy,
                i18n.language
              )}
              subTitle={getLocalizedProperty(
                "subtitle",
                data?.shipping_policy,
                i18n.language
              )}
            />
            <Stack
              spacing={6}
              divider={
                <Divider
                  sx={{
                    width: "60%",
                    opacity: "0.18",
                    backgroundColor: "#fff",
                  }}
                />
              }
            >
              <StyledText
                dangerouslySetInnerHTML={{
                  __html: getLocalizedProperty(
                    "body",
                    data?.shipping_policy,
                    i18n.language
                  ),
                }}
              />
              {/* 
              <Stack spacing={3}>
                <Stack>
                  <StyledText
                    sx={{
                      color: "#fff",
                    }}
                  >
                    {getLocalizedProperty(
                      "subsection_title",
                      data?.shipping_policy,
                      i18n.language
                    )}
                  </StyledText>
                  <StyledText>
                    {getLocalizedProperty(
                      "subsection_body",
                      data?.shipping_policy,
                      i18n.language
                    )}
                  </StyledText>
                </Stack>
                <StyledText
                  sx={{
                    color: "#fff",
                  }}
                >
                  {getLocalizedProperty(
                    "subsection_footer",
                    data?.shipping_policy,
                    i18n.language
                  )}
                </StyledText>
                <StyledText
                  sx={{
                    color: "#fff",
                  }}
                >
                  {getLocalizedProperty(
                    "subsection_notes",
                    data?.shipping_policy,
                    i18n.language
                  )}
                </StyledText>
              </Stack> */}
            </Stack>
          </PageContentContainer>
        </MainContainer>
      )}
      <RequestBlock />
    </>
  );
};

export default Policy;
