import { toast } from "react-toastify";

export const displayToastErrorApi = (error: string) => {
  toast.error(error, {
    toastId: "errorApi",
  });
};

export const displayToastSuccess = (message: string) => {
  toast.success(message, {
    toastId: "success",
  });
};
