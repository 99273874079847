import { Box, styled } from "@mui/material";
import movieBg from "../../../Assets/Images/movie-bg.svg";

const StyledMainBox = styled("div")({
  overflow: "hidden",
  // height: "185vh",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  // justifyContent: "flex-end",
  padding: "0 0 9rem 0",
  zIndex: 1,
  minHeight: "1200px",
  "&::before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // background: `url(${movieBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    zIndex: -2,
  },
  "&::after": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundImage:
      "linear-gradient(180deg, #00000075 0%, #24113a1c 35%, #321c4a6e 50%, #302254 100%, #24113A 100%)",
    zIndex: -1,
  },
  "@media (max-width: 1650px)": {
    // height: "160vh",
    padding: "0 0 3rem 0",
  },
  "@media (max-width: 1023.99px)": {
    "&::after":{
      height: "70vh"
    }
  },
  "@media (max-width: 992px)": {
    "&::after": {
      height: "50vh"
    }
    // height: "130vh",
  },
  "@media (max-width: 576px)": {
    "&::after": {
      height: "48.3vh"
    }
  },

  //max-width: 768px; and height more than 600px
});

export default StyledMainBox;
