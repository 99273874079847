import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import logo from "../../../Assets/Images/logo.png";
import useDeleteFromCart from "../../../Hooks/useDeleteFromCart";
import { cartActions } from "../../../store/cart-slice";

const smallButtonStyles = {
  minHeight: 0,
  minWidth: 0,
  p: { xs: 0 },
  width: { xs: "25px", md: "30px", lg: "35px" },
  fontSize: { xs: "18px", md: "20px", xl: "24px" },
  height: { xs: "22px", md: "28px" },
  borderInlineEnd: "1px solid rgba(117, 87, 152, 1)",
  borderRadius: 0,
  paddingBottom: "5px !important",
};
type Props = {
  id: number;

  name: string;
  name_ar: string;
  price: number;
  poster: string | "NULL";
  has_cover: boolean;
  cover_price: number;
  priceType: number;
  sub_total: number;
  quantity: number;
};
const ShoppingCartProduct = ({
  name,
  name_ar,
  price,
  id,
  poster,
  has_cover,
  cover_price,
  priceType,
  sub_total,
  quantity,
}: Props) => {
  const { loading, handleDeleteFromCart } = useDeleteFromCart();
  const dispatch = useDispatch();
  const cartState = useSelector((state: any) => state.cart);

  const cookies = new Cookies();

  const { i18n, t } = useTranslation();

  return (
    <Box
      sx={{
        position: "relative",
        display: {
          mobile: "flex",
          md: "grid",
        },
        flexDirection: "column",
        gridTemplateColumns: {
          mobile: "1fr",
          lg: "1fr .75fr",
          xl: "2fr 1fr",
        },
        backgroundColor: "#f5f1fb66",
        borderRadius: "4px",
        alignItems: "center",
        py: {
          mobile: "16px",
          md: "20px",
          lg: "14px",
        },
        px: {
          mobile: "14px",
          md: "24px",
        },
        width: {
          mobile: "250px",
          sm: "300px",
          md: "100%",
        },
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        "@media (max-width: 390px)": {
          width: "210px",
        },
      }}
    >
      {priceType === 4 && <Badge quantity={quantity} />}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            mobile: "1fr",
            md: "1fr 6fr",
          },
          justifyItems: "center",

          gap: {
            mobile: "14px",
            sm: "16px",
            md: "18px",
          },
          alignItems: "center",
        }}
      >
        <Box
          component={"img"}
          src={poster === "NULL" || "" ? logo : poster}
          alt="movie name"
          sx={{
            width: {
              xs: "auto",
              md: "80px",
              lg: "90px",
              xl: "111px",
            },
            height: {
              xs: "200px",
              md: "60px",
              lg: "70px",
              xl: "78px",
            },
            borderRadius: { xs: "4px", md: "6px", lg: "8px" },
          }}
        />

        <Typography
          sx={{
            color: "rgba(117, 87, 152, 1)",
            textAlign: "center",
            fontSize: { mobile: "16px", md: "22px" },
            py: "6px",
          }}
        >
          {i18n.language === "en" ? name || name_ar : name_ar || name}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          width: "100%",
          alignItems: "center",
          padding: {
            mobile: "16px 0",
            md: "0 24px",
          },
          marginTop: {
            mobile: "auto",
            md: "unset",
          },
        }}
      >
        <Stack
          spacing={{
            mobile: 0,
            md: 1,
          }}
          alignItems={"flex-end"}
        >
          <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
            <Typography
              sx={{
                color: "rgba(117, 87, 152, 1)",
                fontSize: {
                  xs: "12px",
                  xl: "14px",
                },
              }}
            >
              { cookies.get("currency") || "KWD"}
            </Typography>
            <Typography
              sx={{
                color: "rgba(117, 87, 152, 1)",
                fontSize: "20px",
              }}
            >
              {price}
            </Typography>
          </Box>
          {has_cover && (
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              sx={{
                position: "absolute",
                width: "100%",
                bottom: {
                  mobile: "-16px",
                  md: "-24px",
                },
                left: "0",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(117, 87, 152, 1)",
                  fontSize: {
                    xs: "12px",
                    xl: "14px",
                  },
                  whiteSpace: "nowrap"
                }}
              >
                {t("coverPrice")}
              </Typography>
              <Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
                <Typography
                  sx={{
                    color: "rgba(117, 87, 152, 1)",
                    fontSize: {
                      xs: "12px",
                      xl: "14px",
                    },
                  }}
                >
                  {cookies.get("currency") || "KWD"}
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(117, 87, 152, 1)",
                    fontSize: "20px",
                  }}
                >
                  {cover_price}
                </Typography>
              </Box>
            </Stack>
          )}
        </Stack>
        <Button
          sx={{
            minHeight: 0,
            minWidth: 0,
            p: { xs: 0 },
            "@media (max-width: 600px)": {
              justifyContent: "flex-start",
              gridArea: " 2 / 2 / 3 / 3 ",
            },
          }}
          onClick={() => {
            handleDeleteFromCart(String(id));
            dispatch(
              cartActions.removeItem({
                id,
                priceType,
              })
            );
            if (priceType === 3) {
              //emptyStorageItems
              dispatch(cartActions.emptyStorageItems());
            }
          }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} color="primary" />
          ) : (
            <DeleteIcon sx={{ color: "rgba(117, 87, 152, 1)" }} />
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default ShoppingCartProduct;

const Badge = ({ quantity }: { quantity: number }) => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        borderRadius: "50%",
        position: "absolute",
        top: "0",
        right: "0",
        width: "22px",
        height: "22px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: "white",
          fontSize: "16px",
          fontWeight: 800,
          padding: "4px 0",
        }}
      >
        {quantity}
      </Typography>
    </Box>
  );
};
