import { styled, Button } from "@mui/material";

const StyledButton = styled(Button)({
  width: "100%",
  maxWidth: "180px",
  height: "55px",
  borderRadius: "8px",
  color: "white",
  fontSize: "1.5rem",
  "@media (max-width: 768px)": {
    maxWidth: "120px",
    height: "40px",
    fontSize: "1.25rem",
  },
});

export const StyledSecondaryButton = styled(Button)({
  width: "150px",
  height: "50px",
  fontWeight: "600",
  borderRadius: "12px",
  // "@media (max-width: 1024px)": {
  //   width: "120px",
  //   // flex: "1",
  // },
});

export const StyledAuthButton = styled(Button)({
  width: "330px",
  height: "84px",
  borderRadius: "16px",
  fontSize: "32px",
  "@media (max-width: 1440px)": {
    width: "250px",
    height: "64px",
    borderRadius: "12px",
    fontSize: "24px",
  },
  "@media (max-width: 1024px)": {
    width: "200px",
    height: "60px",
    borderRadius: "12px",
    fontSize: "22px",
  },
  "@media (max-width: 768px)": {
    width: "180px",
    height: "50px",
    borderRadius: "12px",
    fontSize: "20px",
  },
});

export const StyledIconButton = styled(Button)({
  height: "40px",
  width: "40px",
  padding: "8px",
  minWidth: "unset",
  borderRadius: "8px",
});

//  sx={{
//       width: {
//         xs: "180px",
//         lg: "330px",
//       },
//       height: "84px",
//       borderRadius: "16px",
//       color: "#E8D6FF",
//       fontSize: "32px",
//     }}

export default StyledButton;
