import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import AnimateTap from "../../../Animation/AnimateTap";
import { category } from "../../../../Types/Types";
import { useTranslation } from "react-i18next";

type Props = {
  subMenu?: category[];
};

const StyledMenu = styled("div")({
  backgroundColor: "#FFFFFF",
  padding: "20px 16px 12px 8px",
  color: "#278CC9",
  boxShadow: "0px 3px 30px #00000080",
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  width: "130%",
  fontWeight: "500",
  borderRadius: "10px",
  position: "relative",
  maxHeight: "450px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "1px",
  },
  "@media (max-width: 1440px)": {
    padding: "20px 8px 12px 8px",
  },
});

const StyledLink = styled("div")({
  fontSize: "1.25rem",
  fontWeight: "500",
  cursor: "pointer",
  // whiteSpace: "nowrap",
  "&::after": {
    content: "''",
    position: "absolute",
    bottom: "-8px",
    width: "95%",
    left: "50%",
    transform: "translateX(-50%)",
    height: "1px",
    backgroundColor: "#278cc929",
  },
  "@media (max-width: 1440px)": {
    fontSize: "14px",
  },
});

const StyledSubLink = styled("div")({
  position: "relative",
  textAlign: "left",
});

const NavItemMenu = ({ subMenu }: Props) => {
  const { i18n } = useTranslation();
  return (
    <StyledMenu>
      {subMenu &&
        subMenu?.length > 0 &&
        subMenu.map((item: any, index: number) => {
          return (
            <StyledSubLink key={item.id}>
              <StyledLink
                sx={{
                  "&::after": {
                    display: index === subMenu.length - 1 ? "none" : "block",
                  },
                }}
                title={item.name_ar}
              >
                <Link
                  to={`/media/${item.slug_en}?name=${item.name_ar}&nameEn=${item.name_en}`}
                >
                  <AnimateTap>
                    {i18n.language === "ar" ? item.name_ar : item.name_en}
                  </AnimateTap>
                </Link>
              </StyledLink>
            </StyledSubLink>
          );
        })}
    </StyledMenu>
  );
};

export default NavItemMenu;
