import { CacheProvider, ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import axios from "axios";
import { useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Cookies from "universal-cookie";
import "./App.scss";
import AlertMessage from "./Components/Blocks/AlertMessage/AlertMessage";
import ShoppingCart from "./Components/Blocks/ShoppingCart/ShoppingCart";
import AddToCartModal from "./Components/Modals/AddToCartModal";
import TrailerModal from "./Components/Modals/TrailerModal";
import { cacheLtr, cacheRtl } from "./Helpers/Theme/cache";
import { ltrTheme, rtlTheme } from "./Helpers/Theme/theme";
import useGetCurrency from "./Hooks/useGetCurrency";
import { useScrollToTop } from "./Hooks/useScrollToTop";
import AppRoutes from "./Routes/AppRoutes";
import "./i18n";
import { authActions } from "./store/auth-slice";
import VerificationModal from "./Components/Modals/VerificationModal";
import { verificationsActions } from "./store/verification-slice";

function App() {
  // document.addEventListener("contextmenu", (e) => {
  //   e.preventDefault();
  // });
  // document.onkeydown = function (e) {
  //   if (
  //     e.ctrlKey &&
  //     (e.keyCode === 85 ||
  //       e.keyCode === 67 ||
  //       e.keyCode === 73 ||
  //       e.keyCode === 74)
  //   ) {
  //     return false;
  //   }
  // };

  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dir = i18n.dir();
  useLayoutEffect(() => {
    document.body.setAttribute("dir", dir);
  }, [dir]);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  useEffect(() => {
    if (cookies.get("token")) {
      let isVip = cookies.get("vip");
      if (isVip === "true" || isVip === true) {
        isVip = true;
      } else {
        isVip = false;
      }
      dispatch(
        authActions.login({
          token: cookies.get("token"),
          isVip: isVip,
        })
      );
    } else {
      dispatch(authActions.logout());
    }
  }, []);
  useGetCurrency();
  //axios interceptors
  axios.interceptors.request.use(function (config) {
    const token = cookies.get("token");
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.lang = i18n.language;
    return config;
  });
  // axios interceptor to logout if error 401
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        dispatch(authActions.logout());
        navigate("/login");
        // console.log('run logout');
        
      }
      if (error.response.status === 405) {
        dispatch(verificationsActions.setOpen(true));
      }
      return Promise.reject(error);
    }
  );

  useScrollToTop([]);

  return (
    <CacheProvider value={dir === "rtl" ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={dir === "rtl" ? rtlTheme : ltrTheme}>
        <CssBaseline />
        <AppRoutes />
        {/* general modals */}
        <AlertMessage />
        <AddToCartModal />
        <ShoppingCart />
        <TrailerModal />
        {/* <VerificationModal /> */}
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
