import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { handleForgetPasswordStepThree } from "../../../Helpers/AuthFunctions/ForgetPasswordHelpers";
import AuthTextField from "../../Blocks/AuthTextField/AuthTextField";
import { StyledAuthButton } from "../../StyledComponents/StyledButton/StyledButton";

const StepThree = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(t("forgetPasswordPage.stepThree.validation.password")),
    //must match password
    password_confirmation: yup
      .string()
      .required(t("forgetPasswordPage.stepThree.validation.confirmPassword"))
      .oneOf(
        [yup.ref("password")],
        t("forgetPasswordPage.stepThree.validation.confirmPassword")
      ),
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Submit handler function
  const onSubmit = async (data) => {
    handleForgetPasswordStepThree(data, setError, setLoading, navigate);
  };

  // React Hook Form
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { password, password_confirmation } = errors;
  const {
    password: passwordTouched,
    password_confirmation: passwordConfirmationTouched,
  } = touchedFields;

  return (
    <form className="forget-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__fields">
        <h6>{t("forgetPasswordPage.stepThree.title")}</h6>
        <AuthTextField
          placeholder={t("forgetPasswordPage.stepThree.password")}
          name="password"
          type={"password"}
          register={register}
          error={password}
          touched={passwordTouched}
        />
        <AuthTextField
          placeholder={t("forgetPasswordPage.stepThree.confirmPassword")}
          name="password_confirmation"
          type={"password"}
          register={register}
          error={password_confirmation}
          touched={passwordConfirmationTouched}
        />
      </div>

      <StyledAuthButton
        disabled={loading}
        endIcon={loading && <CircularProgress size={20} />}
        variant="contained"
        type="submit"
      >
        {t("forgetPasswordPage.stepThree.save")}
      </StyledAuthButton>
    </form>
  );
};

export default StepThree;
