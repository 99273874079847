import { useDispatch } from "react-redux";
import { createData } from "../../Services/DataServices";
import { displayToastSuccess } from "../toastHelper";

import { useNavigate } from "react-router-dom";
import { refreshAddressActions } from "../../store/refreshAddress-slice";

const useRegisterFormHelper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const RegisterFromHelper = async (
    data,
    url,
    setError,
    setLoading,
    addressId
  ) => {
    const formData = new FormData();
    if (addressId) {
      formData.append("address_id", addressId);
    }
    for (let key in data) {
      formData.append(key, data[key]);
    }
    try {
      setLoading(true);
      await createData(url, formData)
        .then((res) => {
          if (res.status === 200) {
            displayToastSuccess(res?.data?.message);
            dispatch(refreshAddressActions.refreshApi());
          }
        })
        .then(() => navigate("/"));
    } catch (error) {
      //loop over the errors object and set the errors
      for (let key in error?.response?.data?.errors) {
        setError(key, {
          message: error?.response?.data?.errors[key][0],
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return { RegisterFromHelper };
};

export default useRegisterFormHelper;
