import { Box, Divider, Stack } from "@mui/material";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import StyledText from "../../Components/StyledComponents/StyledText/StyledText";
import useGetData from "../../Hooks/useGetData";
import { useTranslation } from "react-i18next";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import getLocalizedProperty from "../../Helpers/Functions/getLocalizedProperty";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";

type Props = {};

const Privacy = (props: Props) => {
  const { data, loading, error } = useGetData("footer/privacy_policy", []);
  const { i18n } = useTranslation();
  if (error) return <ErrorComponent />;
  if (loading) return <MainLoader />;
  return (
    <>
      {data && (
        <MainContainer>
          <PageContentContainer>
            <SectionHead
              color="#CEAFF5"
              title={getLocalizedProperty(
                "title",
                data?.privacy_policy,
                i18n.language
              )}
              subTitle={getLocalizedProperty(
                "subtitle",
                data?.privacy_policy,
                i18n.language
              )}
            />
            <Stack
              spacing={6}
              divider={
                <Divider
                  sx={{
                    width: "60%",
                    opacity: "0.18",
                    backgroundColor: "#fff",
                  }}
                />
              }
            >
              <Stack spacing={3}>
                <StyledText
                  dangerouslySetInnerHTML={{
                    __html: getLocalizedProperty(
                      "body",
                      data?.privacy_policy,
                      i18n.language
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </PageContentContainer>
        </MainContainer>
      )}
      <RequestBlock />
    </>
  );
};

export default Privacy;
