import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "95vh",
  bgcolor: "transparent",
  boxShadow: "0 3px 40px #000000AB",
  borderRadius: "30px",
  backdropFilter: "blur(39px)",
  webkitBackdropFilter: "blur(39px)",
};

function CheckoutModal() {
  const cartState = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(cartActions.handleCheckOutModal(false));
  };

  return (
    <>
      <Modal
        open={cartState.checkoutModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box sx={style}>
          {cartState?.checkoutModalData && (
            <div
              dangerouslySetInnerHTML={{ __html: cartState?.checkoutModalData }}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default React.memo(CheckoutModal);
