// useProfileAddressForm.js
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import useRegisterFormHelper from "../Helpers/AuthFunctions/useRegisterFormHelper";

const useProfileAddressForm = ({
  selectedAddress,
  addAddress,
  formReset,
  addNewAddress,
  addresses,
}: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { RegisterFromHelper } = useRegisterFormHelper();
  const { t } = useTranslation();

  // Other variables and functions...

  const validationSchema = yup.object().shape({
    country_id: yup.string().required(t("validation.required")),
    // if country_id is 1 then area_id is required
    area_id: yup.string().when("country_id", {
      is: "1",
      then: (schema) => schema.required(t("validation.required")),
    }),
    detailed_address: yup.string().when("country_id", {
      is: (country_id: string) => country_id !== "1",
      then: (schema) => schema.required(t("validation.required")),
    }),
  });

  useEffect(() => {
    reset(selectedAddress);
  }, [formReset, selectedAddress, addAddress]);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    getValues,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: {
      ...selectedAddress,
    },
    resolver: yupResolver(validationSchema),
  });

  const handleProfileAddressForm = async (address: any) => {
    // setLoading(true);
    const formData = new FormData();
    // formData.append("area", address.area_id);

    for (let key in address) {
      formData.append(key, address[key]);
    }
    if (addAddress) {
      RegisterFromHelper(address, `address/store`, setError, setLoading);
    } else {
      RegisterFromHelper(
        address,
        `address/update/${selectedAddress.id}`,
        setError,
        setLoading,
        selectedAddress.id
      );
    }
  };

  return { handleSubmit, loading, handleProfileAddressForm, control, reset };
};

export default useProfileAddressForm;
