import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import { Box, Stack } from "@mui/material";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import AdvancedSearchForm from "../../Components/Blocks/Forms/AdvancedSearchForm";
import { useTranslation } from "react-i18next";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";
import useActivityLog from "../../Hooks/useGetLocation";

const AdvancedSearch = () => {
  const { t } = useTranslation();
  useActivityLog()

  return (
    <>
      <ParticlesBackGround />
      <Stack>
        <MainContainer>
          <PageContentContainer>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: {
                  mobile: "50px",
                  md: "100px",
                  xl: "120px",
                },
              }}
            >
              <SectionHead
                color="#CEAFF5"
                title={t("advancedSearchPage.title")}
                subTitle={t("advancedSearchPage.subTitle")}
              />
              <AdvancedSearchForm />
            </Box>
          </PageContentContainer>
        </MainContainer>
        <RequestBlock />
      </Stack>
    </>
  );
};

export default AdvancedSearch;
