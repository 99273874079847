import React from "react";
import OffersImg from "../../../Assets/Images/offers-img.jpg";
import "./Offer.scss";
import { useTranslation } from "react-i18next";

type Props = {
  id: number;
  title: string;
  title_ar: string;
  sub_title: string;
  sub_title_ar: string;
  date_to: string;
  date_from: string;
  image: string;
  body: any;
  body_ar: any;
};

const Offer = ({
  title,
  body,
  sub_title,
  date_from,
  date_to,
  image,
  title_ar,
  sub_title_ar,
  body_ar,
}: Props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="offer">
      <div className="offer-head">
        <img src={image} alt="offer-img" />
        <div className="offer-date">
          <div className="from">
            <span> {t("offer.from")} </span>
            <span>{date_from}</span>
          </div>
          <div className="to">
            <span> {t("offer.to")} </span>
            <span>{date_to}</span>
          </div>
        </div>
      </div>
      <div className="offer-content">
        <h4>
          {i18n?.language === "ar" ? title_ar || title : title || title_ar}
        </h4>
        <h4>
          {i18n?.language === "ar"
            ? sub_title_ar || sub_title
            : sub_title || sub_title_ar}{" "}
        </h4>
        <div
          dangerouslySetInnerHTML={{
            __html: i18n?.language === "ar" ? body_ar || body : body || body_ar,
          }}
        />
      </div>
    </div>
  );
};

export default Offer;
