import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import NotFound from "../../Components/Blocks/NotFound/NotFound";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import CollapsibleTable from "../../Components/Blocks/tables/CollapsibleTable";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import useGetData from "../../Hooks/useGetData";

const PurchasesPage = () => {
  const { t } = useTranslation();
  const { loading, error, data } = useGetData("order/list", []);
  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  
  return (
    <Box component={"section"}>
      <ParticlesBackGround />
      <MainContainer>
        <PageContentContainer>
          <SectionHead
            color="#CEAFF5"
            title={t("purchases.title")}
            subTitle={t("purchases.sub-title")}
          />
          {data?.orders?.data?.length > 0 ? (
            <CollapsibleTable orders={data?.orders?.data} />
          ) : (
            // <PurchasesTabs orders={data?.orders} />
            <NotFound text={t("error.purchases")} />
          )}
        </PageContentContainer>
        <RequestBlock />
      </MainContainer>
    </Box>
  );
};

export default PurchasesPage;
