import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import SmallOrderCard from "../SmallOrderCard/SmallOrderCard";
import SectionHead from "../SectionHead/SectionHead";
import { motion } from "framer-motion";
import {
  seasonItemType,
  seriesItemType,
  singleItemType,
} from "../../../Types/Types";
import SingleSectionContainer from "./SingleSectionContainer";
import SeriesTabs from "../SeriesTabs/SeriesTabs";
import { useTranslation } from "react-i18next";
import { title } from "process";

type Props = {
  value: string;
  label: string;
  singleItems?: singleItemType[];
  seriesItems?: seriesItemType[];
  seasonItems?: seasonItemType[];
  type: string;
};

const CustomTabPanel = ({
  value,
  label,
  singleItems,
  seriesItems,
  seasonItems,
  type,
}: Props) => {
  const { t, i18n } = useTranslation();

  //set single section data
  const [singleSectionData, setSingleSectionData] = useState<{
    movies: singleItemType[];
    episodes: singleItemType[];
  }>({
    movies: [],
    episodes: [],
  });
  useEffect(() => {
    let movies: singleItemType[] = [];
    let episodes: singleItemType[] = [];
    singleItems?.forEach((item) => {
      
      if (item?.type === "movie") {
        movies.push(item);
      } else if (item?.type === "episode") {
        episodes.push(item);
      }
      // console.log(item.type);
    });
    setSingleSectionData({
      movies,
      episodes,
    });
  }, [singleItems]);
  //end of snippet
  // console.log("singleSectionData",seasonItems);
  
  return (
    <div role="tab-panel" hidden={value !== type}>
      {value === type && (
        <Box
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          {/* movies */}
          {singleSectionData?.movies?.length > 0 && (
            <SingleSectionContainer
              title={t("purchases.movies")}
              items={singleSectionData.movies}
              type="non-expandable"
              color="#E8D6FF"
              itemType={type}
              mediaType={"movie"}
            />
          )}
          {/* episodes */}
          {singleSectionData?.episodes?.length > 0 && (
            <SingleSectionContainer
              title={t("purchases.episodes")}
              items={singleSectionData.episodes}
              type="non-expandable"
              color="#E8D6FF"
              itemType={type}
              mediaType="episode"
            />
          )}
          
          {/* Series section */}
          {seriesItems &&
            seriesItems?.length > 0 &&
            seriesItems?.map((series, index) => {
              return (
                <Stack spacing={3}>
                  <SectionHead
                    title={
                      i18n.language === "en" ? series.name_en : series.name_ar
                    }
                    color="#E8D6FF"
                  />
                  <SeriesTabs seasons={series?.seasons} itemType={type} />
                </Stack>
              );
            })}
          {/* Season section */}
          {seasonItems &&
            seasonItems?.length > 0 &&
            seasonItems?.map((season, index) => {
              return (
                <SingleSectionContainer
                  key={index}
                  title={
                    i18n.language === "en" ? season.name_en : season.name_ar
                  }
                  items={season?.episodes}
                  type="expandable"
                  color="#E8D6FF"
                  itemType={type}
                  mediaType="episode"
                  quantity={season && season.quantity}
                />
              );
            })}

          {/* single section */}
        </Box>
      )}
    </div>
  );
};

export default React.memo(CustomTabPanel);
