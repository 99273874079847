import React from "react";
import { Box, Typography, Grow, Collapse } from "@mui/material";
import { Controller } from "react-hook-form";
import PriceItem from "./PriceItem";
import { StyledSecondaryButton } from "../../StyledComponents/StyledButton/StyledButton";
import { BiCheckDouble } from "react-icons/bi";
import { motion } from "framer-motion";
import { Price } from "../../../Types/Types";
import { useSelector } from "react-redux";
import NotAvailableAlert from "../NotAvailableAlert/NotAvailableAlert";
import { FaMinus, FaPlus } from "react-icons/fa";

type Props = {
  id: number;
  title: string;
  name: string;
  has_cover?: boolean;
  control: any;
  setShowHasCover?: any;
};

const PriceCard = ({ id, name, title, control, setShowHasCover }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const modalState = useSelector((state: any) => state.addToCart);
  // console.log(modalState);
  
  let priceOfItem: any = undefined;
  if (id === 1) {
    priceOfItem = modalState?.price?.watch;
  }
  if (id === 2) {
    priceOfItem = modalState?.price?.download;
  }
  if (id === 3) {
    priceOfItem = modalState?.price?.flash;
  }
  if (id === 4) {
    priceOfItem = modalState?.price?.dvd;
  }

  if (!priceOfItem) return null;

  return (
    <Controller
      name={`price_types[${id - 1}].name`}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <StyledSecondaryButton
          {...field}
          variant={field.value ? "contained" : "outlined"}
          sx={{
            border: !field.value ? "1px solid rgb(216 216 216)" : "none",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            color: "#755798",
            backgroundColor: field.value ? "#0d042ae3" : "transparent",
            textTransform: "none",

            fontSize: {
              mobile: "16px",
            },
            borderRadius: "12px",
            height: {
              mobile: "95px",
              md: "95px",
            },
            width: {
              mobile: "100%",
            },
            "&:hover": {
              backgroundColor: "#0d042ae3",
            },
            maxWidth: "unset",
            alignItems: "center",
            justifyContent: "space-between",
            "& svg": {
              position: "absolute",
              color: "#fff",
              fontSize: "1.5rem",
              backgroundColor: "rgb(19 190 66)",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              right: "8px",
              top: "8px",
            },
          }}
          onClick={() => {
            if (
              (!modalState?.is_available && id === 1 && !field.value) ||
              (!modalState?.is_available && id === 2 && !field.value)
            ) {
              handleOpenDialog();
            } else {
              field.onChange(!field.value);
              if (id === 4) {
                setShowHasCover && setShowHasCover(!field.value);
              }
            }
          }}
        >
          <NotAvailableAlert
            isDialogOpen={isDialogOpen}
            handleClose={handleCloseDialog}
            handleConfirm={() => {
              field.onChange(!field.value);
            }}
          />

          {field.value && (
            <Box
              component={motion.div}
              sx={{
                position: "absolute",
                top: "0",
                right: "0",
              }}
              initial={{
                scale: 0,
              }}
              animate={{
                scale: 1,
              }}
            >
              <BiCheckDouble />
            </Box>
          )}
          <Typography
            sx={{
              fontSize: "1rem",
              color: "#fff",
            }}
          >
            {title}
          </Typography>
          <PriceItem
            price={priceOfItem?.price}
            discounted_price={priceOfItem?.discounted_price}
            has_discount={priceOfItem?.has_discount}
          />
        </StyledSecondaryButton>
      )}
    />
  );
};

export default PriceCard;
