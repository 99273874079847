import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export interface RefreshAddressSlice {
  refreshApi: boolean;
}

const initialState: RefreshAddressSlice = {
  refreshApi: false,
};

const refreshAddressSlice = createSlice({
  name: "refreshAddress",
  initialState,
  reducers: {
    refreshApi: (state) => {
      state.refreshApi = !state.refreshApi;
    },
  },
});

export const refreshAddressActions = refreshAddressSlice.actions;
export default refreshAddressSlice;
