import { useEffect, useState } from "react";
import { getData } from "../Services/DataServices";

// Define a custom hook with a generic type parameter
function useGetData(url: string, dependencies: any[]) {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getData(url).then((response) => {
          if (response !== undefined) {
            if (response.status === 200) {
              setData(response.data);
            }
          }
        });
      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, ...dependencies]);

  return { data, loading, error };
}

export default useGetData;
