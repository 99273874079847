import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import "./index.scss";

const ForgetPasswordForm = () => {
  const [step, setStep] = useState("1");
  const location = useLocation();
  const query = queryString.parse(location.search);
  useEffect(() => {
    if (!query.step) {
      setStep("1");
    }
    if (query.step) {
      setStep(query.step);
    }
  }, [query.step]);
  return (
    <>
      {step === "1" && <StepOne />}
      {step === "2" && <StepTwo />}
      {step === "3" && <StepThree />}
    </>
  );
};

export default ForgetPasswordForm;
