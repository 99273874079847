// @ts-nocheck

import { Box } from "@mui/material";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Header from "../../Components/Blocks/Header/Header";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import MediaSliderSection from "../../Components/Blocks/MediaSliderSection/MediaSliderSection";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import FooterInfo from "../../Components/Layout/Footer/FooterInfo/FooterInfo";
import Navbar from "../../Components/Layout/Navbar/Navbar";
import { getData } from "../../Services/DataServices";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";
import ScrollToTop from "../../Components/Blocks/Buttons/ScrollToTop";
import useGetData from "../../Hooks/useGetData";
import WhatsApp from "../../Components/Blocks/WhatsApp/WhatsApp";
import Cookies from "universal-cookie";
import useEncryptedToken from "../../Hooks/useEncryptedToken";
import CryptoJS from 'crypto-js';
type Props = {};

const Home = (props: Props) => {
  const { t, i18n } = useTranslation();
  
  const [headerSlider, setHeaderSlider] = useState<any>([]);
  const [homeSection1, setHomeSection1] = useState<any>([]);
  const [otherSections, setOtherSections] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [logo, setLogo] = useState<any>("")
  const { data } = useGetData("settings/all", []);
  // const { labels } = useGetData("labels/list/1", []);
  const updatedLogo = data && data?.settings?.logo;
  const location = useLocation();
  const query = queryString.parse(location.search);
  const copyRights = data && data?.settings;
  // console.log(labels);
  
  const handleHomePageData = async (url: string) => {
    setLoading(true);
    await getData(url)
      .then((response) => {
        const data = response?.data;
        setHeaderSlider(data?.data?.header_slider);
        setHomeSection1(data?.data?.sections[0]);
        setOtherSections(data?.data?.sections.slice(1));
        setLogo(updatedLogo);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useEffect(() => {
    let url = `pages/home?enum=${query?.enum || ""}&genre_ids=${
      query?.genre_ids || ""
    }&year=${query?.year || ""}`;
    handleHomePageData(url);
  }, [query.enum, query.genre_ids, query?.year, updatedLogo]);

  const cookies = new Cookies();

  // csrfToken
  // const [csrfToken, setCsrfToken] = useState('');

  // useEffect(() => {
  //   // const csrfMeta = document.querySelector('meta[name="csrf-token"]');
  //   const csrfMeta = document.querySelector('meta[name="description"]');
  //   if (csrfMeta) {
  //     setCsrfToken(csrfMeta.getAttribute('content'));
  //   }
  // }, []);
  // console.log('csrfToken', csrfToken);
  
  // if(cookies.get("token")) {
  //   const message = cookies.get("token");
  //   console.log(message);
  //   // Encode the message
  //     const encodedMessage = btoa(message);
  //     console.log("Encoded message:", encodedMessage);  // Output: ThGlcyBpcyBhIHNlY3JldCBtZXNzYW)!
    
  //     // Decode the message (for demonstration only)
  //     const decodedMessage = atob(encodedMessage);
  //     console.log("Decoded message:", decodedMessage);
  // }

  const [encryptedText, setEncryptedText] = useState('');

  useEffect(() => {
    const secretKey = 'your_secret_key';
    const exampleText = cookies.get("token");

    const encrypted = CryptoJS.AES.encrypt(exampleText, secretKey).toString();
    setEncryptedText(encrypted)
    // console.log('Encrypted:', encrypted);
  }, []);
  // console.log(encryptedText);
  

  const otherSectionsColors = [
    "#FFAD0A",
    "#BB84FF",
    "#81CEFC",
    "#BB84FF",
    "#81CEFC",
  ];

  if (error)
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ErrorComponent />
      </Box>
    );

  return (
    <>
      {updatedLogo && (
        <>
          <ParticlesBackGround />
          <Box
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: "1",
              width: "100%",
              height: "120px",
              backgroundImage:
                "linear-gradient(180deg, #453F4B 0%, #FFFFFF00 100%)",
            }}
          />
          <Navbar />
          <ScrollToTop/>
          {loading ? (
            <MainLoader />
          ) : (
            <Box
              sx={{
                width: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
              }}
            >
              <Header
                sliderData={headerSlider}
                sectionData={homeSection1}
                type="header"
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                }}
              >
                <MainContainer>
                  {otherSections?.map((section: any, index: number) => {
                    return (
                      <MediaSliderSection
                        categoryEnum={section?.enum}
                        color={otherSectionsColors[index]}
                        key={section?.id}
                        movies={
                          section?.enum === 5 ? section?.series : section?.movies
                        }
                        title={
                          i18n.language === "en"
                            ? section?.title?.txt
                            : section?.title?.txt_ar
                        }
                        subTitle={
                          i18n.language === "en"
                            ? section?.description?.txt
                            : section?.description?.txt_ar
                        }
                        moreButton={section?.id}
                        actions={true}
                      />
                    );
                  })}
                  <RequestBlock />
                </MainContainer>
              </Box>
            </Box>
          )}
          <FooterInfo logo={updatedLogo} rights={i18n.language === "ar" ? copyRights?.copyrights_ar : copyRights?.copyrights_en } />
          <WhatsApp message={data?.settings?.whatsapp_message} />
        </>
      )}
    </>
  );
};

export default Home;
