import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export interface AuthState {
  token: string | null;
  isAuthenticated: boolean;
  isVip?: boolean;
}
let isVip = false;
if (cookies.get("vip") === "true") {
  isVip = true;
}
const initialState: AuthState = {
  token: cookies.get("token"),
  isAuthenticated: false,
  isVip: isVip,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      cookies.set("token", action.payload.token, { path: "/" });
      cookies.set("vip", action.payload.isVip, { path: "/" });
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isVip = action.payload.isVip;
    },
    logout: (state) => {
      cookies.remove("token");
      cookies.remove("vip");
      state.token = null;
      state.isAuthenticated = false;
      state.isVip = false;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
