import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  displayToastErrorApi,
  displayToastSuccess,
} from "../../../Helpers/toastHelper";
import useGetData from "../../../Hooks/useGetData";
import { getData, postWithoutData } from "../../../Services/DataServices";
import ProfileAddressForm from "../Forms/ProfileAddressForm";
import SectionHead from "../SectionHead/SectionHead";
import { MdOutlineDelete } from "react-icons/md";
import { refreshAddressActions } from "../../../store/refreshAddress-slice";
import useConstructAddress from "../../../Hooks/useConstructAddress";

const defaultValues = {
  id: "",
  country_id: 0,
  area_id: "",
  residential_area: "",
  street: "",
  building_no: "",
  detailed_address: "",
  block: "",
  avenue: "",
  floor: "",
  apartment: "",
};

const ProfileAddress = () => {
  const { reset } = useForm();
  const [formReset, setFormReset] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState(defaultValues);
  const [addAddress, setAddAddress] = useState<boolean>(true);
  const [addresses, setAddresses] = useState<any>([]);
  const [countryId, setCountryId] = useState(0);
  const [areaId, setAreaId] = useState(null);

  // Language handler
  const { t, i18n } = useTranslation();
  //refresh address api
  const refreshAddress = useSelector((state: any) => state.refreshAddress);

  // Get addresses
  const {
    data: address,
    error,
    loading,
  } = useGetData("address/all", [refreshAddress.refreshApi]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (address) {
      setAddresses(
        address?.addresses?.filter((item: any) => item.is_active === true)
      );
    }
  }, [address]);
  // Function to pass data of selected address to form
  const selectAddress = ({ item }: any) => {
    // setFormReset(!formReset);
    setAddAddress(false);
    setSelectedAddress({
      ...addresses[item],
    });
  };

  const [deleting, setDeleting] = useState(false);

  const handleDeleteAddress = async (id: any) => {
    try {
      setDeleting(true);
      await getData(`address/delete/${id}`).then((res) => {
        if (res?.status === 200) {
          displayToastSuccess(res?.data?.message);
          dispatch(refreshAddressActions.refreshApi());
        }
      });
    } catch (error: any) {
      displayToastErrorApi(error?.response?.data?.message);
    } finally {
      setDeleting(false);
    }
  };

  const { constructAddress } = useConstructAddress();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "120px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100vw",
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            mobile: "1fr",
            sm: "1fr 1fr",
          },
          gap: "20px",
          alignItems: "center",
        }}
      >
        <SectionHead
          color="#CEAFF5"
          title={t("profile.titleAddress")}
          subTitle={t("profile.subTitleAddress")}
        />
        <Box
          onClick={() => {
            setAddAddress(true);
            setFormReset(!formReset);
            setSelectedAddress(defaultValues);
          }}
          sx={{
            display: "flex",
            flex: "1",
            flexDirection: "row",
            gap: "24px",
            alignItems: "center",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(117, 87, 152, 0.04)",
              borderRadius: "10px",
            },
          }}
        >
          <Box
            sx={{
              height: "60px",
              width: "60px",
              borderRadius: "8px",
              backgroundColor: "#E8D6FF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: {
                mobile: "0",
                sm: "32px",
              },
              fontSize: "2rem",
            }}
          >
            <BsFillPlusCircleFill color="#755798" />
          </Box>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "400",
              color: "#ECECEC",
              marginTop: "5px",
            }}
          >
            {t("profile.new")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: {
            mobile: "flex",
            sm: "grid",
          },
          gridTemplateColumns: "1.25fr 1fr",
          gap: "20px",
          flexDirection: "column-reverse",
        }}
      >
        <ProfileAddressForm
          selectedAddress={selectedAddress}
          addAddress={addAddress}
          formReset={formReset}
          addNewAddress={setAddresses}
          addresses={addresses}
          countryId={countryId}
          setCountryId={setCountryId}
          areaId={areaId}
          setAreaId={setAreaId}
        />
        <Box
          sx={{
            borderLeft: "1px solid #E8D6FF",
            opacity: "0.8",
            display: "flex",
            flexDirection: "column",
            // maxHeight: "34rem",
            padding: "16px",
          }}
        >
          {Object.keys(addresses)?.map((item: any, index: number) => {
            return (
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  onClick={() => {
                    selectAddress({ item });
                    setCountryId(addresses[item].country_id);
                    setAreaId(addresses[item]?.area_id);
                  }}
                  sx={{
                    position: "relative",
                    padding: "4px",
                    "&::before": {
                      content: '" * "',
                      position: "absolute",
                      left: "4px",
                      top: "8px",
                    },
                    fontSize: "1rem",
                    fontWeight: "400",
                    color: "#FFFFFF",
                    paddingLeft: "1rem",
                    cursor: "pointer",
                    transition: "all .2s ease-in",
                    "&:hover": {
                      backgroundColor: "#755798",
                      borderRadius: "10px",
                    },
                  }}
                >
                  {constructAddress(addresses[item], i18n.language)}
                </Typography>
                <IconButton
                  sx={{
                    padding: "0",
                    "& svg": {
                      color: "error.main",
                      borderRadius: "4px",
                      width: "24px",
                      height: "24px",
                    },
                  }}
                  onClick={() => {
                    handleDeleteAddress(addresses[item].id);
                  }}
                  disabled={deleting}
                >
                  <MdOutlineDelete />
                </IconButton>
              </Stack>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileAddress;
