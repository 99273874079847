import { useState } from "react";
import { displayToastErrorApi } from "../Helpers/toastHelper";
import { getData } from "../Services/DataServices";

const useOrderUrl = (
  order_id: string,
  price_type: string,
  type: string,
  type_id: string
) => {
  const [url, setUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const getUrl = async () => {
    if (price_type === "3" || price_type === "4") {
      return;
    } else {
      try {
        setLoading(true);
        await getData(
          `order/generate_url?order_id=${order_id}&price_type=${price_type}&type=${type}&type_id=${type_id}`
        ).then((response) => {
          if (response !== undefined) {
            if (response.status === 200) {
              // setLoading(false);
              setUrl(response?.data?.data?.url);
              if (
                response?.data?.data?.url &&
                response?.data?.data?.url.length > 0
              ) {
                window.open(response?.data?.data?.url, "_self");
                // console.log('clicked new url', url);
                
              } else {
                displayToastErrorApi(response?.data?.data?.message);
              }
            }
          }
        });
      } catch (err: any) {
        setError(
          err.response.data.message
            ? err.response.data.message
            : "An error occurred while fetching data."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return { url, loading, error, getUrl };
};

export default useOrderUrl;
