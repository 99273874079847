import { Route, Routes } from "react-router-dom";
import ErrorComponent from "../Components/Blocks/ErrorComponent/ErrorComponent";
import ShoppingCart from "../Components/Blocks/ShoppingCart/ShoppingCart";
import Layout from "../Components/Layout/Layout";
import AboutUs from "../Pages/AboutUs/AboutUs";
import AdvancedSearch from "../Pages/AdvancedSearch/AdvancedSearch";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import Login from "../Pages/Auth/Login";
import Register from "../Pages/Auth/Register";
import ContactUs from "../Pages/ContactUs/ContactUs";
import DMCA from "../Pages/DMCA/DMCA";
import FAQ from "../Pages/FAQ/FAQ";
import FavoritesPage from "../Pages/FavoritesPage/FavoritesPage";
import Home from "../Pages/Home/Home";
import MediaCategoryPage from "../Pages/MediaCategoryPage/MediaCategoryPage";
import SeasonPage from "../Pages/MediaDetailsPage/SeasonPage";
import SeriesPage from "../Pages/MediaDetailsPage/SeriesPage";
import MoreItemsPage from "../Pages/MoreItemsPage/MoreItemsPage";
import MoviesPage from "../Pages/MoviesPage/MoviesPage";
import OffersPage from "../Pages/OffersPage/OffersPage";
import Policy from "../Pages/Policy/Policy";
import Privacy from "../Pages/Privacy/Privacy";
import ProfilePage from "../Pages/ProfilePage/ProfilePage";
import PurchasesPage from "../Pages/PurchasesPage/PurchasesPage";
import Request from "../Pages/Request/Request";
import SearchResultPage from "../Pages/SearchResultPage/SearchResultPage";
import ProtectedRoutes from "./ProtectedRoutes";
import MaintenancePage from "../Pages/MaintenancePage/MaintenancePage";
import SinglePurchasePage from "../Pages/SinglePurchasePage/SinglePurchasePage";
import SingleActor from "../Pages/SingleActor/SingleActor";
import SubmitOrder from "../Pages/SubmitOrder/SubmitOrder";
import NewCart from "../Pages/NewCart/NewCart";
import Unsubscripe from "../Pages/Unsubscripe/Unsubscripe";
const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<MaintenancePage />}>
        <Route path="/" element={<Home />} />
        <Route element={<Layout />}>
          <Route path="/policy" element={<Policy />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/dmca" element={<DMCA />} />
          <Route path="/media/movie/:id" element={<MoviesPage />} />
          <Route path="/home/media/:id" element={<MoreItemsPage />} />
          <Route path="/media/series/:id" element={<SeriesPage />} />
          <Route path="/media/season/:id" element={<SeasonPage />} />
          <Route path="/actor/:id" element={<SingleActor />} />
          <Route path="/request" element={<Request />} />
          <Route path="/search-results" element={<SearchResultPage />} />
          <Route path="/media/:slug" element={<MediaCategoryPage />} />
          <Route path="/offers" element={<OffersPage />} />
          <Route path="/unsubscribe" element={<Unsubscripe />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="/purchases" element={<PurchasesPage />} />
            <Route path="/purchases/:id" element={<SinglePurchasePage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/favorites" element={<FavoritesPage />} />
            <Route path="/cart" element={<NewCart />} />
          </Route>
          <Route path="/submit-order/:token" element={<SubmitOrder />} />
          <Route path="/advanced-search" element={<AdvancedSearch />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route element={<Layout />}>
          <Route path="/404" element={<ErrorComponent />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="*" element={<ErrorComponent />} />
        </Route>
      </Route>
    </Routes>
  );
};
export default AppRoutes;
